import { all } from "redux-saga/effects";
import { gameSaga } from "../modules/game";
import { marketSaga } from "../modules/market";
import { userSaga } from "../modules/user";
import { adminSaga } from "../modules/admin";
import { authSaga } from "../modules/auth";

export default function* rootSaga() {
  yield all([userSaga(), marketSaga(), gameSaga(), adminSaga(), authSaga()]);
}
