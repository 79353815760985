import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import // userGetMetamaskInfoSagaActionCreator,
// userGetMyPageInfoSagaActionCreator,
"../../../redux/modules/user";
import SubHeader from "../../header/template/SubHeader";
import Modal from "../../modal/Modal";
import MyPageTemplate from "./template/MyPageTemplate";

const MyPage = () => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(0);

  useEffect(() => {
    // dispatch(userGetMetamaskInfoSagaActionCreator());
    // dispatch(userGetMyPageInfoSagaActionCreator({ setModalOpen }));
  }, []);

  const modalData = [
    // {
    //   header: "디스코드 커뮤니티 레벨 부족 안내",
    //   size: "sm",
    //   sort: "center",
    //   content: (
    //     <p>
    //       Athena Returns NFT 디스코드 커뮤니티 레벨 1이상부터 이용이 가능합니다.
    //       <br />
    //       커뮤니티에서 레벨 1이상 달성 후 이용해주세요.
    //     </p>
    //   ),
    //   btnType: "point",
    //   ico: true,
    //   leftBtn: true,
    //   btnLeftText: "홈으로 이동",
    //   btnRightText: "커뮤니티로 이동",
    //   ldcBtnHandler: () => {
    //     dispatch(push("/"));
    //     setModalOpen(0);
    //   },
    //   rightBtnHandler: () => {
    //     window.open("https://discord.com/invite/uB8vPbTf8p", "__blank");
    //     dispatch(push("/"));
    //     setModalOpen(0);
    //   },
    // },
  ];

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <SubHeader
        states={{
          title: "MY PAGE",
          type: "MY_PAGE",
        }}
      />
      <MyPageTemplate />
    </>
  );
};

export default MyPage;
