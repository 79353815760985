import axios from "axios";

import { getAccessToken, getRefreshToken } from "./token";

const AUTH_URI = `${process.env.REACT_APP_API_URL}/api/auth`;

export default class AuthService {
  static async getDiscordLogin(payload) {
    const { data } = await axios({
      method: "GET",
      url: `${AUTH_URI}/discord/callback?code=${payload}`,
    });
    return data;
  }

  static async signInCheck(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${AUTH_URI}/token`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async issuanceNewToken() {
    const { data } = await axios({
      method: "GET",
      url: `${AUTH_URI}/reissueToken`,
      headers: {
        Authorization: `Bearer ${getRefreshToken()}`,
      },
    });
    return data;
  }

  // 일반 유저 회원가입
  static async postSignUpUser(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${AUTH_URI}/signup`,
      data: payload,
    });

    return data;
  }

  // 일반 유저 로그인
  static async postLoginUser(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${AUTH_URI}/login`,
      data: payload,
    });

    return data;
  }

  // 이메일 인증
  static async patchEmailAuthCheck(payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${AUTH_URI}/email`,
      data: payload,
    });

    return data;
  }

  // 이메일 인증 메일 재전송
  static async getResendAuthMail(email) {
    const { data } = await axios({
      method: "GET",
      url: `${AUTH_URI}/email/${email}`,
    });

    return data;
  }

  // 비밀번호 재설정
  static async getResetPassword(email) {
    const { data } = await axios({
      method: "GET",
      url: `${AUTH_URI}/password/${email}`,
    });

    return data;
  }
}
