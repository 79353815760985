import { useEffect, useRef, useState } from "react";

// main
import arrowTop from "../../../assets/icons/arrow_top.svg";
import arrowBottom from "../../../assets/icons/arrow_bottom.svg";
import btn_group_official from "../../../assets/icons/btn_group_official.png";
import btn_group_olea_info from "../../../assets/icons/olea_icon.svg";
import btn_group_discord from "../../../assets/icons/btn_group_discord.png";
import btn_group_twitter from "../../../assets/icons/btn_group_twitter.png";
import btn_group_instagram from "../../../assets/icons/btn_group_instagram.png";
import btn_group_facebook from "../../../assets/icons/btn_group_facebook.png";
import btn_group_blog from "../../../assets/icons/btn_group_blog.png";

import "./Home.scss";
import HomeNftInfo from "./template/HomeNftInfo";
import HomeHolder from "./template/HomeHolder";
import HomeBrand from "./template/HomeBrand";
import Modal from "../../modal/Modal";
import { modalData } from "./modalData";

const Home = () => {
  const sectionRefs = useRef([]);
  const [modalOpen, setModalOpen] = useState(0);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  // useEffect(() => {
  //   setModalOpen("ALERT_MAIN");
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const currentSectionIndex = sectionRefs.current.findIndex(
        (sectionRef) => {
          const sectionTop = sectionRef?.offsetTop ?? 0;
          const sectionBottom = sectionTop + (sectionRef?.offsetHeight ?? 0);
          return currentScrollY >= sectionTop && currentScrollY < sectionBottom;
        }
      );
      setCurrentSectionIndex(
        currentSectionIndex === -1 ? 0 : currentSectionIndex
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (index) => {
    const sectionTop = sectionRefs.current[index]?.offsetTop ?? 0;
    window.scrollTo({ top: sectionTop, behavior: "smooth" });
  };

  const handleButtonClick = () => {
    const nextSectionIndex =
      currentSectionIndex === sectionRefs.current.length - 1
        ? 0
        : currentSectionIndex + 1;
    scrollToSection(nextSectionIndex);
  };

  const buttonArr = [
    {
      src: btn_group_official,
      url: "https://athenareturns.io/",
      alt: "공식홈페이지 버튼 이미지",
    },
    {
      src: btn_group_olea_info,
      url: "https://oleatoken.com/",
      alt: "공식홈페이지 버튼 이미지",
    },
    {
      src: btn_group_discord,
      url: "https://discord.com/invite/uB8vPbTf8p",
      alt: "디스코드 버튼 이미지",
    },
    {
      src: btn_group_twitter,
      url: "https://twitter.com/AR2022PROJECT",
      alt: "트위터 버튼 이미지",
    },
    {
      src: btn_group_instagram,
      url: "https://www.instagram.com/Athena_Returns/",
      alt: "인스타그램 버튼 이미지",
    },
    {
      src: btn_group_facebook,
      url: "https://www.facebook.com/people/Athena-Returns/100079898127135/",
      alt: "페이스북 버튼 이미지",
    },
    {
      src: btn_group_blog,
      url: "https://blog.naver.com/athena_returns2022",
      alt: "네이버 블로그 버튼 이미지",
    },
  ];

  const modalPayload = { setModalOpen };

  return (
    <main>
      {modalOpen !== 0 && (
        <Modal data={modalData[modalOpen]} modalPayload={modalPayload} />
      )}

      <div className="main_wrapper">
        <div className="main_btn_group">
          {currentSectionIndex === 2 ? (
            <div
              className="scroll_btn to_bottom_btn"
              onClick={handleButtonClick}
            >
              <img src={arrowTop} alt="상향화살표" />
            </div>
          ) : (
            <div
              className="scroll_btn to_bottom_btn"
              onClick={handleButtonClick}
            >
              <img src={arrowBottom} alt="하향화살표" />
            </div>
          )}
          {buttonArr.map((cur, idx) => {
            return (
              <div
                className="linked_btn"
                key={idx}
                onClick={() => {
                  window.open(`${cur.url}`, "__blank");
                }}
              >
                <img src={cur.src} alt={cur.alt} />
              </div>
            );
          })}
        </div>

        <div
          className="nft_info_section"
          ref={(ref) => (sectionRefs.current[0] = ref)}
        >
          <HomeNftInfo />
        </div>

        <div
          className="holder_section"
          ref={(ref) => (sectionRefs.current[1] = ref)}
        >
          <HomeHolder />
        </div>
        <div
          className="brand_section"
          ref={(ref) => (sectionRefs.current[2] = ref)}
        >
          <HomeBrand />
        </div>
      </div>
    </main>
  );
};

export default Home;
