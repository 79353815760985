//? 메인 페이지 NFT OLEA 잔여량 조회 로직에 사용 =======================================
export const tierList = [
  { name: "Tier 선택", value: "" },
  { name: "A Tier", value: "A" },
  { name: "B Tier", value: "B" },
  { name: "Halloween", value: "Halloween" },
  { name: "Christmas", value: "Christmas" },
  // { name: "Titan", value: "Titan" },
];

const result = (value, reason) => {
  return {
    value: value,
    reason: reason,
  };
};

/**메인페이지 NFT당 잔여 OLEA 조회 시 사용하는 컨버터 */
export function balanceTypeConvert(tier, edition) {
  const numEdition = Number(edition);

  if (tier === "" && edition === "") {
    return result(null, null);
  }

  if (tier === "" && edition) {
    return result(null, "Tier를 선택해주세요.");
  }
  switch (tier) {
    case "A":
      if (numEdition > 3000) {
        return result(null, "A Tier는 3000번까지 존재합니다.");
      } else {
        return result(edition, null);
      }

    case "B":
      if (numEdition > 7000) {
        return result(null, "B Tier는 7000번까지 존재합니다.");
      } else {
        return edition === ""
          ? result(null, null)
          : result(String(numEdition + 3000), null);
      }

    case "Halloween":
      if (numEdition > 500) {
        return result(null, "Halloween Tier는 500번까지 존재합니다.");
      } else {
        return edition === ""
          ? result(null, null)
          : result(String(numEdition + 10000), null);
      }

    case "Christmas":
      if (numEdition > 300) {
        return result(null, "Christmas Tier는 300번까지 존재합니다.");
      } else {
        return edition === ""
          ? result(null, null)
          : result(String(numEdition + 10500), null);
      }

    // case "Titan":
    //   if (numEdition > 9) {
    //     return result(null, "Titan Tier는 9번까지 존재합니다.");
    //   } else {
    //     return edition === ""
    //       ? result(null, null)
    //       : result(String(numEdition + 10800), null);
    //   }

    default:
      break;
  }
}

//? My Wallet NFT 검색에 사용 ====================================================
export const searchTierList = [
  { name: "Tier 전체보기", value: "" },
  { name: "A Tier", value: "A" },
  { name: "B Tier", value: "B" },
  { name: "Halloween SE", value: "HW" },
  { name: "Christmas SE", value: "CM" },
  { name: "Titan", value: "TITAN" },
  { name: "Aegis", value: "AEGISE" },
];

export const balanceList = [
  { name: "잔여량 높은순", value: "htl" },
  { name: "잔여량 낮은순", value: "lth" },
];

export const tierSearch = (tier, userAccountsArray) => {
  switch (tier) {
    case "":
      return userAccountsArray;
    case "A":
      return userAccountsArray.filter((cur) => cur.tierType < 3); // 1, 2
    case "B":
      return userAccountsArray.filter(
        (cur) => cur.tierType > 2 && cur.tierType < 6 // 3, 4, 5
      );
    case "HW":
      return userAccountsArray.filter((cur) => cur.tierType === 6); // 6
    case "CM":
      return userAccountsArray.filter(
        (cur) => cur.tierType > 6 && cur.tierType < 9 // 7, 8
      );
    case "TITAN":
      return userAccountsArray.filter((cur) => cur.tierType === 9); // 9
    case "AEGISE":
      return userAccountsArray.filter((cur) => cur.tierType === 10); // 10
    default:
      return userAccountsArray;
  }
};

//? My Wallet NFT Card에 사용 ===================================================
export const tierTypeConvert = (tierType) => {
  switch (tierType) {
    case 1:
      return "A Tier";
    case 2:
      return "A Tier";
    case 3:
      return "B Tier";
    case 4:
      return "B Tier";
    case 5:
      return "B Tier";
    case 6:
      return "Halloween";
    case 7:
      return "Christmas";
    case 8:
      return "Christmas";
    case 9:
      return "Titan";
    case 10:
      return "Aegis";
    default:
      return null;
  }
};

export const renderCurrentTypeConvert = (tierType) => {
  switch (tierType) {
    case 1:
      return "Fantasy";
    case 2:
      return "Fancy Dress";
    case 3:
      return "Daily Dress";
    case 4:
      return "Modern Clothes";
    case 5:
      return "Pajama";
    case 6:
      return "Special Edition";
    case 7:
      return "Special Edition";
    case 8:
      return "Special Edition";
    case 9:
      return "Titan";
    case 10:
      return "Aegis";
    default:
      return null;
  }
};

//? NFT 카드 정보에 사용 (ex. MarketRightContainer)
export const NFTTypeConvert = (tierType) => {
  switch (tierType) {
    case 1:
      return "A";
    case 2:
      return "A";
    case 3:
      return "B";
    case 4:
      return "B";
    case 5:
      return "B";
    case 6:
      return "SE";
    case 7:
      return "SE";
    case 8:
      return "SE";
    case 9:
      return "Titan";
    case 10:
      return "Aegis";
    default:
      return null;
  }
};

//? HOLDER Market 상품 카테고리에 사용 ==============================================
export const holderMarketCategoryArr = [
  { name: "전체보기", value: "0" },
  { name: "디저트", value: "1" },
  { name: "외식", value: "2" },
  { name: "생활", value: "3" },
  // { name: "NFT", value: "6" },
  { name: "굿즈", value: "7" },
];

//? CLUB Market 상품 카테고리에 사용 ================================================
export const clubMarketCategoryArr = [
  { name: "전체보기", value: "0" },
  { name: "디저트", value: "1" },
  { name: "외식", value: "2" },
  { name: "생활", value: "3" },
  { name: "IT", value: "4" },
  { name: "패션", value: "5" },
  // { name: "NFT", value: "6" },
  // { name: "굿즈", value: "7" },
];

export const tokenMarketCategoryArr = [
  { name: "전체보기", value: "0" },
  // { name: "NFT", value: "6" },
  // { name: "굿즈", value: "7" },
];

//? HOLDER Market ppCheck로직 () ================================================
export const holderPPCheck = (pp, oleaPrice, totalPrice) => {
  const tenPPlimit = 2000000 / oleaPrice;
  const limit =
    Math.floor(Math.floor(pp / 10) * (tenPPlimit / oleaPrice) * 10) / 10;
  return totalPrice > limit;
};

//? CLUB Market ppCheck로직 () ==================================================
export const clubPPCheck = (pp, oleaPrice, totalPrice) => {
  const tenPPlimit = 2000000 / oleaPrice;
  const limit =
    Math.floor(Math.floor(pp / 10) * (tenPPlimit / oleaPrice) * 10) / 10;
  return totalPrice > limit;
};

export const clubVoucherCheck = (oleaPrice, totalPrice) => {
  const limit = Math.ceil(35200 / oleaPrice);
  return totalPrice > limit;
};
