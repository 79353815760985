import TT from "../assets/images/badge_titan.png";
import AG from "../assets/images/badge_aegis.png";
import ST from "../assets/images/badge_starter.png";

export const userTypeConvert = (userType) => {
  switch (userType) {
    case 0:
      return null;
    case 1:
      return <span style={{ fontSize: 20, marginLeft: 20 }}>🚨</span>;
    case 2:
      return <img className="badge_img" src={TT} alt="TT" />;
    case 3:
      return <img className="badge_img" src={AG} alt="AG" />;
    case 4:
      return <img className="badge_img" src={ST} alt="ST" />;
    default:
      break;
  }
};

export const userTypeConvertWithText = (userType) => {
  switch (userType) {
    case 0:
      return null;
    case 1:
      return <span className="text_user_type admin_color">관리자</span>;
    case 2:
      return <span className="text_user_type titan_color">타이탄</span>;
    case 3:
      return <span className="text_user_type aegis_color">이지스</span>;
    case 4:
      return <span className="text_user_type starter_color">스타터</span>;
    default:
      break;
  }
};
