import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { Popper } from "@mui/material";

import Timer from "./Timer";
import PPInformation from "./PPInformation";
import TimerInformation from "./TimerInformation";
import PurchaseChance from "./PurchaseChance";

import TICKETICON from "../../../assets/icons/ticket.png";
import COUNTDOWN from "../../../assets/icons/countdown.svg";
import QUESTIONMARK from "../../../assets/icons/questionMark.svg";

import "./SubHeader.scss";

import { userPutRefreshNftSagaActionCreator } from "../../../redux/modules/user";
import Modal from "../../modal/Modal";
import { push } from "connected-react-router";
import RouletteInfo from "./RouletteInfo";
import RouletteClaimInfo from "./RouletteClaimInfo";
import jwtDecode from "jwt-decode";
import { getAccessToken } from "../../../service/token";
import MarketBuyTicket from "../../pages/markets/MarketBuyTicket";
import MarketBuyGameMoney from "../../pages/markets/MarketBuyGameMoney";
import { useMemo } from "react";

const SubHeader = ({ states }) => {
  const { title, type, count, setCount } = states;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorText, setAnchorText] = useState(null);
  const [modalOpen, setModalOpen] = useState(0);
  const oleaAmount = useMemo(() => {
    if (type !== "OLEA") return 0;
    return 1000;
  }, [type]);

  const userScoresState = useSelector((state) => state.user.userInfo?.PP);
  const curTicket = useSelector((state) => state.user.userInfo?.ticket);
  const holderPurchaseStatus = useSelector(
    (state) => state.market.showMarket?.purchaseStatus
  );
  const clubPurchaseStatus = useSelector(
    (state) => state.market.showClubMarket?.purchaseStatus
  );
  const tokenPurchaseStatus = useSelector(
    (state) => state.market.tokenMarket?.purchaseStatus
  );
  const addressState = useSelector((state) => state.user.userInfo?.address);
  const { gameMoney, winningMoney } = useSelector((state) => state.game.info);
  const userRestAmount = useSelector((state) => state.market.userRestAmount);
  const oleaPrice = useSelector(
    (state) => state.market.showClubMarket?.oleaPrice
  );

  const handleClick = (event, component) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setAnchorText(component);
    setOpen(!open);
  };

  const refreshNFTHandler = () => {
    dispatch(userPutRefreshNftSagaActionCreator({ setModalOpen }));
  };

  const modalData = [
    {
      header: "오류 안내",
      size: "sm",
      sort: "center",
      content: (
        <p>
          스마트 월렛 계정이 등록되어 있지 않습니다.
          <br />
          스마트 월렛 계정을 등록 후 사용이 가능합니다.
          <br />
          스마트 월렛 연동 페이지로 이동하시겠습니까?
        </p>
      ),
      ico: true,
      btnType: "point",
      leftBtn: true,
      btnRightText: "페이지 이동",
      leftBtnHandler: () => {
        setModalOpen(0);
      },
      rdcBtnHandler: () => {
        dispatch(push("/connect-wallet"));
      },
    },
    {
      header: "오류 안내",
      size: "sm",
      sort: "center",
      content: <p>알 수 없는 오류가 발생했습니다.</p>,
      ico: true,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
  ];

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <div
        className={`sub_header_wrapper ${
          type === "HOLDER"
            ? "holder_bg"
            : type === "CLUB"
            ? "club_bg"
            : type === "TICKET"
            ? "ticket_bg"
            : type === "MY_WALLET"
            ? "holder_bg"
            : type === "MY_PAGE"
            ? "holder_bg"
            : type === "JOY"
            ? "joy_bg"
            : type === "OLEA"
            ? "olea_bg"
            : type === "TOKEN"
            ? "olea_bg"
            : null
        }`}
      >
        <div className="sub_header_popper_box">
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement={"right-start"}
            sx={{
              zIndex: 30,
            }}
            popperOptions={{
              strategy: "fixed",
            }}
            onMouseLeave={(e) => {
              setOpen(false);
              setAnchorEl(null);
            }}
          >
            {anchorText}
          </Popper>
        </div>

        <div className="sub_header_title_position_box">
          {type === "HOLDER" && (
            <div className="flex_center">
              <div className="flex_evenly">
                <span className="sub_header_text_span">{title}</span>
                <span className="sub_header_purchase_status">
                  구매 기회 : {holderPurchaseStatus} / 1
                  <img
                    src={QUESTIONMARK}
                    alt="QUESTIONMARK"
                    onClick={(e) => {
                      handleClick(e, <PurchaseChance />);
                    }}
                    style={{
                      position: "absolute",
                      top: 5,
                      marginLeft: 5,
                      cursor: "pointer",
                    }}
                  />
                </span>

                <span
                  className="sub_header_text_span"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={COUNTDOWN} alt="COUNTDOWN" />
                  <span className="sub_header_text_row_content">
                    <Timer states={{ count, setCount, pageType: "olea" }} />
                    <img
                      src={QUESTIONMARK}
                      alt="QUESTIONMARK"
                      onClick={(e) => {
                        handleClick(e, <TimerInformation />);
                      }}
                      style={{
                        position: "absolute",
                        top: 5,
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </span>
                <span className="sub_header_text_span">
                  PP
                  <span className="sub_header_text_row_content">
                    {userScoresState && userScoresState}
                    <img
                      src={QUESTIONMARK}
                      alt="QUESTIONMARK"
                      onClick={(e) => {
                        handleClick(e, <PPInformation />);
                      }}
                      style={{
                        position: "absolute",
                        top: 5,
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          {type === "CLUB" && (
            <div className="flex_center">
              <div className="flex_evenly">
                <span className="sub_header_text_span">{title}</span>
                {/* {type === "OLEA" && (
                  <span className="sub_header_purchase_status">최대 사용 가능 OLEA: {oleaAmount}</span>
                )} */}
                <span className="sub_header_purchase_status">
                  구매 기회 : {clubPurchaseStatus} / 1
                  <img
                    src={QUESTIONMARK}
                    alt="QUESTIONMARK"
                    onClick={(e) => {
                      handleClick(e, <PurchaseChance />);
                    }}
                    style={{
                      position: "absolute",
                      top: 5,
                      marginLeft: 5,
                      cursor: "pointer",
                    }}
                  />
                </span>

                <span
                  className="sub_header_text_span"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={COUNTDOWN} alt="COUNTDOWN" />
                  <span className="sub_header_text_row_content">
                    <Timer states={{ count, setCount, pageType: "club" }} />
                    <img
                      src={QUESTIONMARK}
                      alt="QUESTIONMARK"
                      onClick={(e) => {
                        handleClick(e, <TimerInformation />);
                      }}
                      style={{
                        position: "absolute",
                        top: 5,
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </span>
                <span className="sub_header_text_span">
                  PP
                  <span className="sub_header_text_row_content">
                    {userScoresState && userScoresState}
                    <img
                      src={QUESTIONMARK}
                      alt="QUESTIONMARK"
                      onClick={(e) => {
                        handleClick(e, <PPInformation />);
                      }}
                      style={{
                        position: "absolute",
                        top: 5,
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </span>
              </div>
            </div>
          )}

          {type === "OLEA" && (
            <div className="flex_center">
              <div className="flex_evenly">
                <span className="olea_sub_header_text_span">{title}</span>
                <span className="olea_sub_header_purchase_status">
                  구매 기회 : {clubPurchaseStatus} / 1
                  <img
                    src={QUESTIONMARK}
                    alt="QUESTIONMARK"
                    onClick={(e) => {
                      handleClick(e, <PurchaseChance />);
                    }}
                    style={{
                      position: "absolute",
                      top: 5,
                      marginLeft: 5,
                      cursor: "pointer",
                    }}
                  />
                </span>

                <span
                  className="olea_sub_header_text_span"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={COUNTDOWN} alt="COUNTDOWN" />
                  <span className="olea_sub_header_text_row_content">
                    <Timer states={{ count, setCount, pageType: "club" }} />
                    <img
                      src={QUESTIONMARK}
                      alt="QUESTIONMARK"
                      onClick={(e) => {
                        handleClick(e, <TimerInformation />);
                      }}
                      style={{
                        position: "absolute",
                        top: 5,
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </span>
                <span className="olea_sub_header_text_span">
                  {Math.floor(userRestAmount / oleaPrice).toLocaleString()} OLEA
                  사용 가능
                </span>
                {/* <span className="olea_sub_header_text_span">
                  PP
                  <span className="olea_sub_header_text_row_content">
                    {userScoresState && userScoresState}
                    <img
                      src={QUESTIONMARK}
                      alt="QUESTIONMARK"
                      onClick={(e) => {
                        handleClick(e, <PPInformation />);
                      }}
                      style={{
                        position: "absolute",
                        top: 5,
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </span> */}
              </div>
            </div>
          )}

          {type === "TICKET" && (
            <div className="flex_center">
              <div className="flex_between">
                <span className="sub_header_text_span">{title}</span>
                <span className="sub_header_text_span">
                  <div className="flex_center">
                    <img
                      src={TICKETICON}
                      style={{ width: "50%" }}
                      alt="MarketCart"
                    />
                    <span
                      className="sub_header_text_row_content"
                      style={{ paddingBottom: "5px" }}
                    >
                      {curTicket}
                    </span>
                  </div>
                </span>
              </div>
            </div>
          )}

          {type === "JOY" && (
            <div className="flex_center">
              <div className="flex_between">
                <span className="sub_header_text_span">{title}</span>
                <span className="sub_header_text_span joy_nickname">
                  {jwtDecode(getAccessToken()).nickname
                    ? `${jwtDecode(getAccessToken()).nickname}님, 환영합니다!`
                    : null}
                </span>
                <span className="sub_header_text_span">
                  게임머니
                  <span className="sub_header_text_row_content">
                    {gameMoney && gameMoney} OLEA
                    <img
                      src={QUESTIONMARK}
                      alt="QUESTIONMARK"
                      onClick={(e) => {
                        handleClick(e, <RouletteInfo />);
                      }}
                      style={{
                        position: "absolute",
                        top: 5,
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                    />
                  </span>
                  <MarketBuyGameMoney states={{ pageType: "JOY" }} />
                </span>
              </div>
            </div>
          )}

          {type === "MY_WALLET" && (
            <div className="sub_header_my_wallet_flex">
              <span className="sub_header_text_span">{title}</span>

              {addressState && (
                <p>
                  현재 연결된 계정 : <span>{addressState}</span>
                </p>
              )}

              <div className="sub_header_btn_wrapper">
                <img
                  src={QUESTIONMARK}
                  alt="QUESTIONMARK"
                  onClick={(e) => {
                    handleClick(
                      e,
                      <ButtonInformation>
                        실시간으로 디스코드 커뮤니티 레벨과
                        <br />
                        현재 보유중인 NFT를 조회하여
                        <br />
                        정확한 정보로 새로고침합니다.
                        <br />
                        <br />
                        ※최초 1회의 새로고침 이후 마켓 이용이 가능합니다.
                      </ButtonInformation>
                    );
                  }}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: -6,
                    cursor: "pointer",
                  }}
                />

                <div
                  className="sub_header_refresh_nft_btn"
                  onClick={refreshNFTHandler}
                >
                  내정보 새로고침
                </div>
              </div>
            </div>
          )}

          {type === "MY_PAGE" && (
            <div className="sub_header_my_wallet_flex">
              <span className="sub_header_text_span">{title}</span>
            </div>
          )}

          {type === "TOKEN" && (
            <div className="flex_center">
              <div className="flex_evenly">
                <span className="olea_sub_header_text_span">{title}</span>
                <span className="olea_sub_header_purchase_status">
                  잔여 구매 기회 : {tokenPurchaseStatus}회
                  {tokenPurchaseStatus > 0 ? " (구매 가능)" : " (구매 불가)"}
                  <img
                    src={QUESTIONMARK}
                    alt="QUESTIONMARK"
                    onClick={(e) => {
                      handleClick(e, <PurchaseChance />);
                    }}
                    style={{
                      position: "absolute",
                      top: 5,
                      marginLeft: 5,
                      cursor: "pointer",
                    }}
                  />
                </span>

                <span
                  className="olea_sub_header_text_span"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={COUNTDOWN} alt="COUNTDOWN" />
                  <span className="olea_sub_header_text_row_content">
                    <Timer states={{ count, setCount, pageType: "club" }} />
                    <img
                      src={QUESTIONMARK}
                      alt="QUESTIONMARK"
                      onClick={(e) => {
                        handleClick(e, <TimerInformation />);
                      }}
                      style={{
                        position: "absolute",
                        top: 5,
                        marginLeft: 5,
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="sub_header_empty_box"></div>
    </>
  );
};

export default React.memo(SubHeader);

const ButtonInformation = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 280px;
  background-color: #fff;
  padding: 10px;
  color: #222222;
  font-size: 14px;
  line-height: 120%;
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid #717171;

  /* transform: translateX(-280px); */
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
`;
