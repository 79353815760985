import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import jwtDecode from "jwt-decode";

import { getAccessToken } from "../../service/token";

import Modal from "../modal/Modal";
import DropDown from "./template/DropDown";

import LOGO from "../../assets/icons/LOGO.svg";

import "./Header.scss";
import { userTypeConvert } from "../../shared/typeConvert";

export default function Header() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userInfo?.userId);
  const userAddress = useSelector((state) => state.user.userInfo?.address);
  const [modalOpen, setModalOpen] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [userType, setUserType] = useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const navArr = useMemo(() => {
    if (userType === "discord")
      return [
        {
          name: "이용안내",
          path: "/how-to-use",
        },
        {
          name: "기프티콘",
          path: "/gifticon",
        },
        {
          name: "NFT",
          path: "/nft",
        },
      ];
    else {
      // 이메일 혹은 비로그인 시 메뉴
      return [
        {
          name: "이용안내",
          path: "/how-to-use",
        },
        {
          name: "기프티콘",
          path: "/gifticon",
        },
      ];
    }
  }, [userType]);

  // const navArr = [
  // {
  //   name: "HOLDER",
  //   path: "/holder",
  //   subNav: [],
  // },
  // {
  //   name: "CLUB",
  //   path: "/club",
  //   subNav: [],
  // },
  // {
  //   name: "JOY",
  //   path: "/joy",
  //   subNav: [],
  // },

  // ];

  const modalData = [
    {
      header: "로그인 안내",
      size: "sm",
      sort: "center",
      content: (
        <p>
          로그인이 필요한 서비스입니다.
          <br />
          로그인 페이지로 이동하시겠습니까?
        </p>
      ),
      btnType: "point",
      ico: true,
      leftBtn: true,
      btnRightText: "확인",
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
      rightBtnHandler: () => {
        dispatch(push("/sign-in"));
        setModalOpen(0);
      },
    },
    {
      header: "스마트 월렛 연동 안내",
      size: "sm",
      sort: "center",
      content: (
        <p>
          스마트 월렛 계정 연동이 필요한 서비스입니다.
          <br />
          연동 페이지로 이동하시겠습니까?
        </p>
      ),
      btnType: "point",
      ico: true,
      leftBtn: true,
      btnRightText: "확인",
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
      rightBtnHandler: () => {
        dispatch(push("/connect-wallet"));
        setModalOpen(0);
      },
    },
  ];

  useEffect(() => {
    if (!userId) return;
    const decoded = jwtDecode(getAccessToken());
    setUserType(decoded.loginType);
  }, [userId]);

  const clickPathHandler = (data) => {
    if (data.name === "이용안내") {
      dispatch(push(`${data.path}`));
      return;
    }

    if (data.name === "NFT") {
      if (userId && (!userAddress || userAddress === "")) {
        setModalOpen(2);
        return;
      }
    }

    if (!userId) {
      setModalOpen(1);
      return;
    }

    if (userId) {
      dispatch(push(`${data.path}`));
      return;
    }
  };

  const rightBtnClickHandler = () => {
    if (!userId) {
      dispatch(push("/sign-in"));
    }
  };

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <div className="gnb_wrapper">
        <div className="gnb_box_wrap">
          <div className="gnb_box gnb_left">
            <img
              src={LOGO}
              alt="OLEA MARKET LOGO"
              onClick={() => {
                dispatch(push("/"));
              }}
            />

            {navArr.map((data) => (
              <li
                className="gnb_nav_item"
                key={data.name}
                onClick={() => {
                  clickPathHandler(data);
                }}
              >
                {data.name}
              </li>
            ))}
          </div>

          <div className="gnb_box gnb_right">
            {userId && (
              <div className="gnb_badge">{userTypeConvert(userType)}</div>
            )}
            <div id="myBtn" className="gnb_right_button">
              {userId ? (
                <DropDown
                  states={{ open, anchorEl, placement, handleClick, setOpen }}
                />
              ) : (
                <>
                  <div
                    style={{ marginRight: 20 }}
                    onClick={() => dispatch(push("/sign-in"))}
                  >
                    로그인
                  </div>

                  <div onClick={() => dispatch(push("/sign-up"))}>회원가입</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="gnb_empty_box"></div>
    </>
  );
}
