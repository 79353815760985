import "./Error.scss";

const ErrorPage = () => {
  return (
    <div className="error_section">
      <div className="error_section_style">
        <h2>일시적인 오류가 발생하였습니다.</h2>
        <p>잠시 후 다시 시도해주세요.</p>
        <div className="btn_area">
          <button
            onClick={() => {
              window.location.href = "/";
            }}
          >
            메인 화면으로
          </button>

          <button
            onClick={() => {
              window.history.back();
            }}
          >
            이전으로
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
