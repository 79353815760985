import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

import ICON_MY from "../../../assets/icons/icon_my.svg";
import RIGHTARROWICON from "../../../assets/icons/rightArrow.svg";
import SIGNOUTICON from "../../../assets/icons/Group 912.svg";
import SIGNINICON from "../../../assets/icons/Group 910.svg";

import "./DropDown.scss";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener } from "@mui/material";
import { push } from "connected-react-router";
import { userSignOutSagaActionCreator } from "../../../redux/modules/user";
import { useState } from "react";
import Modal from "../../modal/Modal";

export default function DropDown({ states }) {
  const dispatch = useDispatch();
  const { open, anchorEl, placement, handleClick, setOpen } = states;
  const userId = useSelector((state) => state.user.userInfo?.userId);
  const userAddress = useSelector((state) => state.user.userInfo?.address);
  const userType = useSelector((state) => state.user.userInfo?.userType);

  const [modalOpen, setModalOpen] = useState(0);

  const modalData = [
    {
      header: "스마트 월렛 연동 안내",
      size: "sm",
      sort: "center",
      content: (
        <p>
          스마트 월렛 계정 연동이 필요한 서비스입니다.
          <br />
          연동 페이지로 이동하시겠습니까?
        </p>
      ),
      btnType: "point",
      ico: true,
      leftBtn: true,
      btnRightText: "확인",
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
      rightBtnHandler: () => {
        dispatch(push("/connect-wallet"));
        setModalOpen(0);
      },
    },
  ];

  const menuArr = [
    {
      name: "마이 페이지",
      function: () => {
        // if (userId && (!userAddress || userAddress === "")) {
        //   setModalOpen(1);
        //   return;
        // } else {
        //   dispatch(push("/my-page"));
        //   setOpen(false);
        // }
        dispatch(push("/my-page"));
        setOpen(false);
      },
      type: "category",
    },
    // {
    //   name: "NFT 지갑",
    //   function: () => {
    // if (userId && (!userAddress || userAddress === "")) {
    //   setModalOpen(1);
    //   return;
    // } else {
    //       dispatch(push("/my-wallet"));
    //       setOpen(false);
    //     }
    //   },
    //   type: "category",
    // },
    {
      name: "스마트 월렛 연동",
      function: () => {
        dispatch(push("/connect-wallet"));
        setOpen(false);
      },
      type: "category",
    },

    {
      name: "로그아웃",
      function: () => {
        dispatch(userSignOutSagaActionCreator());
        setOpen(false);
      },
      type: "button",
    },
  ];

  const emailUserMenuArr = [
    {
      name: "마이 페이지",
      function: () => {
        // if (userId && (!userAddress || userAddress === "")) {
        //   setModalOpen(1);
        //   return;
        // } else {
        //   dispatch(push("/my-page"));
        //   setOpen(false);
        // }
        dispatch(push("/my-page"));
        setOpen(false);
      },
      type: "category",
    },
    {
      name: "로그아웃",
      function: () => {
        dispatch(userSignOutSagaActionCreator());
        setOpen(false);
      },
      type: "button",
    },
  ];

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box>
          <Popper
            sx={{ width: 150, zIndex: 50 }}
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ padding: "10px 0", marginTop: "10px" }}>
                  {(userType === "email" ? emailUserMenuArr : menuArr).map(
                    (data) => (
                      <div
                        key={data.name}
                        className={
                          data.type === "category"
                            ? "drop_down_category"
                            : "drop_down_btn"
                        }
                        onClick={data.function}
                      >
                        {data.type === "button" && (
                          <img src={SIGNOUTICON} alt="RIGHTARROWICON" />
                        )}
                        {data.name}
                        {data.type === "category" && (
                          <img src={RIGHTARROWICON} alt="RIGHTARROWICON" />
                        )}
                      </div>
                    )
                  )}
                </Paper>
              </Fade>
            )}
          </Popper>
          <Grid container justifyContent="center">
            <Grid item>
              <div
                className="drop_down_head"
                onClick={handleClick("bottom-end")}
              >
                <img src={ICON_MY} alt="My icon" />내 정보
              </div>
            </Grid>
          </Grid>
        </Box>
      </ClickAwayListener>
    </>
  );
}
