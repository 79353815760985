import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import lodash
import _ from "lodash";

// import images
import WingLogo from "../../../../assets/images/wing_logo.svg";
import { tierList } from "../../../../shared/NftData";

import "./LookupNft.scss";
import { userGetBalanceSagaActionCreator } from "../../../../redux/modules/user";

const LookupNFT = () => {
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.user.balance);
  const error_balance = useSelector((state) => state.user.error_balance);
  const [search, setSearch] = useState({
    tier: "",
    edition: "",
  });

  const sendQuery = (query) => {
    dispatch(userGetBalanceSagaActionCreator(query));
  };

  const delayedQueryCall = useCallback(
    _.debounce((q) => sendQuery(q), 500),
    []
  );

  useEffect(() => {
    delayedQueryCall(search);
  }, [search]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
  };

  const handleInputChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      setSearch({
        ...search,
        [name]: value,
      });
    },
    [search]
  );

  return (
    <div className="lookup_nft_wrapper">
      <img src={WingLogo} alt="holders 로고이미지" />
      <form className="lookup_nft_form" onSubmit={onSubmitHandler}>
        <select name="tier" onChange={handleInputChange}>
          {tierList.map((cur, idx) => (
            <option key={idx} value={cur.value}>
              {cur.name}
            </option>
          ))}
        </select>

        <div className="lookup_nft_input_wrap">
          <input
            className="lookup_nft_search_input"
            onChange={handleInputChange}
            name="edition"
            type="number"
            placeholder="NFT를 검색해보세요! / 예: 100 "
            autoComplete="off"
          />
        </div>
      </form>

      <div className="lookup_nft_balance_box">
        {balance && (
          <div className="lookup_nft_balance">
            <span>{balance} OLEA</span>
          </div>
        )}
        {!balance && error_balance && (
          <div className="lookup_nft_balance">
            <span>{error_balance}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default LookupNFT;
