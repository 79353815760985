import LOGO from "../../../../assets/icons/OLEA_LOGO.svg";
import ArrowRightUp from "../../../../assets/icons/arrow_right_up.svg";
import ArrowRightDown from "../../../../assets/icons/arrow_right_down.svg";
import Nft_1 from "../../../../assets/icons/nft_1.svg";
import Nft_2 from "../../../../assets/icons/nft_2.svg";
import Nft_3 from "../../../../assets/icons/nft_3.svg";
import Nft_4 from "../../../../assets/icons/nft_4.svg";
import Nft_5 from "../../../../assets/icons/nft_5.svg";

import "./HomeNftInfo.scss";

const HomeNftInfo = () => {
  return (
    <div className="wrapper">
      <h3 className="title">
        아테나리턴즈 NFT에 매일 올레아(OLEA) 토큰 리워드!
      </h3>
      <div className="nft_info">
        <div className="logo_box">
          <img src={LOGO} alt="LOGO" />
        </div>

        <div className="arrows">
          <img src={ArrowRightUp} alt="우측상향 화살표" />
          <img src={ArrowRightDown} alt="우측하향 화살표" />
        </div>

        <div className="nft_box">
          <div className="tier_box">
            <div className="text_info_box">
              <p>A tier</p>

              <p>10 OLEA / day</p>
            </div>

            <div className="nft_avatar_box">
              <div className="nft_avatar">
                <img src={Nft_1} alt="NFT A tier 이미지" />
                <p className="nft_tier_type">Fantasy</p>
              </div>

              <div className="nft_avatar">
                <img src={Nft_2} alt="NFT A tier 이미지" />
                <p className="nft_tier_type">Fancy Dress</p>
              </div>
            </div>
          </div>

          <div className="tier_box">
            <div className="text_info_box">
              <p>B tier</p>

              <p>6 OLEA / day</p>
            </div>

            <div className="nft_avatar_box">
              <div className="nft_avatar">
                <img src={Nft_3} alt="NFT A tier 이미지" />
                <p className="nft_tier_type">Daily Dress</p>
              </div>

              <div className="nft_avatar">
                <img src={Nft_4} alt="NFT A tier 이미지" />
                <p className="nft_tier_type">Modern Clothes</p>
              </div>

              <div className="nft_avatar">
                <img src={Nft_5} alt="NFT A tier 이미지" />
                <p className="nft_tier_type">Pajama</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNftInfo;
