import { combineReducers } from "redux";

import { connectRouter } from "connected-react-router";
import user from "./user";
import market from "./market";
import loading from "./loding";
import game from "./game";
import admin from "./admin";
import auth from "./auth";

const rootReducer = (history) =>
  combineReducers({
    user,
    market,
    loading,
    game,
    admin,
    auth,
    router: connectRouter(history),
  });
export default rootReducer;
