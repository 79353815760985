import "./Claim.scss";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../modal/Modal";
import "./Claim.scss";
import React, { useEffect, useState } from "react";
import { modalData } from "../../modlaData";
import {
  getClaimRequestList,
  patchClaimToOLEACancel,
  patchClaimToOLEAComplete,
  setAdminState,
} from "../../../../../redux/modules/admin";
import MuiPagination from "../../../../muiPagination/MuiPagination";
import { currentPosts } from "../../../../../utils/paginationCurrentPosts";
import numberWithCommas from "../../../../../utils/numbersWithComas";
import { dateSet2 } from "../../../../../utils/dateSet";
import { CiMemoPad } from "react-icons/ci";
import SearchInput from "../SearchInput/SearchInput";
import checkAfter2023 from "../../../../../utils/checkAfter2023";

const ClaimToOLEA = () => {
  const dispatch = useDispatch();
  const claimToOLEARequestList = useSelector(
    (state) => state.admin.claimToOLEARequestList
  );

  const [renderArr, setRenderArr] = useState(claimToOLEARequestList || []);
  const [postsPerPage] = useState(10);
  const [postsCurPage, setPostsCurPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(0);
  const [deleteTarget, setDeleteTarget] = useState();

  const [clickedReqOrigin, setClickedReqOrigin] = useState({});
  const [clickedReq, setClickedReq] = useState({});
  const [isMemoEdit, setIsMemoEdit] = useState(false);
  const [memoType, setMemoType] = useState("notice");

  useEffect(() => {
    dispatch(getClaimRequestList("OLEA"));
  }, [dispatch]);

  useEffect(() => {
    setRenderArr(
      currentPosts(claimToOLEARequestList, postsCurPage, postsPerPage)
    );
  }, [claimToOLEARequestList, postsCurPage, postsPerPage]);

  useEffect(() => {
    setPostsCurPage(1);
  }, [claimToOLEARequestList.length]);

  const editedMemoHandler = (target) => {
    dispatch(
      setAdminState({
        claimToOLEARequestList: claimToOLEARequestList.map((req) => {
          if (req.claimLogsId === target.claimLogsId) {
            return target;
          }
          return req;
        }),
      })
    );
  };

  const paginationConvert = (arr) => {
    dispatch(setAdminState({ claimToOLEARequestList: arr }));
  };

  const modalPayload = {
    dispatch,
    setModalOpen,
    claimToOLEARequestList,

    clickedReqOrigin,

    clickedReq,
    setClickedReq,

    isMemoEdit,
    setIsMemoEdit,
    memoType,
    setMemoType,

    editedMemoHandler,

    deleteTarget,
    pageType: "ClaimToOLEA",
  };

  const OLEATheadArr = [
    { key: "nftId", value: "NFT ID" },
    { key: "email", value: "이메일" },
    {
      key: "amount",
      value: "지급 예정 OLEA COIN",
    },
    { key: "status", value: "상태" },
    { key: "createdAt", value: "신청일" },
  ];

  return (
    <>
      {modalOpen !== 0 && (
        <Modal data={modalData[modalOpen]} modalPayload={modalPayload} />
      )}

      {
        <>
          <div className="list_area">
            <SearchInput
              typeList={OLEATheadArr}
              origin={claimToOLEARequestList}
              setOrigin={paginationConvert}
            />

            <table>
              <thead>
                <tr>
                  {OLEATheadArr.map((th) => (
                    <th key={th.value}>{th.value}</th>
                  ))}
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {renderArr.map((req) => (
                  <tr key={req.claimLogsId}>
                    <td>{req.nftId}</td>

                    <td>{req.email}</td>

                    <td>{numberWithCommas(req.amount, "OLEA")} </td>

                    <td>
                      <span
                        className={`${req.status === "취소" ? "cancel" : ""}
                        ${req.status === "완료" ? "done" : ""}`}
                      >
                        {req.status}
                      </span>
                    </td>

                    <td>{dateSet2(req.createdAt)}</td>

                    <td>
                      <div className="btn_area">
                        {checkAfter2023(req.createdAt) && (
                          <>
                            {req.status !== "취소" && (
                              <button
                                className="default"
                                onClick={() =>
                                  dispatch(patchClaimToOLEACancel(req))
                                }
                              >
                                취소
                              </button>
                            )}

                            {req.status === "신청" && (
                              <button
                                className="point"
                                onClick={() =>
                                  dispatch(
                                    patchClaimToOLEAComplete({
                                      id: req.claimLogsId,
                                    })
                                  )
                                }
                              >
                                출금 완료
                              </button>
                            )}

                            <CiMemoPad
                              onClick={() => {
                                setClickedReq(req);
                                setClickedReqOrigin(req);
                                setModalOpen("CLAIM_OLEA_MEMO");
                              }}
                            />

                            <div
                              className="deleteBtn"
                              onClick={() => {
                                setModalOpen("DELETE_CHECK");
                                setDeleteTarget(req.claimLogsId);
                              }}
                            >
                              &times;
                            </div>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {renderArr && (
              <div className="pagination">
                <MuiPagination
                  count={Math.ceil(
                    claimToOLEARequestList.length / postsPerPage
                  )}
                  cur={postsCurPage}
                  setCur={setPostsCurPage}
                />
              </div>
            )}
          </div>
        </>
      }
    </>
  );
};

export default ClaimToOLEA;
