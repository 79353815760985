import { Pagination } from "@mui/material";

const MuiPagination = ({ count, cur, setCur, dispatch }) => {
  return (
    <div className="paging">
      <Pagination
        shape="rounded"
        count={count}
        page={cur}
        className="paging"
        onChange={(e, value) => {
          if (dispatch) {
            dispatch(setCur(value));
          } else {
            setCur(value);
          }
        }}
      />
    </div>
  );
};

export default MuiPagination;
