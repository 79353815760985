import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./index";
import { ErrorBoundary } from "react-error-boundary";

import ErrorPage from "./components/pages/error/ErrorPage";
import Router from "./shared/Router/Router";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { Mobile, PC } from "./hooks/MediaQuery";
import MobileInfo from "./components/pages/mobile/MobileInfo";
import AdminHeader from "./components/pages/admin/components/header/Header.jsx";

import { userInitSagaActionCreator } from "./redux/modules/user";
import { CircularProgress } from "@mui/material";

import "@rainbow-me/rainbowkit/styles.css";

import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { mainnet, goerli } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import Tools from "./components/pages/admin/components/Tools/Tools.jsx";
// import Maintain from "./components/pages/error/Maintain";
import backgroundImg from "./assets/mainImage/olea_market_bg.png";

//* Wagmi Config 현재 사용 할 network와 provider를 설정 *//
const { chains, publicClient } = configureChains(
  process.env.REACT_APP_ENVIRONMENT === "product"
    ? [mainnet]
    : [mainnet, goerli],
  [publicProvider()]
);

//* creatConfig: config의 상세 설정. 자동연결 및 스마트월렛 등 추가 *//
const config = createConfig({
  autoConnect: true,
  connectors: [new MetaMaskConnector({ chains })],
  publicClient,
});

function Loading() {
  return (
    <div className="loading-wrap">
      <CircularProgress
        style={{
          zIndex: "9999",
          width: "80px",
          height: "80px",
          color: "#e8e7ff",
        }}
      />
    </div>
  );
}

function App() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);
  const userId = useSelector((state) => state.user?.userInfo?.userId);
  const authState = useSelector((state) => state.auth);

  const checkWWWUrl = process.env.REACT_APP_CHECK_WWW_URL;
  const WWWRedirect = process.env.REACT_APP_WWW_REDIRECT;

  useEffect(() => {
    const host = window.location.host;
    if (host === checkWWWUrl) {
      const wl = window.location;
      window.location.href = `${wl.protocol}${WWWRedirect}${wl.pathname}`;
    }
  }, []);

  useEffect(() => {
    dispatch(userInitSagaActionCreator());
  }, [authState]);

  return (
    //* WagmiConfig: WagmiConfig를 사용하여 wagmi의 config를 설정 *//
    <WagmiConfig config={config}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <ConnectedRouter history={history}>
          {loading && <Loading />}
          <Mobile>
            <MobileInfo />
          </Mobile>
          <PC>
            {process.env.REACT_APP_ADMIN_USER_ID?.includes(userId) ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "80px",
                  height: "100vh",
                  backgroundImage: `url(${backgroundImg})`,
                }}
              >
                <AdminHeader />
                <Router />
                <Tools />
              </div>
            ) : (
              <>
                {/* <Maintain /> */}
                <Header />
                <Router />
                <Footer />
              </>
            )}
          </PC>
        </ConnectedRouter>
      </ErrorBoundary>
      {/* </RainbowKitProvider> */}
    </WagmiConfig>
  );
}

export default App;
