import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { marketGetShowSagaActionCreator } from "../../../redux/modules/market";
import { userGetMetamaskInfoSagaActionCreator } from "../../../redux/modules/user";

import "./Timer.scss";

export default function Timer({ states }) {
  const { count, setCount, pageType } = states;
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    let countdown;
    if (count <= 300) {
      countdown = setInterval(() => {
        setCount(count < 300 ? count + 1 : count);
        if (parseInt(seconds) > 0) {
          setSeconds(parseInt(seconds) - 1);
        }
        if (parseInt(seconds) === 0) {
          if (parseInt(minutes) === 0) {
            clearInterval(countdown);
            dispatch(marketGetShowSagaActionCreator());
            dispatch(userGetMetamaskInfoSagaActionCreator(pageType));
            setMinutes(5);
            setCount(0);
          } else {
            setMinutes(parseInt(minutes) - 1);
            setSeconds(59);
          }
        }
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [minutes, seconds, count]);

  return (
    <>
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      {minutes === 0 && (
        <span className="timer_warning">
          (곧 만료되어 시세가 변동될 수 있습니다)
        </span>
      )}
    </>
  );
}
