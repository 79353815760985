import "./Tools.scss";
import React, { useEffect } from "react";
import oleaIcon from "../../../../../assets/icons/olea_symbol.png";
import { useState } from "react";
import { useSelector } from "react-redux";

const Tools = () => {
  const oleaPrice = useSelector((state) => state.admin.olea.price);
  const [isOpen, setIsOpen] = useState(false);
  const [inputType, setInputType] = useState("OLEA");
  const [resultType, setResultType] = useState("KRW");
  const [inputValue, setInputValue] = useState(1);
  const [resultValue, setResultValue] = useState(0);

  useEffect(() => {
    if (inputType === "OLEA") {
      setResultValue(inputValue * oleaPrice);
    }

    if (inputType === "KRW") {
      setResultValue(inputValue / oleaPrice);
    }
  }, [inputValue, oleaPrice, inputType]);

  return (
    <div className="Tools">
      {isOpen ? (
        <div className="tool_wrapper">
          <div className="tool_header">
            <span>OLEA COIN ↔ KRW 변환기</span>

            <div className="btn_close" onClick={() => setIsOpen(!isOpen)}>
              &times;
            </div>
          </div>

          <div className="tool_body">
            <div className="content_area">
              <div className="name_area">{inputType}</div>
              <input
                type="number"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={`계산할 ${inputType}를 입력해 주세요.`}
              />
              <div className="unit_area">{inputType}</div>
            </div>

            <div
              className="btn_change"
              onClick={() => {
                setInputType(inputType === "OLEA" ? "KRW" : "OLEA");
                setResultType(resultType === "OLEA" ? "KRW" : "OLEA");
              }}
            >
              ↓↑
            </div>

            <div className="content_area">
              <div className="name_area">{resultType}</div>
              <div className="result_area">{Math.ceil(resultValue)}</div>
              <div className="unit_area">{resultType}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="icon_area" onClick={() => setIsOpen(!isOpen)}>
          <img
            className="tool_icon"
            src={oleaIcon}
            width={40}
            height={40}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default Tools;
