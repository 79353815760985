import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import Table from "@mui/material/Table/";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "./PPInformation.scss";

const StyledTableCell = muiStyled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#636190",
    color: "#fff",
    textAlign: "left",
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f7f8f9",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(a, b, c) {
  return { a, b, c };
}

const topRows = [
  createData("A1", "Fantasy", "+9"),
  createData("A2", "Fancy Dress", "+8"),
  createData("B1", "Daily Dress", "+6"),
  createData("B2", "Modern Clothes", "+4"),
  createData("B3", "Pajama", "+2"),
  createData("5종", "동시 보유 1set당", "+1(BONUS PP)"),
];

const bottomRows = [
  createData("Level 60 제우스", "+20"),
  createData("Level 50 아테나", "+15"),
  createData("Level 45 포세이돈", "+12"),
  createData("Level 40 니케", "+9"),
  createData("Level 35 아폴론", "+7"),
  createData("Level 30 헤르메스", "+5"),
  createData("Level 25 아르테미스", "+4"),
  createData("Level 20 디오니소스", "+3"),
  createData("Level 15 프로메테우스", "+2"),
  createData("Level 10 헤라클레스", "+1"),
  createData("쿠폰 사용 후기 30개당", "+1(BONUS PP)"),
];

const PPInformation = () => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: 500,
        opacity: 0.95,
      }}
    >
      <div className="pp_info_olea_info_box">
        <div>
          <span className="pp_info_sub_text">
            PP는 OLEA 토큰 시세와 연동됩니다.
          </span>
        </div>
      </div>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>NFT</StyledTableCell>
            <StyledTableCell align="right">Tier & Type</StyledTableCell>
            <StyledTableCell align="right">PP</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {topRows.map((row) => (
            <StyledTableRow key={row.a}>
              <StyledTableCell component="th" scope="row">
                {row.a}
              </StyledTableCell>
              <StyledTableCell align="right">{row.b}</StyledTableCell>
              <StyledTableCell align="right">{row.c}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2}>
              디스코드 커뮤니티 레벨
            </StyledTableCell>
            <StyledTableCell align="right">PP</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bottomRows.map((row) => (
            <StyledTableRow key={row.a}>
              <StyledTableCell component="th" scope="row" colSpan={2}>
                {row.a}
              </StyledTableCell>
              <StyledTableCell align="right">{row.b}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PPInformation;
