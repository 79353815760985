import { useDispatch } from "react-redux";
import Modal from "../../../../modal/Modal";
import "./Claim.scss";
import React, { useEffect, useState } from "react";
import { modalData } from "../../modlaData";
import { getClaimRequestList } from "../../../../../redux/modules/admin";
import ClaimToOLEA from "./ClaimToOLEA";
import ClaimToPoint from "./ClaimToPoint";
const Claim = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(0);
  const [listType, setListType] = useState("OLEA");

  useEffect(() => {
    dispatch(getClaimRequestList(listType));
  }, [dispatch, listType]);

  const modalPayload = {
    dispatch,
    setModalOpen,
  };

  return (
    <>
      {modalOpen !== 0 && (
        <Modal data={modalData[modalOpen]} modalPayload={modalPayload} />
      )}

      <div className="Claim">
        <div className="title_area">
          <h2>출금 신청 관리</h2>

          <ul>
            <li
              className={listType === "OLEA" ? "clicked" : ""}
              onClick={() => setListType("OLEA")}
            >
              to OLEA COIN
            </li>
            <li
              className={listType === "OLEA POINT" ? "clicked" : ""}
              onClick={() => setListType("OLEA POINT")}
            >
              to POINT
            </li>
          </ul>
        </div>

        {listType === "OLEA" && <ClaimToOLEA />}
        {listType === "OLEA POINT" && <ClaimToPoint />}
      </div>
    </>
  );
};

export default Claim;
