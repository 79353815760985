import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NFTTypeConvert } from "../../../../../shared/NftData";

import ROULETTE_BTN from "../../../../../assets/images/roulette_button.png";
import { userPostBuyGameMoneySagaActionCreator } from "../../../../../redux/modules/user";

const ClubBuyGameMoneyComponent = ({ states }) => {
  const { setModalOpen, gameMoneyPrice } = states;
  const dispatch = useDispatch();

  const userNftArr = useSelector((state) => state.user.userMetaMaskInfo);
  const voucherState = useSelector((state) => state.user.voucher);

  const [count, setCount] = useState(1);
  const [selectedValue, setSelectedValue] = useState("voucher");

  const handleChage = (e) => {
    setSelectedValue(e.target.value);
  };

  // const handleCountChange = (e) => {
  //   const inputCount = parseInt(e.target.value);
  //   if (isNaN(inputCount)) {
  //     setCount(1);
  //     return;
  //   }
  //   if (selectedValue === "voucher") {
  //     if (inputCount > voucherState) {
  //       return;
  //     } else {
  //       setCount(inputCount);
  //     }
  //   } else {
  //     if (inputCount > userNftArr[0]?.balance) {
  //       return;
  //     } else {
  //       setCount(inputCount);
  //     }
  //   }
  // };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const increment = () => {
    let comparison;
    if (selectedValue === "voucher") {
      comparison = Math.floor(voucherState / gameMoneyPrice);
    } else {
      comparison = Math.floor(userNftArr[0].balance / gameMoneyPrice);
    }

    if (count < comparison) {
      setCount(count + 1);
    }
  };

  // 구매버튼 클릭시 실행
  const clickBuyBtnHandler = useCallback(() => {
    let payload;
    if (selectedValue === "voucher") {
      // 바우처를 선택시,
      payload = {
        pageType: "voucher",
        gameMoneyCount: count,
      };

      if (voucherState < count) {
        setModalOpen(3);
      } else {
        dispatch(
          userPostBuyGameMoneySagaActionCreator({
            payload,
            setModalOpen,
            pageType: "club",
          })
        );
        setModalOpen(0);
      }
    } else {
      // NFT를 선택시,
      payload = {
        pageType: "nft",
        nftId: selectedValue,
        gameMoneyCount: count,
      };

      if (userNftArr[0].balance < count) {
        setModalOpen(3);
      } else {
        dispatch(
          userPostBuyGameMoneySagaActionCreator({
            payload,
            setModalOpen,
            pageType: "club",
          })
        );
        setModalOpen(0);
      }
    }
  }, [selectedValue, count]);

  return (
    <>
      <div className="buy_ticket_component">
        <div>
          <img src={ROULETTE_BTN} alt="ticket" style={{ width: "70px" }} />
          <span>
            <button onClick={decrement}>-</button>
            {count * 100}게임머니<button onClick={increment}>+</button>
          </span>
        </div>
        <div>
          <span>구매수단 선택</span>
          <select value={selectedValue} onChange={handleChage}>
            <option value={"voucher"}>
              {`Voucher / ${voucherState} OLEA`}
            </option>

            {userNftArr.length ? (
              <option value={userNftArr[0].nftId}>
                {`ATENA ${NFTTypeConvert(userNftArr[0].tierType)} ${
                  userNftArr[0].edition
                } / ${userNftArr[0].balance} OLEA`}
              </option>
            ) : null}
            {/* <option value={userNftArr[0].nftId}>
              {`ATENA ${NFTTypeConvert(userNftArr[0].tierType)} ${
                userNftArr[0].edition
              } / ${userNftArr[0].balance} OLEA`}
            </option> */}
          </select>
        </div>

        <button onClick={clickBuyBtnHandler}>구매</button>
      </div>
    </>
  );
};

export default ClubBuyGameMoneyComponent;
