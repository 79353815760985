const prefix = "olea/loading";

// action type
const actionType = {};

const actionTypeArray = ["LOADING_ON", "LOADING_OFF"];

actionTypeArray.forEach((data) => {
  actionType[data] = `${prefix}/${data}`;
});

const { LOADING_ON, LOADING_OFF } = actionType;

export const actionCreator = (type) => ({
  type,
});

// action creator
export const loadingOn = () => actionCreator(LOADING_ON);
export const loadingOff = () => actionCreator(LOADING_OFF);

// initial state
const initailState = {
  loading: false,
};

// reducer
export default function loadingReducer(state = initailState, action) {
  switch (action.type) {
    case LOADING_ON:
      return {
        ...state,
        loading: true,
      };

    case LOADING_OFF:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
