import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NFTTypeConvert } from "../../../../shared/NftData";
import MuiPagination from "../../../muiPagination/MuiPagination";
import { currentPosts } from "../../../muiPagination/currentPost";
import randomString from "../../../../utils/randomString";
import "./NftInfo.scss";
import dateSet from "../../../../utils/dateSet";
import Modal from "../../../modal/Modal";

// amount, claimState, nftInfo, createdAt, nowDate

const MyWalletOleaClaimModal = ({ states }) => {
  // const { nftId } = states;
  const oleaClaimLog = useSelector((state) => state.user.oleaClaimLog);

  const [tradeLogArr, setTradelogArr] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(4);
  const [renderData, setRenderData] = useState([]);
  const [tabType, setTabType] = useState("OLEA");
  const [isMemoOpen, setIsMemoOpen] = useState(false);
  const [memoContent, setMemoContent] = useState("");

  useEffect(() => {
    setCurPage(1);
  }, [tabType]);

  useEffect(() => {
    setTradelogArr(oleaClaimLog.filter((item) => item.claimType === tabType));
  }, [oleaClaimLog, tabType]);

  useEffect(() => {
    setRenderData(currentPosts(tradeLogArr, curPage, postsPerPage));
  }, [curPage, postsPerPage, tradeLogArr]);

  // const renderClaimStatus = (status) => {
  //   switch (status) {
  //     case 0:
  //       return "요청";
  //     case 1:
  //       return "완료";
  //     case 2:
  //       return "반려";
  //     default:
  //       return;
  //   }
  // };

  return (
    <div className="nft_info_wrapper">
      <div className="nft_info_log_box">
        <div className="nft_info_tradelog_wrapper">
          <ul className="nft_info_tradelog_tabs">
            <li
              onClick={() => setTabType("OLEA")}
              className={tabType === "OLEA" ? "clicked" : ""}
            >
              OLEA
            </li>
            <li
              onClick={() => setTabType("OLEA POINT")}
              className={tabType === "OLEA POINT" ? "clicked" : ""}
            >
              POINT
            </li>
          </ul>
          <div className="nft_info_tradelog_header">
            {["NFT", "출금 신청", "진행 상태", "요청일", ""].map((data) => (
              <span key={data}>{data}</span>
            ))}
          </div>

          <div className="nft_info_tradelog_body">
            {renderData.map((data) => (
              <div key={randomString()}>
                <span>
                  {NFTTypeConvert(data.nftInfo.tierType)} #
                  {data.nftInfo.edition}
                </span>

                <span>
                  {data.amount.toLocaleString()} {data.claimType}
                </span>

                <span
                  className={`${data.status === "취소" ? "cancel" : ""}
                        ${data.status === "완료" ? "done" : ""}`}
                >
                  {data.status}
                </span>

                <span>{dateSet(data.createdAt)}</span>

                {data.memoForNotice ? (
                  <span>
                    {isMemoOpen && (
                      <Modal
                        data={{
                          header: "관리자 메세지",
                          size: "sm",
                          ico: false,
                          sort: "center",
                          content: (
                            <>
                              <div className="memo_div">{memoContent}</div>
                            </>
                          ),
                          btnType: "point",
                          leftBtn: false,
                          btnRightText: "닫기",
                          rdcBtnHandler: ({ modalPayload }) => {
                            modalPayload.setIsMemoOpen(false);
                            setMemoContent("");
                          },
                        }}
                        modalPayload={{ setIsMemoOpen }}
                      />
                    )}
                    <button
                      onClick={() => {
                        setIsMemoOpen(true);
                        setMemoContent(data.memoForNotice);
                      }}
                      className="btn point"
                    >
                      관리자 메세지
                    </button>
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
            ))}
          </div>
        </div>

        <MuiPagination
          count={Math.ceil(tradeLogArr?.length / postsPerPage)}
          cur={curPage}
          setCur={setCurPage}
        />
      </div>
    </div>
  );
};

export default MyWalletOleaClaimModal;
