import axios from "axios";
import { getAccessToken } from "./token";

const USER_URI = `${process.env.REACT_APP_API_URL}/api/users`;
const A2P_API_PREPARE_URL = "https://a2a-api.klipwallet.com/v2/a2a/prepare";
const APP_NAME = "OLEA MARKET";
const KLIP_ACCESS_URL = "https://a2a-api.klipwallet.com/v2/a2a/result";
const CLAIM_URI = `${process.env.REACT_APP_API_URL}/api/claimLogs`;
const POINT_URI = `${process.env.REACT_APP_API_URL}/api/point`;

export default class UserService {
  static async getUserScores() {
    const { data } = await axios({
      method: "GET",
      url: `${USER_URI}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getUserTicket() {
    const { data } = await axios({
      method: "GET",
      url: `${USER_URI}`,
      params: { type: "ticket" },
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getUserVoucher() {
    const { data } = await axios({
      method: "GET",
      url: `${USER_URI}`,
      params: { type: "voucher" },
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async registerMetamask(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${USER_URI}/address`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  static async getUserMetaMask(pageType) {
    const { data } = await axios({
      method: "GET",
      url: `${USER_URI}/nfts`,
      params: {
        pageType: pageType,
      },
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async refreshNftList() {
    const { data } = await axios({
      method: "POST",
      url: `${USER_URI}/refreshes`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async connectKlip() {
    const { data } = await axios({
      method: "POST",
      url: `${A2P_API_PREPARE_URL}`,
      data: {
        bapp: {
          name: APP_NAME,
        },
        type: "auth",
      },
    });
    return data;
  }

  static async getKlipAccessUrl(requestKey) {
    const response = await axios({
      method: "GET",
      url: `${KLIP_ACCESS_URL}`,
      params: {
        request_key: requestKey,
      },
    });
    return response;
  }

  static async deleteUserInfo() {
    const { data } = await axios({
      method: "DELETE",
      url: `${USER_URI}/delete`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async postBuyTicket(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${USER_URI}/buy/ticket`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      params: {
        pageType: payload.pageType,
      },
      data: {
        nftId: payload.nftId,
        ticketCount: payload.ticketCount,
      },
    });
    return data;
  }

  static async postBuyGameMoney(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${USER_URI}/buy/gameMoney`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      params: {
        pageType: payload.pageType,
      },
      data: {
        nftId: payload.nftId,
        gameMoneyCount: payload.gameMoneyCount,
      },
    });
    return data;
  }

  static async postRequestClaim(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${USER_URI}/claim`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: { claimCoin: payload },
    });
    return data;
  }

  static async getClaimLogs(payload) {
    const { data } = await axios({
      method: "GET",
      url: `${CLAIM_URI}/${payload}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getClaimLogsPoint(payload) {
    const { data } = await axios({
      method: "GET",
      url: `${CLAIM_URI}/point/${payload}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async postClaimToOleaRequest(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${CLAIM_URI}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: {
        ...payload,
      },
    });
    return data;
  }
  static async postClaimToPointRequest(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${CLAIM_URI}/point`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: {
        ...payload,
      },
    });
    return data;
  }

  static async getPointLogs() {
    const { data } = await axios({
      method: "GET",
      url: `${POINT_URI}/inquiry`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }
}
