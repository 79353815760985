import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import addressFormSchema from "../../../utils/addressFormSchema";

import EMAILINQUIREIMAGE from "../../../assets/emailInquireImage/emai_inquire_img.svg";

import "./Inquire.scss";
import { userPostEmailInquireSagaActionCreator } from "../../../redux/modules/user";
import { useState } from "react";
import Modal from "../../modal/Modal";

const AddressInquire = () => {
  const { userEmail } = useSelector((state) => state.user.userInfo);
  // hooks
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(0);
  const [payload, setPayload] = useState({});

  const validationSchema = addressFormSchema;

  const formik = useFormik({
    initialValues: {
      subject: "",
      content: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setPayload({
        email: userEmail,
        subject: "[연동 해지 문의] 메타마스크 계정 주소 연동 해지 문의",
        content: values.content,
      });
      setModalOpen(1);
    },
  });

  const modalData = [
    {
      header: "이메일 문의",
      size: "sm",
      sort: "center",
      content: <p>문의 메일을 보내시겠습니까?</p>,
      btnType: "point",
      leftBtn: true,
      btnRightText: "확인",
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
      rightBtnHandler: () => {
        dispatch(
          userPostEmailInquireSagaActionCreator({ payload, setModalOpen })
        );
      },
    },
    {
      header: "이메일 문의 완료",
      size: "sm",
      sort: "center",
      content: (
        <p>이메일 문의를 전송했습니다. 빠른 시일 내 답변드리겠습니다.</p>
      ),
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    {
      header: "오류",
      size: "sm",
      sort: "center",
      content: <p>오류가 발생했습니다.</p>,
      ico: true,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
  ];

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <div className="inquire_wrapper">
        <div className="inquire_style_wrapper">
          <div className="inquire_style_container">
            <div className="inquire_style_image_box">
              <img src={EMAILINQUIREIMAGE} alt="계정 연동 해지하기 이미지" />
            </div>
            <span className="inquire_style_title_text">계정 연동 해지</span>

            <form onSubmit={formik.handleSubmit} method="GET">
              <div className="inquire_style_form_wrapper">
                <div className="inquire_style_form_box">
                  <div className="inquire_style_input_box">
                    <label className="inquire_style_top_input_label">
                      <label htmlFor="content">
                        <span className="inquire_style_top_input_label_text">
                          해지사유
                        </span>
                      </label>
                    </label>
                    <div className="inquire_style_top_input_wrap">
                      <textarea
                        className="inquire_style_text_area"
                        id="content"
                        name="content"
                        placeholder="해지사유을 입력해 주세요. 1,000자 제한"
                        maxLength="1000"
                        rows="10"
                        {...formik.getFieldProps("content")}
                      />
                      {formik.touched.content && formik.errors.content && (
                        <span className="inquire_style_input_alert">
                          {formik.errors.content}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="inquire_style_send_email_btn"
                type="submit"
                size="sm"
                btnform="fill"
              >
                메일 전송
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressInquire;
