import React, { useEffect, useRef, useState } from "react";
import {
  NFTTypeConvert,
  renderCurrentTypeConvert,
  tierTypeConvert,
} from "../../../../shared/NftData";

import VanillaTilt from "vanilla-tilt";

import "./MyWalletGlareCards.scss";
import Modal from "../../../modal/Modal";
import NftInfo from "./NftInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  userGetOleaClaimLogSagaActionCreator,
  userGetTradeLogSagaActionCreator,
  userPostOleaClaimSagaActionCreator,
} from "../../../../redux/modules/user";
import MyWalletOleaClaimModal from "./MyWalletOleaClaimModal";
import MyWalletRequestOleaClaim from "./MyWalletRequestOleaClaim";

const claimStandard = (nftType) => {
  switch (nftType) {
    case 1:
      return 3000; // "A"
    case 2:
      return 3000; // "A"
    case 3:
      return 2000; // "B"
    case 4:
      return 2000; // "B"
    case 5:
      return 2000; // "B"
    case 6:
      return 5000; // "SE"
    case 7:
      return 5000; // "SE"
    case 8:
      return 5000; // "SE"
    case 9:
      return 5000; // "Titan"
    case 10:
      return 5000; // "Aegis"
    default:
      return null;
  }
};

const MyWalletGlareCards = ({ states }) => {
  const dispatch = useDispatch();
  const tilt = useRef(null);
  const {
    imageUrl,
    tierType,
    edition,
    airdrop,
    coinCount,
    balance,
    nftId,
    event,
  } = states.data;

  const userId = useSelector((state) => state.user.userInfo?.userId);
  const toggleInfo = states.toggleInfo;
  const [modalOpen, setModalOpen] = useState(0);
  const [amount, setAmount] = useState(0);
  const [payload, setPayload] = useState({});
  const MIN_OLEA_CLAIM_VALUE = 100;

  useEffect(() => {
    const options = {
      reverse: false,
      max: 35,
      startX: 0,
      startY: 0,
      perspective: 1000,
      scale: 1.05,
      speed: 500,
      transition: true,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      glare: true,
      "max-glare": 0.5,
      "mouse-event-element": null,
      gyroscope: true,
      gyroscopeMinAngleX: -45,
      gyroscopeMaxAngleX: 45,
      gyroscopeMinAngleY: -45,
      gyroscopeMaxAngleY: 45,
    };
    VanillaTilt.init(tilt.current, options);
  }, []);

  const cardInfoData = [
    {
      mobileText: "구분",
      renderText: tierTypeConvert(tierType),
      renderType: "",
    },
    { mobileText: "토큰 아이디", renderText: `# ${edition}`, renderType: "" },
    {
      mobileText: "타입",
      renderText: renderCurrentTypeConvert(tierType),
      renderType: "",
    },
    {
      mobileText: "리워드",
      renderText: `${airdrop.toLocaleString()} OLEA`,
      renderType: "",
    },
    {
      mobileText: "이벤트 리워드",
      renderText: `${event.toLocaleString()} OLEA`,
      renderType: "",
    },
    {
      mobileText: "사용",
      renderText: `${coinCount.toLocaleString()} OLEA`,
      renderType: "valid",
    },
    {
      mobileText: "잔여",
      renderText: `${balance.toLocaleString()} OLEA`,
      renderType: "invalid",
    },
  ];

  useEffect(() => {
    setPayload({
      nftId: nftId,
      amount: amount,
      type: tierType,
      userId: userId,
    });
  }, [nftId, amount, userId, tierType]);

  const modalData = [
    {
      // 1
      header: "NFT 조회",
      size: "lg",
      sort: "center",
      content: <NftInfo states={{ imageUrl, tierType, edition }} />,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    {
      // 2
      header: "출금 신청 내역",
      size: "lg",
      sort: "center",
      content: <MyWalletOleaClaimModal states={{ nftId, tierType }} />,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    {
      // 3
      header: "출금 신청",
      size: "sm",
      sort: "center",
      content: (
        <MyWalletRequestOleaClaim
          states={{
            imageUrl,
            tierType,
            edition,
            airdrop,
            coinCount,
            balance,
            nftId,
            event,
            amount,
            setAmount,
            claimStandard,
          }}
        />
      ),
      btnType: "point",
      leftBtn: true,
      btnLeftText: "POINT로 받기",
      btnRightText: "지갑으로 받기",
      dimBtnHandler: () => {
        setModalOpen(0);
      },
      closeBtnHandler: () => {
        setModalOpen(0);
      },
      leftBtnHandler: () => {
        if (amount <= 0 || isNaN(amount)) {
          alert("출금 신청할 OLEA를 입력해 주세요.");
          return;
        }
        if (amount < 10000) {
          alert("최소 10,000 OLEA 부터 출금 신청이 가능합니다.");
          return;
        }
        if (amount % 10000 !== 0) {
          alert("10,000 OLEA 단위로 출금 신청이 가능합니다.");
          return;
        }
        dispatch(
          userPostOleaClaimSagaActionCreator({
            payload,
            setModalOpen,
            claimType: "point",
          })
        );
      },
      rightBtnHandler: () => {
        if (amount <= 0 || isNaN(amount)) {
          alert("출금 신청할 OLEA를 입력해 주세요.");
          return;
        }
        if (amount < 10000) {
          alert("최소 10,000 OLEA 부터 출금 신청이 가능합니다.");
          return;
        }
        if (amount % 10000 !== 0) {
          alert("10,000 OLEA 단위로 출금 신청이 가능합니다.");
          return;
        }
        // const compareArr = oleaClaimLog.filter(
        //   (item) => item.nftInfo.nftId === nftId
        // );
        // if (
        //   compareArr.some(
        //     (data) => dateSet(data.createdAt) === dateSet(data.nowDate)
        //   )
        // ) {
        //   alert("오늘은 해당 NFT로 더이상 OLEA 출금 신청을 할 수 없습니다.");
        //   return;
        // }
        dispatch(
          userPostOleaClaimSagaActionCreator({
            payload,
            setModalOpen,
            claimType: "olea",
          })
        );
      },
    },
    {
      // 4
      header: "출금 신청 완료",
      size: "sm",
      sort: "center",
      content: <p>출금 신청이 완료되었습니다.</p>,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
  ];

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <div
        ref={tilt}
        className={`my_wallet_glare_card_wrapper ${
          balance < claimStandard(tierType) + MIN_OLEA_CLAIM_VALUE
            ? undefined
            : "possible_to_claim_shadow"
        }`}
      >
        <div
          style={
            balance < claimStandard(tierType) + MIN_OLEA_CLAIM_VALUE
              ? null
              : { backgroundColor: "#fff8eb" }
          }
        >
          <p>
            {NFTTypeConvert(tierType)}
            <span>{edition}</span>
          </p>
        </div>
        <div>
          <p>{renderCurrentTypeConvert(tierType)}</p>
        </div>
        <img src={imageUrl} alt={imageUrl} />
        <div
          className="glare_card_info"
          style={
            toggleInfo
              ? { visibility: "visible", top: 0, transform: "translateY(0px)" }
              : null
          }
        >
          <div className="glare_button_box1">
            <button
              className="glare_card_check_log_btn"
              onClick={() => {
                dispatch(userGetTradeLogSagaActionCreator(nftId));
                setModalOpen(1);
              }}
            >
              구매내역 확인
            </button>
          </div>

          {cardInfoData.map((data) => (
            <div className="glare_card_info_text_box" key={data.mobileText}>
              <span className={`my_wallet_card_text ${data.renderType}`}>
                {data.mobileText}
              </span>

              <span
                className={`my_wallet_card_text ${data.renderType}`}
                style={data.renderType === "" ? { color: "#555" } : undefined}
              >
                {data.renderText}
              </span>
            </div>
          ))}

          <div className="glare_button_box2">
            <button
              className="glare_card_claim_btn"
              onClick={() => {
                setModalOpen(3);
              }}
            >
              출금 신청
            </button>
            <button
              className="glare_card_claim_btn"
              onClick={() => {
                dispatch(userGetOleaClaimLogSagaActionCreator({ nftId }));
                setModalOpen(2);
              }}
            >
              출금 신청 내역
            </button>

            {/* {balance < claimStandard(tierType) + MIN_OLEA_CLAIM_VALUE ? null : (
              <button
                className="glare_card_claim_btn"
                onClick={() => {
                  dispatch(userGetOleaClaimLogSagaActionCreator());
                  if (balance < claimStandard(tierType) + MIN_OLEA_CLAIM_VALUE)
                    return;
                  setModalOpen(3);
                }}
              >
                출금 신청
              </button>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyWalletGlareCards;
