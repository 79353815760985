export default function checkAfter2023(date) {
  if (!date) return;
  const targetDate = new Date(date);

  if (targetDate.getFullYear() > 2023) {
    return true;
  }

  return false;
}
