import { useEffect } from "react";
import { useDispatch } from "react-redux";

// import mui loading progress
import { CircularProgress } from "@mui/material";
import { userGetDiscordAccountSagaActionCreator } from "../../../redux/modules/user";
import { useState } from "react";
import Modal from "../../modal/Modal";
import { push } from "connected-react-router";

// import middleware

const OAuth2RedirectHandler = ({ history }) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(0);

  const nowUrl = new URL(window.location.href).href;
  const refusedUrl = "denied";
  let code = new URL(window.location.href).searchParams.get("code");

  useEffect(() => {
    if (code) {
      dispatch(userGetDiscordAccountSagaActionCreator({ code, setModalOpen }));
    }
    return;
  }, [code]);

  useEffect(() => {
    if (nowUrl.includes(refusedUrl)) {
      history.replace("/sign-in");
    }
  }, []);

  const modalData = [
    {
      header: "오류",
      size: "sm",
      sort: "center",
      content: (
        <p>
          Athena Returns NFT 디스코드 채널에 가입하지 않은 사용자입니다.
          <br />
          디스코드 채널에 가입하시겠습니까?
        </p>
      ),
      ico: true,
      btnType: "point",
      leftBtn: true,
      btnLeftText: "취소",
      btnRightText: "디스코드 채널 이동",
      ldcBtnHandler: () => {
        setModalOpen(0);
        dispatch(push("/sign-in"));
      },
      rightBtnHandler: () => {
        setModalOpen(0);
        window.open("https://discord.com/invite/uB8vPbTf8p", "__blank");
        dispatch(push("/sign-in"));
      },
    },
  ];

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <div className="loading-wrap">
        <CircularProgress
          style={{
            zIndex: "9999",
            width: "80px",
            height: "80px",
            color: "#e8e7ff",
          }}
        />
      </div>
    </>
  );
};

export default OAuth2RedirectHandler;
