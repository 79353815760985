import "./Purchase.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPurchaseList,
  setAdminState,
} from "../../../../../redux/modules/admin";
import { currentPosts } from "../../../../../utils/paginationCurrentPosts";
import SearchInput from "../SearchInput/SearchInput";
import numberWithCommas from "../../../../../utils/numbersWithComas";
import { dateSet2 } from "../../../../../utils/dateSet";
import MuiPagination from "../../../../muiPagination/MuiPagination";
import Modal from "../../../../modal/Modal";
import { modalData } from "../../modlaData";

const Purchase = () => {
  const dispatch = useDispatch();
  const purchaseList = useSelector((state) => state.admin.purchaseList);
  const [modalOpen, setModalOpen] = useState(0);
  const [deleteTarget, setDeleteTarget] = useState();

  const [renderArr, setRenderArr] = useState(purchaseList || []);
  const [postsPerPage] = useState(10);
  const [postsCurPage, setPostsCurPage] = useState(1);

  useEffect(() => {
    dispatch(getPurchaseList());
  }, [dispatch]);

  const paginationConvert = (arr) => {
    dispatch(setAdminState({ purchaseList: arr }));
  };

  useEffect(() => {
    setRenderArr(currentPosts(purchaseList, postsCurPage, postsPerPage));
  }, [purchaseList, postsCurPage, postsPerPage]);

  useEffect(() => {
    setPostsCurPage(1);
  }, [purchaseList.length]);

  const theadArr = [
    { key: "createdAt", value: "구매일" },
    { key: "email", value: "이메일" },
    { key: "name", value: "상품명" },
    { key: "amount", value: "가격" },
  ];

  const modalPayload = {
    dispatch,
    setModalOpen,

    deleteTarget,
    pageType: "Purchase",
  };
  return (
    <>
      {modalOpen !== 0 && (
        <Modal data={modalData[modalOpen]} modalPayload={modalPayload} />
      )}
      <div className="Purchase">
        <div className="title_area">
          <h2>사용자 구매 내역</h2>

          <SearchInput
            typeList={theadArr}
            origin={purchaseList}
            setOrigin={paginationConvert}
          />
        </div>

        <div className="list_area">
          <table>
            <thead>
              <tr>
                {theadArr.map((th, idx) => (
                  <th key={th.key}>{th.value}</th>
                ))}
                <th></th>
              </tr>
            </thead>

            <tbody>
              {renderArr.map((req, idx) => (
                <tr key={req.createdAt + idx}>
                  <td>{dateSet2(req.createdAt)}</td>

                  <td>{req.email}</td>

                  <td>{req.goods_name}</td>

                  <td>{numberWithCommas(req.price, "POINT")}</td>

                  <td>
                    <div
                      className="deleteBtn"
                      onClick={() => {
                        setModalOpen("DELETE_CHECK");
                        setDeleteTarget(req.id);
                      }}
                    >
                      &times;
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {renderArr && (
            <div className="pagination">
              <MuiPagination
                count={Math.ceil(purchaseList.length / postsPerPage)}
                cur={postsCurPage}
                setCur={setPostsCurPage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Purchase;
