import { useEffect } from "react";
import ReactDOM from "react-dom";

export default function Portal({ children }) {
  useEffect(() => {
    const $layer = document.querySelector(".pop_layer");
    if (!$layer) return;
    const $modal = $layer.querySelector(".in_pop");
    $layer.classList.add("active");
    $modal.classList.add("active");
  }, []);
  const docsRoot = document.querySelector("#docs-root");
  if (!docsRoot) {
    const modalRoot = document.querySelector("#modal-root");
    return ReactDOM.createPortal(children, modalRoot);
  }
  return ReactDOM.createPortal(children, docsRoot);
}
