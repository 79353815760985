import "./EmailAuthenticationSuccess.scss";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import NotFound from "../error/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { patchEmailAuthCheck } from "../../../redux/modules/auth";

const EmailAuthenticationSuccess = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const emailAuthCheckCode = useSelector(
    (state) => state.auth.emailAuthCheckCode
  );

  const queryParams = location.search.slice(1).split("&");
  let email, key;
  queryParams.forEach((param) => {
    const [name, value] = param.split("=");
    if (name === "email") {
      email = value;
    }

    if (name === "key") {
      key = value;
    }
  });

  useEffect(() => {
    dispatch(patchEmailAuthCheck({ email, key }));
  }, []);

  if (emailAuthCheckCode === 200) {
    return (
      <div className="EmailAuthenticationSuccess">
        <h1>이메일 인증 완료</h1>

        <div className="EmailAuthenticationSuccess_area">
          <h2>
            <strong>{email || "test@test.com"}</strong> 님
            <br />
            회원가입을 환영합니다!
          </h2>

          <div className="line" />

          <p>
            이제부터 OLEA MARKET에서
            <br />
            다양한 상품들을 만나보실 수 있습니다.
            <br />
            <br />
            OLEA MARKET의 회원이 되신 것을 진심으로 환영합니다.
          </p>
        </div>

        <Link to="/sign-in">로그인하러 가기</Link>
      </div>
    );
  }

  return <NotFound />;
};

export default EmailAuthenticationSuccess;
