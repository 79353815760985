import { getCookie, removeCookie, setCookie } from "../shared/cookie";

export default class TokenService {
  static save(accessToken, refreshToken, verifyToken) {
    if (accessToken) {
      setCookie("accessToken", accessToken, {
        path: "/",
      });
    }
    if (refreshToken) {
      setCookie("refreshToken", refreshToken, {
        path: "/",
      });
    }
    if (verifyToken) {
      setCookie("verifyToken", verifyToken, {
        path: "/",
      });
    }
  }

  static get() {
    return {
      accessToken: getCookie("accessToken", { path: "/" }),
      refreshToken: getCookie("refreshToken", { path: "/" }),
      verifyToken: getCookie("verifyToken", { path: "/" }),
    };
  }

  static clear() {
    removeCookie("accessToken", { path: "/" });
    removeCookie("refreshToken", { path: "/" });
    removeCookie("verifyToken", { path: "/" });
  }
}

export function getAccessToken() {
  const { accessToken } = TokenService.get();
  return accessToken;
}

export function getRefreshToken() {
  const { refreshToken } = TokenService.get();
  return refreshToken;
}

export function getVerifyToken() {
  const { verifyToken } = TokenService.get();
  return verifyToken;
}
