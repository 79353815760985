import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { balanceList, searchTierList } from "../../../../shared/NftData";

import "./MyWalletSearchSection.scss";

import { userSetStateActionCreator } from "../../../../redux/modules/user";

const MyWalletSearchSection = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState({
    tier: "",
    edition: "",
    balance: "",
  });

  /**debounce 된 search state를 최종적으로 dispatch하는 함수
   */
  const sendQuery = (query) => {
    dispatch(userSetStateActionCreator({ nftSearch: query }));
  };

  /**인자로 받아온 search state를 debounce 를 통해 delay주는 함수
   */
  const delayedQueryCall = useCallback(
    _.debounce((q) => sendQuery(q), 500),
    []
  );

  /**select & input value 변화를 감지하여 setSearch 함수를 통해 search state에 복사하는 함수
   */
  const handleInputChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      setSearch({
        ...search,
        [name]: value,
      });
    },
    [search]
  );

  useEffect(() => {
    delayedQueryCall(search);
  }, [search]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className="my_wallet_search_section_wrapper">
      <div className="my_wallet_search_section_container">
        <form
          className="my_wallet_search_section_form"
          onSubmit={onSubmitHandler}
        >
          <select
            className="my_wallet_search_section_select"
            name="tier"
            onChange={handleInputChange}
          >
            {searchTierList.map((cur, idx) => (
              <option key={idx} value={cur.value}>
                {cur.name}
              </option>
            ))}
          </select>

          <div className="my_wallet_search_section_input_wrap">
            <input
              onChange={handleInputChange}
              name="edition"
              type="text"
              placeholder="NFT를 검색해보세요! / 예: 100 "
            />
          </div>

          <select
            className="my_wallet_search_section_select"
            name="balance"
            onChange={handleInputChange}
          >
            {balanceList.map((cur, idx) => (
              <option key={idx} value={cur.value}>
                {cur.name}
              </option>
            ))}
          </select>
        </form>
      </div>
    </div>
  );
};

export default MyWalletSearchSection;
