// cafe
import img1 from "../assets/brands/img1.png";
import img2 from "../assets/brands/img2.png";
import img3 from "../assets/brands/img3.png";
import img4 from "../assets/brands/img4.png";
import img5 from "../assets/brands/img5.png";
import img6 from "../assets/brands/img6.png";
import img7 from "../assets/brands/img7.png";
import img8 from "../assets/brands/img8.png";
import img9 from "../assets/brands/img9.png";
import img10 from "../assets/brands/img10.png";
import img11 from "../assets/brands/img11.png";
import img12 from "../assets/brands/img12.png";
import img13 from "../assets/brands/img13.png";
import img14 from "../assets/brands/img14.png";
import img15 from "../assets/brands/img15.png";
import img16 from "../assets/brands/img16.png";

// coupon
import img25 from "../assets/brands/img25.png";
import img26 from "../assets/brands/img26.png";
import img27 from "../assets/brands/img27.png";
import img31 from "../assets/brands/img31.png";
import img32 from "../assets/brands/img32.png";
import img33 from "../assets/brands/img33.png";
import img34 from "../assets/brands/img34.png";
import img35 from "../assets/brands/img35.png";
import img36 from "../assets/brands/img36.png";

// food
import img17 from "../assets/brands/img17.png";
import img18 from "../assets/brands/img18.png";
import img19 from "../assets/brands/img19.png";
import img20 from "../assets/brands/img20.png";
import img21 from "../assets/brands/img21.png";
import img22 from "../assets/brands/img22.png";
import img23 from "../assets/brands/img23.png";
import img24 from "../assets/brands/img24.png";
import img28 from "../assets/brands/img28.png";
import img29 from "../assets/brands/img29.png";
import img30 from "../assets/brands/img30.png";
import img37 from "../assets/brands/img37.png";
import img38 from "../assets/brands/img38.png";
import img39 from "../assets/brands/img39.png";
import img40 from "../assets/brands/img40.png";
import img41 from "../assets/brands/img41.png";
import img42 from "../assets/brands/img42.png";

export const HomeBrandCafeArr = [
  { id: 1, src: img1, title: "img1" },
  { id: 2, src: img2, title: "img2" },
  { id: 3, src: img3, title: "img3" },
  { id: 4, src: img4, title: "img4" },
  { id: 5, src: img5, title: "img5" },
  { id: 6, src: img6, title: "img6" },
  { id: 7, src: img7, title: "img7" },
  { id: 8, src: img8, title: "img8" },
  { id: 9, src: img9, title: "img9" },
  { id: 10, src: img10, title: "img10" },
  { id: 11, src: img11, title: "img11" },
  { id: 12, src: img12, title: "img12" },
  { id: 13, src: img13, title: "img13" },
  { id: 14, src: img14, title: "img14" },
  { id: 15, src: img15, title: "img15" },
  { id: 16, src: img16, title: "img16" },
];

export const HomeBrandCouponArr = [
  { id: 25, src: img25, title: "img25" },
  { id: 26, src: img26, title: "img26" },
  { id: 27, src: img27, title: "img27" },
  { id: 31, src: img31, title: "img31" },
  { id: 32, src: img32, title: "img32" },
  { id: 33, src: img33, title: "img33" },
  { id: 34, src: img34, title: "img34" },
  { id: 35, src: img35, title: "img35" },
  { id: 36, src: img36, title: "img36" },
  { id: 25, src: img25, title: "img25" },
  { id: 26, src: img26, title: "img26" },
  { id: 27, src: img27, title: "img27" },
  { id: 31, src: img31, title: "img31" },
  { id: 32, src: img32, title: "img32" },
  { id: 33, src: img33, title: "img33" },
  { id: 34, src: img34, title: "img34" },
  { id: 35, src: img35, title: "img35" },
  { id: 36, src: img36, title: "img36" },
];

export const HomeBrandFoodArr = [
  { id: 17, src: img17, title: "img17" },
  { id: 18, src: img18, title: "img18" },
  { id: 19, src: img19, title: "img19" },
  { id: 20, src: img20, title: "img20" },
  { id: 21, src: img21, title: "img21" },
  { id: 22, src: img22, title: "img22" },
  { id: 23, src: img23, title: "img23" },
  { id: 24, src: img24, title: "img24" },
  { id: 28, src: img28, title: "img28" },
  { id: 29, src: img29, title: "img29" },
  { id: 30, src: img30, title: "img30" },
  { id: 37, src: img37, title: "img37" },
  { id: 38, src: img38, title: "img38" },
  { id: 39, src: img39, title: "img39" },
  { id: 40, src: img40, title: "img40" },
  { id: 41, src: img41, title: "img41" },
  { id: 42, src: img42, title: "img42" },
];
