import axios from "axios";

import { getAccessToken, getRefreshToken } from "./token";

const INQUIRE_URL = `${process.env.REACT_APP_API_URL}/api/mail`;

export default class InquireService {
  static async postSendEmailInquire(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${INQUIRE_URL}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: {
        email: payload.email,
        subject: payload.subject,
        content: payload.content,
      },
    });
    return data;
  }
}
