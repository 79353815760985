import axios from "axios";

import { getAccessToken } from "./token";

const NFT_URI = `${process.env.REACT_APP_API_URL}/api/nfts`;

export default class NftService {
  static async getBalance(payload) {
    const { data } = await axios({
      method: "GET",
      url: `${NFT_URI}/${payload}/balance`,
    });
    return data;
  }

  static async getTradeLog(payload) {
    const { data } = await axios({
      method: "GET",
      url: `${NFT_URI}/${payload}/tradelogs`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async sendCalliopeMintInfo(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${NFT_URI}/mint`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      params: {
        type: payload.type,
      },
      data: payload.data,
    });
    return data;
  }

  static async getMintLog() {
    const { data } = await axios({
      method: "GET",
      url: `${NFT_URI}/mint`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getEachMintLog(payload) {
    const { data } = await axios({
      method: "GET",
      url: `${NFT_URI}/mint/${payload}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }
}
