import { takeEvery, put, call, select, takeLatest } from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import { loadingOff, loadingOn } from "./loding";
import TokenService from "../../service/token";
import AdminService from "../../service/adminService";

const prefix = "olea/admin";

// action type
const actionType = {};

const actionTypeArray = ["START", "SUCCESS", "FAIL", "SET"];

actionTypeArray.forEach((data) => {
  actionType[data] = `${prefix}/${data}`;
});

const { START, SUCCESS, FAIL, SET } = actionType;

export const actionCreator = (type, data) => ({
  type,
  data,
});

// action creator
const start = () => actionCreator(START);
const success = (data) => actionCreator(SUCCESS, data);
const fail = (error) => actionCreator(FAIL, error);
export const setAdminState = (data) => actionCreator(SET, data);

// initial state
const initialState = {
  error: null,
  olea: {
    price: 0,
    points: [],
  },
  pointRequestList: [],
  claimToOLEARequestList: [],
  claimToPointRequestList: [],
  purchaseList: [],
};

// reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START:
      return {
        ...state,
        error: null,
      };
    case SUCCESS:
      return {
        ...state,
        ...action.data,
        error: null,
      };
    case FAIL:
      return {
        ...state,
        error: action.error,
      };

    case SET:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}

// saga action
const sagaActionType = {};

const sagaActionTypeArr = [
  "GET_OLEA_PRICE",
  "GET_POINT_EXCHANGE_REQUEST_LIST",
  "PATCH_EXCHANGE_POINT",
  "PATCH_POINT_EXCHANGE_REQUEST_MEMO",
  "PATCH_CANCEL_STATUS",
  "GET_CLAIM_REQUEST_LIST",
  "PATCH_CLAIM_TO_OLEA_REQUEST_MEMO",
  "PATCH_CLAIM_TO_OLEA_COMPLETE",
  "PATCH_CLAIM_TO_OLEA_CANCEL",
  "PATCH_CLAIM_TO_POINT_REQUEST_MEMO",
  "PATCH_CLAIM_TO_POINT_COMPLETE",
  "PATCH_CLAIM_TO_POINT_CANCEL",
  "GET_PURCHASE_LIST",
  "DELETE_ROW_DATA",
];

sagaActionTypeArr.forEach((data) => {
  sagaActionType[data] = `${prefix}/${data}`;
});

const {
  GET_OLEA_PRICE,
  GET_POINT_EXCHANGE_REQUEST_LIST,
  PATCH_EXCHANGE_POINT,
  PATCH_POINT_EXCHANGE_REQUEST_MEMO,
  PATCH_CANCEL_STATUS,
  GET_CLAIM_REQUEST_LIST,
  PATCH_CLAIM_TO_OLEA_REQUEST_MEMO,
  PATCH_CLAIM_TO_OLEA_COMPLETE,
  PATCH_CLAIM_TO_OLEA_CANCEL,
  PATCH_CLAIM_TO_POINT_REQUEST_MEMO,
  PATCH_CLAIM_TO_POINT_COMPLETE,
  PATCH_CLAIM_TO_POINT_CANCEL,
  GET_PURCHASE_LIST,
  DELETE_ROW_DATA,
} = sagaActionType;

// saga action creator

export const getOLEAPrice = () => actionCreator(GET_OLEA_PRICE);
export const getPointExchangeRequestList = () =>
  actionCreator(GET_POINT_EXCHANGE_REQUEST_LIST);
export const patchExchangePoint = (data) =>
  actionCreator(PATCH_EXCHANGE_POINT, data);

export const patchPointExchangeRequestMemo = (data) =>
  actionCreator(PATCH_POINT_EXCHANGE_REQUEST_MEMO, data);

export const patchCancelStatus = (data) =>
  actionCreator(PATCH_CANCEL_STATUS, data);

export const getClaimRequestList = (data) =>
  actionCreator(GET_CLAIM_REQUEST_LIST, data);

export const patchClaimToOLEARequestMemo = (data) =>
  actionCreator(PATCH_CLAIM_TO_OLEA_REQUEST_MEMO, data);

export const patchClaimToOLEAComplete = (data) =>
  actionCreator(PATCH_CLAIM_TO_OLEA_COMPLETE, data);

export const patchClaimToOLEACancel = (data) =>
  actionCreator(PATCH_CLAIM_TO_OLEA_CANCEL, data);

export const patchClaimToPointRequestMemo = (data) =>
  actionCreator(PATCH_CLAIM_TO_POINT_REQUEST_MEMO, data);

export const patchClaimToPointComplete = (data) =>
  actionCreator(PATCH_CLAIM_TO_POINT_COMPLETE, data);

export const patchClaimToPointCancel = (data) =>
  actionCreator(PATCH_CLAIM_TO_POINT_CANCEL, data);

export const getPurchaseList = () => actionCreator(GET_PURCHASE_LIST);

export const deleteRowData = (data) => actionCreator(DELETE_ROW_DATA, data);

// saga reducer
function* sagaReducer(action) {
  const type = action.type;
  switch (type) {
    // OLEA 시세 조회
    case GET_OLEA_PRICE:
      try {
        const res = yield call(AdminService.getOLEAPrice);
        yield put(
          success({
            olea: {
              price: res.data.currentMarketPrice,
              points: res.data.points,
            },
          })
        );
      } catch (e) {
        const err = { ...e.response.data.error };
        console.log(e);
        console.log(err);
      }
      break;

    // 포인트 교환 요청 리스트 조회
    case GET_POINT_EXCHANGE_REQUEST_LIST:
      try {
        const res = yield call(AdminService.getPointExchangeRequestList);
        yield put(
          success({
            pointRequestList: res.data,
          })
        );
      } catch (e) {
        const err = { ...e.response.data.error };
        console.log(e);
        console.log(err);
      }
      break;

    // 포인트 지급 및 완료 상태로 변경
    case PATCH_EXCHANGE_POINT:
      try {
        const pointRequestListState = yield select(
          (state) => state.admin.pointRequestList
        );

        const res = yield call(AdminService.patchExchangePoint, action.data);

        yield put(
          success({
            pointRequestList: pointRequestListState.map((target) => {
              if (target.id === res.data.processingExchange.id) {
                return {
                  ...res.data.processingExchange,
                  email: res.data.user.email,
                };
              }

              return target;
            }),
          })
        );
      } catch (e) {
        const err = { ...e.response.data.error };
        console.log(e);
        console.log(err);
      }
      break;

    // 포인트 교환 요청 메모 기록
    case PATCH_POINT_EXCHANGE_REQUEST_MEMO:
      try {
        const res = yield call(
          AdminService.patchPointExchangeRequestMemo,
          action.data.type,
          action.data.payload
        );
      } catch (e) {
        const err = { ...e.response.data.error };
        console.log(e);
        console.log(err);
      }
      break;

    // 포인트 교환 요청 취소 상태로 변경
    case PATCH_CANCEL_STATUS:
      try {
        const pointRequestListState = yield select(
          (state) => state.admin.pointRequestList
        );

        const cancelRes = yield call(AdminService.patchCancelStatus, {
          id: action.data.id,
        });

        if (cancelRes.code === 200) {
          if (action.data.status === "완료") {
            const subtractRes = yield call(AdminService.patchSubtractPoint, {
              id: action.data.id,
            });
          }

          yield put(
            success({
              pointRequestList: pointRequestListState.map((target) => {
                if (target.id === cancelRes.data.id) {
                  return cancelRes.data;
                }

                return target;
              }),
            })
          );
        }
      } catch (e) {
        const err = { ...e.response.data.error };
        console.log(e);
        console.log(err);
      }
      break;

    // 클레임 요청 리스트 조회
    case GET_CLAIM_REQUEST_LIST:
      try {
        const res = yield call(AdminService.getClaimRequestList, action.data);

        if (action.data === "OLEA") {
          yield put(
            success({
              claimToOLEARequestList: res.data,
            })
          );
        }

        if (action.data === "OLEA POINT") {
          yield put(
            success({
              claimToPointRequestList: res.data,
            })
          );
        }
      } catch (e) {
        yield put(fail());
      }
      break;

    // 올레아 클레임 요청 메모 기록
    case PATCH_CLAIM_TO_OLEA_REQUEST_MEMO:
      try {
        const res = yield call(
          AdminService.patchClaimToOLEARequestMemo,
          action.data.type,
          action.data.payload
        );
      } catch (e) {
        yield put(fail());

        // const err = { ...e.response.data.error };
        // console.log(e);
        // console.log(err);
      }
      break;

    // 올레아 클레임 요청 완료 상태로 변경
    case PATCH_CLAIM_TO_OLEA_COMPLETE:
      try {
        const claimToOLEARequestListState = yield select(
          (state) => state.admin.claimToOLEARequestList
        );

        const res = yield call(
          AdminService.patchClaimToOLEAComplete,
          action.data
        );

        yield put(
          success({
            claimToOLEARequestList: claimToOLEARequestListState.map(
              (target) => {
                if (
                  target.claimLogsId === res.data.processingClaim.claimLogsId
                ) {
                  return {
                    ...res.data.processingClaim,
                    email: target.email,
                  };
                }

                return target;
              }
            ),
          })
        );
      } catch (e) {
        yield put(fail());

        // const err = { ...e.response.data.error };
        // console.log(e);
        // console.log(err);
      }
      break;

    // 올레아 클레임 요청 취소 상태로 변경
    case PATCH_CLAIM_TO_OLEA_CANCEL:
      try {
        const claimToOLEARequestListState = yield select(
          (state) => state.admin.claimToOLEARequestList
        );

        const cancelRes = yield call(AdminService.patchClaimToOLEACancel, {
          id: action.data.claimLogsId,
        });

        yield put(
          success({
            claimToOLEARequestList: claimToOLEARequestListState.map(
              (target) => {
                if (target.claimLogsId === cancelRes.data.claimLogsId) {
                  return cancelRes.data;
                }

                return target;
              }
            ),
          })
        );
      } catch (e) {
        const err = { ...e.response.data.error };
        console.log(e);
        console.log(err);
      }
      break;

    // 포인트 클레임 요청 메모 기록
    case PATCH_CLAIM_TO_POINT_REQUEST_MEMO:
      try {
        const res = yield call(
          AdminService.patchClaimToPointRequestMemo,
          action.data.type,
          action.data.payload
        );
      } catch (e) {
        yield put(fail());

        // const err = { ...e.response.data.error };
        // console.log(e);
        // console.log(err);
      }
      break;

    // 포인트 클레임 요청 완료 상태로 변경
    case PATCH_CLAIM_TO_POINT_COMPLETE:
      try {
        const claimToPointRequestListState = yield select(
          (state) => state.admin.claimToPointRequestList
        );

        const res = yield call(
          AdminService.patchClaimToPointComplete,
          action.data
        );

        yield put(
          success({
            claimToPointRequestList: claimToPointRequestListState.map(
              (target) => {
                if (
                  target.claimLogsId === res.data.processingClaim.claimLogsId
                ) {
                  return {
                    ...res.data.processingClaim,
                    email: target.email,
                  };
                }

                return target;
              }
            ),
          })
        );
      } catch (e) {
        yield put(fail());

        // const err = { ...e.response.data.error };
        // console.log(e);
        // console.log(err);
      }
      break;

    // 포인트 클레임 요청 취소 상태로 변경
    case PATCH_CLAIM_TO_POINT_CANCEL:
      try {
        const claimToPointRequestListState = yield select(
          (state) => state.admin.claimToPointRequestList
        );

        const cancelRes = yield call(AdminService.patchClaimToPointCancel, {
          id: action.data.claimLogsId,
        });

        yield put(
          success({
            claimToPointRequestList: claimToPointRequestListState.map(
              (target) => {
                if (target.claimLogsId === cancelRes.data.claimLogsId) {
                  return cancelRes.data;
                }

                return target;
              }
            ),
          })
        );
      } catch (e) {
        const err = { ...e.response.data.error };
        console.log(e);
        console.log(err);
      }
      break;

    // 사용자 물품 구매 내역
    case GET_PURCHASE_LIST:
      try {
        const res = yield call(AdminService.getPurchaseList);
        yield put(
          success({
            purchaseList: res.data,
          })
        );
      } catch (e) {
        const err = { ...e.response.data.error };
        console.log(e);
        console.log(err);
      }
      break;

    // 리스트 row 데이터 삭제
    case DELETE_ROW_DATA:
      try {
        const adminState = yield select((state) => state.admin);

        switch (action.data.pageType) {
          case "Point":
            yield call(
              AdminService.deleteRowData,
              `api/point/exchange/${action.data.deleteTarget}`
            );

            yield put(
              success({
                pointRequestList: adminState.pointRequestList.filter(
                  (data) => data.id !== action.data.deleteTarget
                ),
              })
            );
            break;

          case "ClaimToOLEA":
            yield call(
              AdminService.deleteRowData,
              `api/claimLogs/${action.data.deleteTarget}/admin`
            );

            yield put(
              success({
                claimToOLEARequestList:
                  adminState.claimToOLEARequestList.filter(
                    (data) => data.claimLogsId !== action.data.deleteTarget
                  ),
              })
            );
            break;

          case "ClaimToPoint":
            yield call(
              AdminService.deleteRowData,
              `api/claimLogs/point/${action.data.deleteTarget}/admin `
            );

            yield put(
              success({
                claimToPointRequestList:
                  adminState.claimToPointRequestList.filter(
                    (data) => data.claimLogsId !== action.data.deleteTarget
                  ),
              })
            );
            break;

          case "Purchase":
            yield call(
              AdminService.deleteRowData,
              `api/point/admin/purchase/${action.data.deleteTarget}`
            );

            yield put(
              success({
                purchaseList: adminState.purchaseList.filter(
                  (data) => data.id !== action.data.deleteTarget
                ),
              })
            );
            break;

          default:
            break;
        }
      } catch (e) {
        console.log(e);
      }
      break;

    default:
      break;
  }
}

// export saga
export function* adminSaga() {
  yield takeEvery(GET_OLEA_PRICE, sagaReducer);
  yield takeEvery(GET_POINT_EXCHANGE_REQUEST_LIST, sagaReducer);
  yield takeEvery(PATCH_EXCHANGE_POINT, sagaReducer);
  yield takeEvery(PATCH_POINT_EXCHANGE_REQUEST_MEMO, sagaReducer);
  yield takeEvery(PATCH_CANCEL_STATUS, sagaReducer);
  yield takeEvery(GET_CLAIM_REQUEST_LIST, sagaReducer);
  yield takeEvery(PATCH_CLAIM_TO_OLEA_REQUEST_MEMO, sagaReducer);
  yield takeEvery(PATCH_CLAIM_TO_OLEA_COMPLETE, sagaReducer);
  yield takeEvery(PATCH_CLAIM_TO_OLEA_CANCEL, sagaReducer);
  yield takeEvery(PATCH_CLAIM_TO_POINT_REQUEST_MEMO, sagaReducer);
  yield takeEvery(PATCH_CLAIM_TO_POINT_COMPLETE, sagaReducer);
  yield takeEvery(PATCH_CLAIM_TO_POINT_CANCEL, sagaReducer);
  yield takeEvery(GET_PURCHASE_LIST, sagaReducer);
  yield takeEvery(DELETE_ROW_DATA, sagaReducer);
}
