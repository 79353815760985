import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";

import { marketSetStateSagaActionCreator } from "../../../../../redux/modules/market";

import "../../SortContainer.scss";

const SortContainer = ({ states }) => {
  const dispatch = useDispatch();
  const tabValue = String(states.value);
  const brandsData = useSelector(
    (state) => state.market.tokenMarket?.brandsData
  );
  const [brandTarget, setBrandTarget] = useState("전체보기");
  const [priceTarget, setPriceTarget] = useState("낮은 순");

  const handleBrandFilter = (event) => {
    setBrandTarget(event.target.value);
  };
  const handlePriceSort = (event) => {
    setPriceTarget(event.target.value);
  };

  useEffect(() => {
    const payload = {
      marketSearchValue: {
        tabValue: tabValue,
        brandValue: brandTarget,
        priceValue: priceTarget,
      },
    };
    dispatch(marketSetStateSagaActionCreator(payload));
  }, [brandsData, brandTarget, priceTarget, tabValue]);

  return (
    <div className="sort_container_wrapper">
      <div className="sort_container_input_group">
        <Box
          sx={{
            marginRight: "2rem",
          }}
        >
          <FormControl sx={{ fontSize: "2.4rem" }}>
            <InputLabel
              id="simple-select-label"
              sx={{ fontSize: 15, fontWeight: 500, color: "#000" }}
            >
              브랜드
            </InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={brandTarget}
              label="브랜드"
              onChange={handleBrandFilter}
              sx={{
                fontSize: 15,
                fontWeight: 500,
                color: "#000",
                height: "35px",
              }}
              autoWidth
            >
              <MenuItem
                value={"전체보기"}
                sx={{ fontSize: 15, fontWeight: 500, color: "#000" }}
              >
                전체보기
              </MenuItem>
              {brandsData &&
                brandsData.map((data) => (
                  <MenuItem
                    key={data}
                    value={data}
                    sx={{ fontSize: 15, fontWeight: 500, color: "#000" }}
                  >
                    {data}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ marginRight: "2rem" }}>
          <FormControl sx={{ fontSize: "2.4rem" }}>
            <InputLabel
              id="simple-select-label"
              sx={{ fontSize: 15, fontWeight: 500, color: "#000" }}
            >
              가격순
            </InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={priceTarget}
              label="가격순"
              onChange={handlePriceSort}
              sx={{
                fontSize: 15,
                fontWeight: 500,
                color: "#000",
                height: "35px",
              }}
              autoWidth
            >
              <MenuItem
                value={"낮은 순"}
                sx={{ fontSize: 15, fontWeight: 500, color: "#000" }}
              >
                낮은 순
              </MenuItem>
              <MenuItem
                value={"높은 순"}
                sx={{ fontSize: 15, fontWeight: 500, color: "#000" }}
              >
                높은 순
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
    </div>
  );
};

export default SortContainer;
