import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PRODUCTINPREPARATION from "../../../../../assets/images/ProductInPreparation.svg";
import MarketRender from "./MarketRender";

import "../../MarketItemContainer.scss";
import MuiPagination from "../../../../muiPagination/MuiPagination";

const MarketItemContainer = ({ states }) => {
  const {
    open,
    handlePopoverOpen,
    handlePopoverClose,
    enqueueSnackbar,
    myShoppingCart,
    setMyShoppingCart,
  } = states;
  // const marketData = useSelector(
  //   (state) => state.market.showClubMarket.marketData
  // );
  const marketData = useSelector(
    (state) => state.market.tokenMarket.marketData
  );
  const tabValue = useSelector(
    (state) => state.market.marketSearchValue?.tabValue
  );
  const brandValue = useSelector(
    (state) => state.market.marketSearchValue?.brandValue
  );
  const priceValue = useSelector(
    (state) => state.market.marketSearchValue?.priceValue
  );

  const [renderArr, setRenderArr] = useState(marketData);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const [postsCurPage, setPostsCurPage] = useState(1);

  function currentPosts(tmp, curPage, postsPerPage) {
    const last = curPage * postsPerPage;
    const first = last - postsPerPage;
    let cur = 0;
    if (tmp) {
      cur = tmp.slice(first, last);
    }
    return cur;
  }

  const tabFilter = (tabValue, marketData) => {
    if (!marketData) return;
    if (tabValue === "0") {
      return marketData;
    } else {
      return marketData.filter((cur) => cur.category === tabValue);
    }
  };

  const brandFilter = (tabValue, brandValue, priceValue, marketData) => {
    const tabFilterReturn = tabFilter(tabValue, marketData);

    let brandFilterReturn;

    if (brandValue === "전체보기") {
      brandFilterReturn = tabFilterReturn;
    } else {
      brandFilterReturn = tabFilterReturn.filter(
        (cur) => cur.goodsBrand === brandValue
      );
    }

    const result = sortArr(priceValue, brandFilterReturn);
    setPostsCurPage(1);
    setRenderArr(result);
  };

  const sortArr = (priceValue, brandFilterReturn) => {
    const brandSorting = brandFilterReturn.map((cur) => cur);
    switch (priceValue) {
      case "높은 순":
        return brandSorting.sort(
          (a, b) => b.goodsPointPrice - a.goodsPointPrice
        );
      case "낮은 순":
        return brandSorting.sort(
          (a, b) => a.goodsPointPrice - b.goodsPointPrice
        );
      default:
        return brandSorting;
    }
  };

  useEffect(() => {
    if (!tabValue || !brandValue || !priceValue) return;
    brandFilter(tabValue, brandValue, priceValue, marketData);
  }, [tabValue, brandValue, priceValue, marketData]);

  useEffect(() => {
    setPostsCurPage(1);
    setRenderArr(marketData);
  }, [marketData]);

  return (
    <>
      <div className="market_item_container_wrapper">
        {renderArr && renderArr.length === 0 ? (
          <div className="product_preparation_box">
            <img src={PRODUCTINPREPARATION} alt="" />
          </div>
        ) : (
          <MarketRender
            renderArr={currentPosts(renderArr, postsCurPage, postsPerPage)}
            states={{
              open,
              handlePopoverOpen,
              handlePopoverClose,
              enqueueSnackbar,
              myShoppingCart,
              setMyShoppingCart,
            }}
          />
        )}
      </div>
      {renderArr && (
        <MuiPagination
          count={Math.ceil(renderArr.length / postsPerPage)}
          cur={postsCurPage}
          setCur={setPostsCurPage}
        />
      )}
    </>
  );
};

export default MarketItemContainer;
