import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dateSet, { getTime } from "../../../../../utils/dateSet";
import {
  marketGetOLEAPointInfoSagaActionCreator,
  marketPatchExchangeCancelSagaActionCreator,
} from "../../../../../redux/modules/market";

export default function OleaPointProgress({ setModalOpen }) {
  const dispatch = useDispatch();
  const OLEA_Point_Info = useSelector((state) => state.market.OLEA_Point_Info);

  useEffect(() => {
    dispatch(marketGetOLEAPointInfoSagaActionCreator());
  }, [dispatch]);

  return (
    <div>
      <div className="market_token_modal">
        {!OLEA_Point_Info?.exchangeInProgress && (
          <p>관리자가 충전 정보를 확인했습니다.</p>
        )}
        {OLEA_Point_Info.exchangeInProgress && (
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">진행 상태</TableCell>
                  <TableCell align="center">신청 POINT</TableCell>
                  <TableCell align="center">전송 OLEA COIN</TableCell>
                  <TableCell align="center">트랜잭션 해시</TableCell>
                  <TableCell align="center">신청일</TableCell>
                  {OLEA_Point_Info?.exchangeInProgress?.status === "신청" && (
                    <TableCell align="center">충전 취소</TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    {OLEA_Point_Info?.exchangeInProgress?.status}
                  </TableCell>
                  <TableCell align="center">
                    {OLEA_Point_Info?.exchangeInProgress?.point.toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    {OLEA_Point_Info?.exchangeInProgress?.oleaForExchange.toLocaleString()}
                  </TableCell>
                  <TableCell align="center">
                    {OLEA_Point_Info?.exchangeInProgress?.hashValue || (
                      <button
                        className="point_btn"
                        onClick={() => setModalOpen("TXHASH_SEND")}
                      >
                        트랜잭션 해시 입력
                      </button>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {dateSet(OLEA_Point_Info?.exchangeInProgress?.createdAt)}
                    {"("}
                    {getTime(OLEA_Point_Info?.exchangeInProgress?.createdAt)}
                    {")"}
                  </TableCell>
                  {OLEA_Point_Info?.exchangeInProgress?.status === "신청" && (
                    <TableCell align="center">
                      <button
                        className="point_btn"
                        onClick={() => {
                          setModalOpen("POINT_CANCEL");
                        }}
                      >
                        충전 취소
                      </button>
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
}
