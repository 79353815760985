import axios from "axios";

import { getAccessToken, getRefreshToken } from "./token";

const GAME_URL = `${process.env.REACT_APP_API_URL}/api/games`;

export default class GameService {
  static async runGame(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${GAME_URL}/${payload}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      params: { coin: payload },
    });
    return data;
  }

  static async getGame(payload) {
    const { data } = await axios({
      method: "GET",
      url: `${GAME_URL}/${payload}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      params: { coin: payload },
    });
    return data;
  }

  static async postNickname(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${GAME_URL}/agreement`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: { nickname: payload },
    });
    return data;
  }
}
