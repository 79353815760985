import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NFTTypeConvert } from "../../../../../shared/NftData";

import ROULETTE_BTN from "../../../../../assets/images/roulette_button.png";
import { userPostBuyGameMoneySagaActionCreator } from "../../../../../redux/modules/user";

const HolderBuyGameMoneyComponent = ({ states }) => {
  const { setModalOpen, gameMoneyPrice } = states;
  const dispatch = useDispatch();

  const userNftArr = useSelector((state) => state.user.userMetaMaskInfo);
  const nowDate = useSelector((state) => state.user.date);

  const date = new Date(nowDate.year, nowDate.month, nowDate.day);
  const day = date.getDate();
  const lastDay = day.toString().slice(-1);

  const [availableArr, setAvailableArr] = useState([]);
  const [count, setCount] = useState(1);
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    setAvailableArr(
      userNftArr.filter((data) => data.edition.toString().slice(-1) === lastDay)
    );
    setSelectedValue(
      userNftArr.filter(
        (data) => data.edition.toString().slice(-1) === lastDay
      )[0].nftId
    );
  }, [userNftArr]);

  const handleChage = (e) => {
    setSelectedValue(Number(e.target.value));
  };

  // const handleCountChange = (e) => {
  //   const inputCount = parseInt(e.target.value);
  //   if (isNaN(inputCount)) {
  //     setCount(1);
  //     return;
  //   }
  //   if (inputCount > 0) {
  //     if (
  //       inputCount >
  //       availableArr.filter((data) => data.nftId === selectedValue)[0]?.balance
  //     ) {
  //       return;
  //     } else {
  //       setCount(inputCount);
  //     }
  //   }
  // };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const increment = () => {
    const comparison = Math.floor(
      userNftArr.filter((data) => selectedValue === data.nftId)[0].balance /
        gameMoneyPrice
    );
    if (count < comparison) {
      setCount(count + 1);
    }
  };

  // 구매버튼 클릭시 실행
  const clickBuyBtnHandler = useCallback(() => {
    const payload = {
      pageType: "nft",
      nftId: selectedValue,
      gameMoneyCount: count,
    };
    const compare = availableArr.filter((data) => data.nftId === selectedValue);
    if (compare[0].balance < count) {
      setModalOpen(3);
    } else {
      dispatch(
        userPostBuyGameMoneySagaActionCreator({
          payload,
          setModalOpen,
          pageType: "holder",
        })
      );
      setModalOpen(0);
    }
  }, [selectedValue, count]);

  return (
    <>
      <div className="buy_ticket_component">
        <div>
          <img src={ROULETTE_BTN} alt="ticket" style={{ width: "70px" }} />
          <span>
            <button onClick={decrement}>-</button>
            {count * 100}게임머니<button onClick={increment}>+</button>
          </span>
        </div>
        <div>
          <span>구매수단 선택</span>
          <select value={selectedValue} onChange={handleChage}>
            {availableArr.map((data) => (
              <option key={data.nftId} value={data.nftId}>
                {`ATENA ${NFTTypeConvert(data.tierType)} ${data.edition} / ${
                  data.balance
                } OLEA`}
              </option>
            ))}
          </select>
        </div>

        <button onClick={clickBuyBtnHandler}>구매</button>
      </div>
    </>
  );
};

export default HolderBuyGameMoneyComponent;
