export function timeSet(str) {
  if (!str) return;
  if (str < 10) {
    return `0${str}`;
  } else {
    return str;
  }
}

export default function dateSet(str) {
  if (!str) return;
  const date = new Date(str);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const result = `${timeSet(y)}-${timeSet(m)}-${timeSet(d)}`;
  return result;
}

export function dateSet2(str) {
  if (!str) return;
  const date = new Date(str);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const result = `${`${timeSet(y)}`.slice(2)}.${timeSet(m)}.${timeSet(d)}`;
  return result;
}

export function timeAdd(str) {
  if (!str) return;
  const splited = str.split("T")[1].split(":")[0];
  const date = new Date(str);

  date.setHours(date.getHours() + 72);

  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const result = `${timeSet(y)}년 ${timeSet(m)}월 ${timeSet(d)}일 ${splited}시`;

  return result;
}

/** ISO date 객체에 더하고싶은 일 수를 Number type 매개변수로 넣는다.
 *
 * addDays(somdDate, 100)
 */
export function addDays(date, days) {
  // if (!date || !days) return;

  const value = new Date(date);
  value.setDate(value.getDate() + days);

  const y = value.getFullYear();
  const m = value.getMonth() + 1;
  const d = value.getDate();
  const result = `${timeSet(y)}-${timeSet(m)}-${timeSet(d)}`;
  return result;
}

export function dateTextSet(str) {
  if (!str) return;
  const splited = str.split("T")[1].split(":")[0];
  const date = new Date(str);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const result = `${timeSet(y)}년 ${timeSet(m)}월 ${timeSet(d)}일 ${splited}시`;
  return result;
}

export function dateTextWithoutTimeSet(str) {
  if (!str) return;
  const date = new Date(str);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const result = `${timeSet(y)}년 ${timeSet(m)}월 ${timeSet(d)}일`;
  return result;
}

export function timeStampDateSet(str) {
  if (!str) return;

  const y = str.substr(0, 4);
  const m = str.substr(5, 2);
  const d = str.substr(8, 2);
  return `${y}-${m}-${d}`;
}

export function timeStampDateSet2(str, time) {
  if (!str) return;

  const y = str.substr(0, 4);
  const m = str.substr(5, 2);
  const d = str.substr(8, 2);
  const t = str.substr(11, 8);
  if (time) {
    return `${y}-${m}-${d} ${t}`;
  } else {
    return `${y}-${m}-${d}`;
  }
}

export function timeStringSet(str) {
  if (str < 10) {
    return `0${str}`;
  } else {
    return str;
  }
}

export function defaultDate(dateForm = "/") {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  return `${y}${dateForm}${timeStringSet(m)}${dateForm}${timeStringSet(d)}`;
}

export function timeDifference(start, cur) {
  const setDate = new Date(start);
  setDate.setHours(setDate.getHours() + 72);

  const now = new Date(cur);

  const distance = setDate.getTime() - now.getTime();
  const day = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return {
    hours: day === 1 ? hours + 24 : day === 2 ? hours + 48 : hours,
    minutes: minutes,
  };
  // `
  // ${day === 1 ? hours + 24 : day === 2 ? hours + 48 : hours}시간
  // ${minutes < 10 ? `0${minutes}` : minutes}분`;
  // ${day}일
  // ${seconds < 10 ? `0${seconds}` : seconds}초 남았습니다.
}

export const getWeek = (date) => {
  const currentDate = date.getDate();
  const firstDay = new Date(date.setDate(1)).getDay();

  return Math.ceil((currentDate + firstDay) / 7);
};

export function formatAMPM(str) {
  const date = new Date(`2023-01-11T${str}`);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = ampm + " " + hours + ":" + minutes;
  return strTime;
}

export function dateSetWithoutDate(str) {
  if (!str) return;
  const date = new Date(str);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const result = `${timeSet(y)}년 ${timeSet(m)}월`;
  return result;
}

export function dateSetWithoutDate2(str) {
  if (!str) return;
  const date = new Date(str);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const result = `${timeSet(y)}-${timeSet(m)}`;
  return result;
}

export function getTime(str) {
  const date = new Date(str);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "오후" : "오전";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = ampm + " " + hours + ":" + minutes;
  return strTime;
}
