import LOGOICON from "../../../assets/icons/LOGO.svg";
import "./MobileInfo.scss";

const MobileInfo = () => {
  return (
    <div className="mobile_info_wrapper">
      <img src={LOGOICON} alt="LOGOICON" />
      <span>모바일 서비스는 준비중입니다.</span>
      <span>PC 환경에서 접속해주세요.</span>
    </div>
  );
};

export default MobileInfo;
