import {
  deleteRowData,
  patchClaimToOLEARequestMemo,
  patchClaimToPointRequestMemo,
  patchPointExchangeRequestMemo,
} from "../../../redux/modules/admin";

export const modalData = {
  POINT_MEMO: {
    header: "메모",
    size: "sm",
    ico: false,
    sort: "center",
    content: ({ modalPayload }) => (
      <div className="memo_wrapper">
        <div className="memo_tabs">
          <div
            className={`memo_tab ${
              modalPayload.memoType === "notice" && "isActive"
            }`}
            onClick={() => {
              if (modalPayload.isMemoEdit) {
                return alert("저장 후 이동해 주세요.");
              }
              modalPayload.setMemoType("notice");
            }}
          >
            사용자 메모
          </div>

          <div
            className={`memo_tab ${
              modalPayload.memoType === "manager" && "isActive"
            }`}
            onClick={() => {
              if (modalPayload.isMemoEdit) {
                return alert("저장 후 이동해 주세요.");
              }
              modalPayload.setMemoType("manager");
            }}
          >
            관리자 메모
          </div>
        </div>

        {modalPayload.memoType === "notice" ? (
          <>
            <div className="memo_warming">
              * 사용자도 확인 가능한 메모입니다.
            </div>

            {modalPayload.isMemoEdit ? (
              <textarea
                className="memo_textarea"
                defaultValue={modalPayload.clickedReq.memoForNotice}
                placeholder="메모를 입력해 주세요."
                onChange={(e) => {
                  modalPayload.setClickedReq({
                    ...modalPayload.clickedReq,
                    memoForNotice: e.target.value,
                  });
                }}
              />
            ) : (
              <div className="memo_div">
                {modalPayload.clickedReq.memoForNotice}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="memo_warming">
              * 관리자만 확인 가능한 메모입니다.
            </div>

            {modalPayload.isMemoEdit ? (
              <textarea
                className="memo_textarea"
                defaultValue={modalPayload.clickedReq.memoForManager}
                placeholder="메모를 입력해 주세요."
                onChange={(e) => {
                  modalPayload.setClickedReq({
                    ...modalPayload.clickedReq,
                    memoForManager: e.target.value,
                  });
                }}
              />
            ) : (
              <div className="memo_div">
                {modalPayload.clickedReq.memoForManager}
              </div>
            )}
          </>
        )}
      </div>
    ),
    btnType: "default",
    leftBtn: true,
    btnLeftText: "닫기",
    btnRightText: ({ modalPayload }) =>
      modalPayload.isMemoEdit ? "저장" : "편집",
    ldcBtnHandler: ({ modalPayload }) => {
      if (modalPayload.isMemoEdit)
        modalPayload.editedMemoHandler(modalPayload.clickedReqOrigin);

      modalPayload.setIsMemoEdit(false);
      modalPayload.setModalOpen(0);
    },
    rightBtnHandler: ({ modalPayload }) => {
      if (modalPayload.isMemoEdit) {
        modalPayload.editedMemoHandler(modalPayload.clickedReq);

        modalPayload.dispatch(
          patchPointExchangeRequestMemo({
            type: modalPayload.memoType,
            payload: {
              id: modalPayload.clickedReq.id,
              [modalPayload.memoType === "notice"
                ? "memoForNotice"
                : "memoForManager"]:
                modalPayload.clickedReq[
                  modalPayload.memoType === "notice"
                    ? "memoForNotice"
                    : "memoForManager"
                ],
            },
          })
        );

        modalPayload.setIsMemoEdit(false);

        return;
      }

      modalPayload.setIsMemoEdit(true);
    },
  },

  CLAIM_OLEA_MEMO: {
    header: "메모",
    size: "sm",
    ico: false,
    sort: "center",
    content: ({ modalPayload }) => (
      <div className="memo_wrapper">
        <div className="memo_tabs">
          <div
            className={`memo_tab ${
              modalPayload.memoType === "notice" && "isActive"
            }`}
            onClick={() => {
              if (modalPayload.isMemoEdit) {
                return alert("저장 후 이동해 주세요.");
              }
              modalPayload.setMemoType("notice");
            }}
          >
            사용자 메모
          </div>

          <div
            className={`memo_tab ${
              modalPayload.memoType === "manager" && "isActive"
            }`}
            onClick={() => {
              if (modalPayload.isMemoEdit) {
                return alert("저장 후 이동해 주세요.");
              }
              modalPayload.setMemoType("manager");
            }}
          >
            관리자 메모
          </div>
        </div>

        {modalPayload.memoType === "notice" ? (
          <>
            <div className="memo_warming">
              * 사용자도 확인 가능한 메모입니다.
            </div>

            {modalPayload.isMemoEdit ? (
              <textarea
                className="memo_textarea"
                defaultValue={modalPayload.clickedReq.memoForNotice}
                placeholder="메모를 입력해 주세요."
                onChange={(e) => {
                  modalPayload.setClickedReq({
                    ...modalPayload.clickedReq,
                    memoForNotice: e.target.value,
                  });
                }}
              />
            ) : (
              <div className="memo_div">
                {modalPayload.clickedReq.memoForNotice}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="memo_warming">
              * 관리자만 확인 가능한 메모입니다.
            </div>

            {modalPayload.isMemoEdit ? (
              <textarea
                className="memo_textarea"
                defaultValue={modalPayload.clickedReq.memoForManager}
                placeholder="메모를 입력해 주세요."
                onChange={(e) => {
                  modalPayload.setClickedReq({
                    ...modalPayload.clickedReq,
                    memoForManager: e.target.value,
                  });
                }}
              />
            ) : (
              <div className="memo_div">
                {modalPayload.clickedReq.memoForManager}
              </div>
            )}
          </>
        )}
      </div>
    ),
    btnType: "default",
    leftBtn: true,
    btnLeftText: "닫기",
    btnRightText: ({ modalPayload }) =>
      modalPayload.isMemoEdit ? "저장" : "편집",
    ldcBtnHandler: ({ modalPayload }) => {
      if (modalPayload.isMemoEdit)
        modalPayload.editedMemoHandler(modalPayload.clickedReqOrigin);

      modalPayload.setIsMemoEdit(false);
      modalPayload.setModalOpen(0);
    },
    rightBtnHandler: ({ modalPayload }) => {
      if (modalPayload.isMemoEdit) {
        modalPayload.editedMemoHandler(modalPayload.clickedReq);

        modalPayload.dispatch(
          patchClaimToOLEARequestMemo({
            type: modalPayload.memoType,
            payload: {
              id: modalPayload.clickedReq.claimLogsId,
              [modalPayload.memoType === "notice"
                ? "memoForNotice"
                : "memoForManager"]:
                modalPayload.clickedReq[
                  modalPayload.memoType === "notice"
                    ? "memoForNotice"
                    : "memoForManager"
                ],
            },
          })
        );

        modalPayload.setIsMemoEdit(false);

        return;
      }

      modalPayload.setIsMemoEdit(true);
    },
  },

  CLAIM_POINT_MEMO: {
    header: "메모",
    size: "sm",
    ico: false,
    sort: "center",
    content: ({ modalPayload }) => (
      <div className="memo_wrapper">
        <div className="memo_tabs">
          <div
            className={`memo_tab ${
              modalPayload.memoType === "notice" && "isActive"
            }`}
            onClick={() => {
              if (modalPayload.isMemoEdit) {
                return alert("저장 후 이동해 주세요.");
              }
              modalPayload.setMemoType("notice");
            }}
          >
            사용자 메모
          </div>

          <div
            className={`memo_tab ${
              modalPayload.memoType === "manager" && "isActive"
            }`}
            onClick={() => {
              if (modalPayload.isMemoEdit) {
                return alert("저장 후 이동해 주세요.");
              }
              modalPayload.setMemoType("manager");
            }}
          >
            관리자 메모
          </div>
        </div>

        {modalPayload.memoType === "notice" ? (
          <>
            <div className="memo_warming">
              * 사용자도 확인 가능한 메모입니다.
            </div>

            {modalPayload.isMemoEdit ? (
              <textarea
                className="memo_textarea"
                defaultValue={modalPayload.clickedReq.memoForNotice}
                placeholder="메모를 입력해 주세요."
                onChange={(e) => {
                  modalPayload.setClickedReq({
                    ...modalPayload.clickedReq,
                    memoForNotice: e.target.value,
                  });
                }}
              />
            ) : (
              <div className="memo_div">
                {modalPayload.clickedReq.memoForNotice}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="memo_warming">
              * 관리자만 확인 가능한 메모입니다.
            </div>

            {modalPayload.isMemoEdit ? (
              <textarea
                className="memo_textarea"
                defaultValue={modalPayload.clickedReq.memoForManager}
                placeholder="메모를 입력해 주세요."
                onChange={(e) => {
                  modalPayload.setClickedReq({
                    ...modalPayload.clickedReq,
                    memoForManager: e.target.value,
                  });
                }}
              />
            ) : (
              <div className="memo_div">
                {modalPayload.clickedReq.memoForManager}
              </div>
            )}
          </>
        )}
      </div>
    ),
    btnType: "default",
    leftBtn: true,
    btnLeftText: "닫기",
    btnRightText: ({ modalPayload }) =>
      modalPayload.isMemoEdit ? "저장" : "편집",
    ldcBtnHandler: ({ modalPayload }) => {
      if (modalPayload.isMemoEdit)
        modalPayload.editedMemoHandler(modalPayload.clickedReqOrigin);

      modalPayload.setIsMemoEdit(false);
      modalPayload.setModalOpen(0);
    },
    rightBtnHandler: ({ modalPayload }) => {
      if (modalPayload.isMemoEdit) {
        modalPayload.editedMemoHandler(modalPayload.clickedReq);

        modalPayload.dispatch(
          patchClaimToPointRequestMemo({
            type: modalPayload.memoType,
            payload: {
              id: modalPayload.clickedReq.claimLogsId,
              [modalPayload.memoType === "notice"
                ? "memoForNotice"
                : "memoForManager"]:
                modalPayload.clickedReq[
                  modalPayload.memoType === "notice"
                    ? "memoForNotice"
                    : "memoForManager"
                ],
            },
          })
        );

        modalPayload.setIsMemoEdit(false);

        return;
      }

      modalPayload.setIsMemoEdit(true);
    },
  },

  REJECTION_REASON: {
    header: "반려 사유",
    size: "sm",
    ico: false,
    sort: "center",
    content: ({ modalPayload }) => (
      <>
        <div className="memo_div">{modalPayload.clickedReq.memoForNotice}</div>
      </>
    ),
    btnType: "point",
    leftBtn: false,
    btnRightText: "닫기",
    rdcBtnHandler: ({ modalPayload }) => {
      modalPayload.setModalOpen(0);
    },
  },

  REJECTION: {
    header: "반려",
    size: "sm",
    ico: false,
    sort: "center",
    content: ({ modalPayload }) => (
      <>
        <div className="memo_div">{modalPayload.clickedReq.memoForNotice}</div>
      </>
    ),
    btnType: "point",
    leftBtn: false,
    btnRightText: "닫기",
    rdcBtnHandler: ({ modalPayload }) => {
      modalPayload.setModalOpen(0);
    },
  },

  DELETE_CHECK: {
    header: "데이터 삭제 확인",
    size: "sm",
    ico: true,
    sort: "center",
    content: <div>해당 데이터를 삭제하시겠습니까?</div>,
    btnType: "point",
    leftBtn: true,
    btnLeftText: "취소",
    btnRightText: "삭제",
    ldcBtnHandler: ({ modalPayload }) => {
      modalPayload.setModalOpen(0);
    },
    rightBtnHandler: ({ modalPayload }) => {
      console.log(modalPayload.deleteTarget);

      modalPayload.dispatch(
        deleteRowData({
          deleteTarget: modalPayload.deleteTarget,
          pageType: modalPayload.pageType,
        })
      );
      modalPayload.setModalOpen(0);
    },
  },
};
