import * as Yup from "yup";

export default Yup.object().shape({
  subject: Yup.string()
    .max(100, "제목은 최대 100자리 입니다!")
    .required("제목을 입력해주세요."),
  content: Yup.string()
    .max(1000, "문의내용은 최대 1,000자까지 입력할 수 있습니다.")
    .required("문의내용을 입력해주세요."),
});
