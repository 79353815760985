import React from "react";

import { Popover, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useSnackbar } from "notistack";

import {
  getTokenMarketGoodsList,
  marketGetShowClubSagaActionCreator,
  // marketGetShowMarketSagaActionCreator,
} from "../../../../../redux/modules/market";

import "../../MarketTemplate.scss";
import MarketLeftContainer from "./MarketLeftContainer";
import MarketRightContainer from "./MarketRightContainer";
import MarketTab from "./MarketTab";
import MarketItemContainer from "./MarketItemContainer";
import Modal from "../../../../modal/Modal";

const MarketTemplate = ({ count }) => {
  const dispatch = useDispatch();
  const marketData = useSelector(
    (state) => state.market.tokenMarket?.marketData
  ).sort((a, b) => a.goodsPointPrice - b.goodsPointPrice);
  const OLEA_Point_Info = useSelector((state) => state.market.OLEA_Point_Info);
  // const oleaPrice = useSelector(
  //   (state) => state.market.showClubMarket?.oleaPrice
  // );
  // const userRestAmount = useSelector((state) => state.market.userRestAmount);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorText, setAnchorText] = useState("");
  const [myShoppingCart, setMyShoppingCart] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalCheck, setTotalCheck] = useState(false);
  const [minusPointModal, setMinusPointModal] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (myShoppingCart.length === 0 || !myShoppingCart) {
      setTotalCheck(false);
      return;
    }

    // setTotalCheck(totalPrice >= Math.floor(userRestAmount / oleaPrice));

    setTotalCheck(totalPrice > OLEA_Point_Info.point / 10);
  }, [totalPrice, myShoppingCart, OLEA_Point_Info]);

  useEffect(() => {
    // dispatch(userGetInfoSagaActionCreator()); PP 조회
    // dispatch(marketGetShowMarketSagaActionCreator());
    dispatch(marketGetShowClubSagaActionCreator());
    dispatch(getTokenMarketGoodsList({ type: "전체조회" }));
  }, []);

  useEffect(() => {
    //   let sum = 0;
    //   if (myShoppingCart.length > 0) {
    //     myShoppingCart.forEach((data) => {
    //       sum += +data.goodsCoinPrice;
    //     });
    //   }
    //   setTotalPrice(sum);

    if (myShoppingCart.length === 0) setTotalPrice(0);

    setTotalPrice(
      myShoppingCart.reduce(
        (acc, selectedItem) => acc + selectedItem.goodsPointPrice,
        0
      )
    );
  }, [myShoppingCart]);

  useEffect(() => {
    if (!marketData) return;
    const result = myShoppingCart.map((data) => ({
      ...data,
      goodsCoinPrice: marketData.find((d) => d.goodsId === data.goodsId)
        .goodsCoinPrice,
    }));
    setMyShoppingCart(result);
  }, [marketData]);

  function isEllipsisActive(e) {
    return e.offsetWidth < e.scrollWidth;
  }

  const handlePopoverOpen = (event) => {
    const isEllipsis = isEllipsisActive(event.currentTarget);
    if (!isEllipsis) return;
    setAnchorEl(event.currentTarget);
    setAnchorText(event.currentTarget.textContent);
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (OLEA_Point_Info?.point < 0) {
      setMinusPointModal(true);
    }
  }, [OLEA_Point_Info?.point]);

  return (
    <>
      {minusPointModal && (
        <Modal
          data={{
            header: "POINT 차감 안내",
            size: "sm",
            sort: "center",
            content: (
              <div>
                OLEA COIN 입금 확인 절차에서 문제가 발생하여
                <br />
                다음 POINT 충천 시
                <strong style={{ color: "#e94786" }}>
                  {" "}
                  {-OLEA_Point_Info?.point}POINT 만큼 차감 후{" "}
                </strong>
                충전됩니다.
                <br />
                <br />
                자세한 내역은
                <br />
                <strong>
                  {"마이 페이지 > POINT 충전 내역 > 관리자 메세지"}
                </strong>
                에서 확인하실 수 있습니다.
              </div>
            ),
            btnType: "point",
            ico: false,
            leftBtn: false,
            btnRightText: "확인",
            rdcBtnHandler: () => {
              setMinusPointModal(false);
            },
          }}
        />
      )}

      <div className="market_contents_wrapper">
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }} style={{ fontSize: "1.5rem" }}>
            {anchorText}
          </Typography>
        </Popover>

        <MarketLeftContainer
          states={{
            myShoppingCart,
            setMyShoppingCart,
            handlePopoverOpen,
            handlePopoverClose,
            totalCheck,
            totalPrice,
          }}
        />

        <MarketRightContainer
          states={{
            count,
          }}
        />

        <MarketTab />

        <MarketItemContainer
          states={{
            open: open,
            handlePopoverOpen: handlePopoverOpen,
            handlePopoverClose: handlePopoverClose,
            enqueueSnackbar: enqueueSnackbar,
            myShoppingCart: myShoppingCart,
            setMyShoppingCart: setMyShoppingCart,
          }}
        />
      </div>
    </>
  );
};

export default React.memo(MarketTemplate);
