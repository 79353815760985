import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

import "./TimerInformation.scss";

const TimerInformation = () => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        opacity: 0.95,
      }}
    >
      <div className="timer_info_box">
        <div>
          <span className="timer_info_sub_text">
            5분마다 시세가 변동됩니다.
          </span>
        </div>
      </div>
    </TableContainer>
  );
};

export default TimerInformation;
