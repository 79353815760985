import React from "react";

// import Icons
import MainMidLogo from "../../../../assets/icons/main_mid_logo.png";
import HomeCarousel from "./HomeCarousel";

import "./HomeBrand.scss";

const HomeBrand = () => {
  return (
    <div className="main_brand_wrapper">
      <div className="main_brand_logo_section">
        <img src={MainMidLogo} alt="OLEA MARKET" />
      </div>

      <div className="main_bran_carousel_section">
        <HomeCarousel />
      </div>
    </div>
  );
};

export default HomeBrand;
