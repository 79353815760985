import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import randomString from "../../../../../utils/randomString";
import Modal from "../../../../modal/Modal";
import VanillaTilt from "vanilla-tilt";
import "../../MarketRender.scss";
import numberWithCommas from "../../../../../utils/numbersWithComas";

function MarketItem({
  data,
  open,
  handlePopoverOpen,
  handlePopoverClose,
  isEvent,
  clickPutInHandler,
}) {
  const tilt = React.useRef(null);
  useEffect(() => {
    const options = {
      reverse: false,
      max: 35,
      startX: 0,
      startY: 0,
      perspective: 1000,
      speed: 500,
      transition: true,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      "mouse-event-element": null,
      gyroscope: true,
      gyroscopeMinAngleX: -45,
      gyroscopeMaxAngleX: 45,
      gyroscopeMinAngleY: -45,
      gyroscopeMaxAngleY: 45,
    };
    VanillaTilt.init(tilt.current, options);
  }, []);
  return (
    <>
      <div className="market_render_wrapper" ref={tilt}>
        <div className="market_render_item_img_box">
          {data.onSale === 0 && (
            <div className="market_render_item_img_cover"></div>
          )}
          <img src={data.goodsImageUrl} alt="" />
        </div>
        <p className="market_render_item_brand">{data.goodsBrand}</p>

        <p
          className="market_render_item_name"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {data.goodsName}
        </p>

        <div className="market_render_item_bottom_group">
          <span>{numberWithCommas(data.goodsPointPrice)} POINT</span>
          <button
            className="bg_club200"
            disabled={
              data.onSale === 0 ||
              (isEvent === 1 && data.category !== "6") ||
              (isEvent === 0 && data.category === "6")
            }
            onClick={() => {
              clickPutInHandler(data);
            }}
          >
            담기
          </button>
        </div>
      </div>
    </>
  );
}

const MarketRender = ({ states, renderArr }) => {
  const {
    open,
    handlePopoverOpen,
    handlePopoverClose,
    enqueueSnackbar,
    myShoppingCart,
    setMyShoppingCart,
  } = states;

  const isEvent = useSelector((state) => state.market.showClubMarket?.isEvent);

  const [modalOpen, setModalOpen] = useState(0);

  const modalData = [
    {
      header: "오류",
      size: "sm",
      sort: "center",
      content: (
        <p>NFT Special Edition 구매 시, 장바구니에 1개씩만 담을 수 있습니다.</p>
      ),
      ico: true,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
  ];

  const clickPutInHandler = (data) => {
    if (isEvent === 1 && data.category === "6") {
      if (myShoppingCart.length === 1) {
        setModalOpen(1);
        return;
      }

      if (myShoppingCart.length < 1) {
        enqueueSnackbar(`${data.goodsName} 담기 완료`, {
          autoHideDuration: 2000,
          variant: "success",
        });
        setMyShoppingCart([
          ...myShoppingCart,
          { ...data, uniId: randomString() },
        ]);
      }
    } else {
      enqueueSnackbar(`${data.goodsName} 담기 완료`, {
        autoHideDuration: 2000,
        variant: "success",
      });
      setMyShoppingCart([
        ...myShoppingCart,
        { ...data, uniId: randomString() },
      ]);
    }
  };

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      {renderArr.map((data) => (
        <MarketItem
          key={data.goodsId}
          data={data}
          open={open}
          handlePopoverOpen={handlePopoverOpen}
          handlePopoverClose={handlePopoverClose}
          isEvent={isEvent}
          clickPutInHandler={clickPutInHandler}
        />
      ))}
    </>
  );
};

export default MarketRender;
