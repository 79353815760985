import "./SignUpComplete.scss";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SignUpComplete = () => {
  const userId = useSelector((state) => state.user?.userId) || "test@test.com";

  return (
    <div className="SignUpComplete">
      <h1>회원가입 완료</h1>

      <div className="signUpComplete_area">
        <h2>
          <strong>{userId}</strong> 님 안녕하세요!
        </h2>

        <div className="line" />

        <p>
          가입하신 이메일 주소 <strong>{userId}</strong>로 인증메일을 보내
          드렸습니다.
          <br />
          이메일 인증을 완료 후 서비스를 사용하실 수 있습니다.
          <br />
          <br />
          이메일 주소를 잘못 입력하신 경우 재가입이 필요합니다.
          <br />
          <br />
          <span className="invalid">
            24시간 내에 인증되지 않을 경우 해당 계정은 사용 불가능 합니다.
          </span>
          <br />
          인증 메일을 받지 못하신 경우 로그인 후 재전송 받으실 수 있습니다.
        </p>
      </div>

      <Link to="/">HOME</Link>
    </div>
  );
};

export default SignUpComplete;
