import React from "react";
import {
  renderCurrentTypeConvert,
  tierTypeConvert,
} from "../../../../shared/NftData";

const MyWalletRequestOleaClaim = ({ states }) => {
  const { tierType, edition, balance, amount, setAmount } = states;
  const MAX_CLAIM_AMOUNT = 10000;

  const cardInfoData = [
    {
      mobileText: "구분",
      renderText: tierTypeConvert(tierType),
      renderType: "",
    },
    { mobileText: "NFT ID", renderText: `# ${edition}`, renderType: "" },
    {
      mobileText: "타입",
      renderText: renderCurrentTypeConvert(tierType),
      renderType: "",
    },
    {
      mobileText: "잔여",
      renderText: `${balance?.toLocaleString()} OLEA COIN`,
      renderType: "invalid",
    },
    {
      mobileText: "출금 신청 가능 OLEA COIN",
      // renderText: `${balance - claimStandard(tierType)} OLEA`,
      renderText: `${(
        Math.floor(balance / 10000) * 10000
      ).toLocaleString()} OLEA COIN`,
      renderType: "valid",
    },
  ];

  // const handleKeyDown = (e) => {
  //   if (e.key === "e" || e.key === ".") {
  //     e.preventDefault();
  //   }
  // };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (balance < newValue) {
      alert("잔여 OLEA가 부족합니다.");
      return;
    }
    setAmount(newValue);
    // if (newValue > (balance ? balance : MAX_CLAIM_AMOUNT)) {
    //   setAmount(Number(balance ? balance : MAX_CLAIM_AMOUNT));
    // } else {
    //   setAmount(newValue);
    // }
  };

  return (
    <div>
      {cardInfoData.map((data) => (
        <div
          className="my_wallet_req_olea_claim_text_wrap"
          key={data.mobileText}
        >
          <span className={`my_wallet_card_text ${data.renderType}`}>
            {data.mobileText}
          </span>

          <span
            className={`my_wallet_card_text ${data.renderType}`}
            style={data.renderType === "" ? { color: "#555" } : undefined}
          >
            {data.renderText}
          </span>
        </div>
      ))}
      <select
        style={{ marginRight: 8, padding: "6px 2px", borderRadius: 4 }}
        value={amount}
        onChange={handleChange}
      >
        <option value={0} disabled hidden>
          출금 신청 OLEA COIN 수량 선택
        </option>

        {[10000, 20000, 30000, 40000, 50000].map((value) => (
          <option key={value} value={value}>
            {value.toLocaleString()}
          </option>
        ))}
      </select>
      {/* <input
        type="number"
        min={10000}
        // max={
        //   balance - claimStandard(tierType) < MAX_CLAIM_AMOUNT
        //     ? balance - claimStandard(tierType)
        //     : MAX_CLAIM_AMOUNT
        // }
        className="olea_claim_input"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={amount || 0}
      /> */}
      OLEA COIN
      <p>10,000 OLEA COIN 단위로 출금 신청이 가능합니다.</p>
      {/* <p>각 NFT당 하루 1회, 10000 OLEA 단위로</p> */}
      {/* <p>일일 최대 10,000 OLEA 출금 신청이 가능합니다.</p> */}
    </div>
  );
};

export default MyWalletRequestOleaClaim;
