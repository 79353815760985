import { useDispatch } from "react-redux";
import "./SignUp.scss";
import React, { useEffect, useState } from "react";
import { postSignUpUser } from "../../../redux/modules/auth";
import Modal from "../../modal/Modal";
import TermsOfUse from "../../policy/TermsOfUse";
import CollectionOfPersonalInfo from "../../policy/CollectionOfPersonalInfo";
import Marketing from "../../policy/Marketing";

const SignUp = () => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [ageCheck, setAgeCheck] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [collectionOfPersonalInfo, setCollectionOfPersonalInfo] =
    useState(false);
  const [marketing, setMarketing] = useState(0);

  const [modalOpen, setModalOpen] = useState(0);

  const signUpHandler = (e) => {
    e.preventDefault();

    if (userId === "" || password === "" || passwordCheck === "") {
      return alert("입력사항을 모두 입력해 주세요.");
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userId)) {
      return alert("이메일 형식을 입력해 주세요.");
    }

    if (
      !/^.*(?=^.{8,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/.test(
        password
      )
    ) {
      return alert(
        "비밀번호는 영문 대소문자, 숫자, 특수문자(!@#$%^&+=)를\n8자 이상 20자 이하로 입력해 주세요."
      );
    }

    if (password !== passwordCheck) {
      return alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
    }

    if (!ageCheck || !termsOfUse || !collectionOfPersonalInfo) {
      return alert("필수항목을 모두 확인해 주세요.");
    }
    dispatch(postSignUpUser({ email: userId, password, marketing }));
  };

  const modalData = {
    TERMS_OF_USE: {
      header: "이용약관",
      size: "md",
      ico: false,
      sort: "center",
      content: <TermsOfUse />,
      leftBtn: false,
      rightBtn: false,
      ldcBtnHandler: () => setModalOpen(0),
    },

    COLLECTION_OF_PERSONAL_INFO: {
      header: "개인정보처리방침",
      size: "md",
      ico: false,
      sort: "center",
      content: <CollectionOfPersonalInfo />,
      leftBtn: false,
      rightBtn: false,
      ldcBtnHandler: () => setModalOpen(0),
    },

    MARKETING: {
      header: "마케팅 활용 및 광고 수신",
      size: "md",
      ico: false,
      sort: "center",
      content: <Marketing />,
      leftBtn: false,
      rightBtn: false,
      ldcBtnHandler: () => setModalOpen(0),
    },
  };

  const [allChecked, setAllChecked] = useState(
    ageCheck && termsOfUse && collectionOfPersonalInfo && marketing
      ? true
      : false
  );

  useEffect(() => {
    if (ageCheck && termsOfUse && collectionOfPersonalInfo && marketing) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [ageCheck, termsOfUse, collectionOfPersonalInfo, marketing]);

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen]} />}

      <div className="SignUp">
        <h1>회원가입</h1>

        <div className="signUp_area">
          <h2>
            OLEA MARKET 회원가입을 위해
            <br />
            아래 입력사항을 모두 입력해 주세요
          </h2>

          <div className="gray-line" />

          <form className="form_area" onSubmit={signUpHandler}>
            <div className="input_area">
              <label htmlFor="username">이메일</label>
              <input
                type="text"
                id="username"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                required
                placeholder="이메일 입력"
              />
            </div>

            <div className="input_area">
              <label htmlFor="password">비밀번호</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="비밀번호 입력"
              />
            </div>

            <div className="input_area">
              <label htmlFor="passwordCheck">비밀번호 확인</label>
              <input
                type="password"
                id="passwordCheck"
                value={passwordCheck}
                onChange={(e) => setPasswordCheck(e.target.value)}
                required
                placeholder="비밀번호 확인"
              />
            </div>

            <div className="gray-line" />

            <div className="check_area">
              <ul
                style={{
                  marginBottom: "3px",
                }}
              >
                <li>
                  <input
                    style={{
                      width: 15,
                    }}
                    type="checkbox"
                    id="check-0"
                    checked={allChecked}
                    onChange={() => {
                      setAgeCheck(!allChecked);
                      setTermsOfUse(!allChecked);
                      setCollectionOfPersonalInfo(!allChecked);
                      setMarketing(!allChecked ? 1 : 0);
                    }}
                  />

                  <label
                    htmlFor="check-0"
                    style={{
                      fontSize: 18,
                      borderBottom: "1px solid #e1e1e1",
                    }}
                  >
                    <strong>모든 약관</strong>에 동의합니다.
                  </label>
                </li>
              </ul>

              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="check-1"
                    checked={ageCheck}
                    onChange={() => setAgeCheck(!ageCheck)}
                  />

                  <label htmlFor="check-1">
                    (필수) <strong>만 14세 이상</strong>입니다.
                  </label>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="check-2"
                    checked={termsOfUse}
                    onChange={() => setTermsOfUse(!termsOfUse)}
                  />
                  <label htmlFor="check-2">
                    (필수){" "}
                    <strong
                      onClick={(e) => {
                        e.stopPropagation();
                        // setModalOpen("TERMS_OF_USE");
                        window.open(
                          "https://olea-market-static.s3.ap-northeast-2.amazonaws.com/oleamarket-terms-of-service.pdf",
                          "__blank"
                        );
                      }}
                    >
                      이용약관
                    </strong>
                    에 동의합니다.
                  </label>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="check-3"
                    checked={collectionOfPersonalInfo}
                    onChange={() =>
                      setCollectionOfPersonalInfo(!collectionOfPersonalInfo)
                    }
                  />
                  <label htmlFor="check-3">
                    (필수){" "}
                    <strong
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalOpen("COLLECTION_OF_PERSONAL_INFO");
                      }}
                    >
                      개인정보 수집이용
                    </strong>
                    에 동의합니다.
                  </label>
                </li>

                <li>
                  <input
                    type="checkbox"
                    id="check-4"
                    checked={marketing}
                    onChange={() => setMarketing(marketing ? 0 : 1)}
                  />
                  <label htmlFor="check-4">
                    (선택){" "}
                    <strong
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalOpen("MARKETING");
                      }}
                    >
                      마케팅 활용 및 광고 수신
                    </strong>
                    에 동의합니다.
                  </label>
                </li>
              </ul>

              <div className="notice_area">
                ※ 필수항목의 개인정보 수집 및 이용 동의에 거부하실 수 있으나,
                <br />
                거부 시 회원가입 및 회원 서비스 이용이 제한됩니다.
              </div>
            </div>

            <div className="input_area">
              <button type="submit">회원가입</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
