import "./policy.scss";
import React from "react";

const CollectionOfPersonalInfo = () => {
  return (
    <div className="policy">
      <table>
        <thead>
          <tr>
            <th>수집 시점</th>
            <th>수집 및 이용목적</th>
            <th>필수 / 선택</th>
            <th>수집항목</th>
            <th>이용 및 보유기간</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>회원가입 시</td>
            <td rowSpan={3}>회원제 서비스 재공</td>
            <td rowSpan={4}>필수</td>
            <td>이메일 주소</td>
            <td rowSpan={3}>회원 탈퇴 시까지</td>
          </tr>

          <tr>
            <td>디스코드 로그인 시</td>
            <td>이메일 주소, 디스코드 회원 고유 번호</td>
          </tr>

          <tr>
            <td>메타마스크 지갑 등록 시</td>
            <td>메타마스크 지갑 주소</td>
          </tr>

          <tr>
            <td>이메일 문의 시</td>
            <td>
              1:1 문의 답변에 따른
              <br />
              개인 식별 및 답변 내용 전달
            </td>
            <td>이메일 주소</td>
            <td>
              1:1 문의 답변
              <br />
              완료 시 까지
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CollectionOfPersonalInfo;
