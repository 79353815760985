import { useState } from "react";

// import Mui
import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SortContainer from "./SortContainer";
import { tokenMarketCategoryArr } from "../../../../../shared/NftData";

export default function MarketTab() {
  const [value, setValue] = useState(tokenMarketCategoryArr[0].value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "40px", position: "relative" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="inherit"
          aria-label="basic tabs example"
        >
          {/* {tokenMarketCategoryArr.map((cur, idx) => {
            return (
              <Tab
                label={cur.name}
                key={cur.value}
                sx={{ fontSize: "14px" }}
                value={cur.value}
              />
            );
          })} */}
        </Tabs>
      </Box>

      <SortContainer states={{ value }} />
    </Box>
  );
}
