import { useState } from "react";
import SubHeader from "../../../header/template/SubHeader";
import MarketTemplate from "./template/MarketTemplate";

const Olea = () => {
  const [count, setCount] = useState(0);

  return (
    <>
      <SubHeader
        states={{
          title: "Gifticon Market",
          type: "TOKEN",
          count: count,
          setCount: setCount,
        }}
      />
      <MarketTemplate count={count} />
    </>
  );
};

export default Olea;
