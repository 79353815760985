import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFound from "../../components/pages/error/NotFound";

const Router = () => {
  const routeMap = useSelector((state) => state.user.routeMap);

  return (
    <>
      <Switch>
        {routeMap.map(({ path, Component }) => (
          <Route key={path} exact path={path} component={Component} />
        ))}
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default Router;
