import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateSet2 } from "../../../../utils/dateSet";
import MuiPagination from "../../../muiPagination/MuiPagination";
import { currentPosts } from "../../../../utils/paginationCurrentPosts";
import Modal from "../../../modal/Modal";

const tableHeader = {
  deposit: [
    "신청일",
    "진행 상태",
    "신청 POINT",
    "전송 OLEA COIN",
    "트랜잭션 해시",
    "충전 후 잔액",
    "",
  ],

  withdrawal: ["구매일", "상품명", "가격", "구매 후 잔액"],
};

export default function OleaPointLog({ renderLogData }) {
  const dispatch = useDispatch();
  const pointLogs = useSelector((state) => state.user.pointLogs);

  const [renderArr, setRenderArr] = useState([]);
  const [postsPerPage] = useState(5);
  const [postsCurPage, setPostsCurPage] = useState(1);

  const [isMemoOpen, setIsMemoOpen] = useState(false);
  const [memoContent, setMemoContent] = useState("");

  useEffect(() => {
    if (!pointLogs?.[renderLogData]) return;

    setRenderArr(
      currentPosts(pointLogs[renderLogData], postsCurPage, postsPerPage)
    );
  }, [pointLogs, renderLogData, postsCurPage, postsPerPage]);

  useEffect(() => {
    setPostsCurPage(1);
  }, [renderLogData]);

  return (
    <>
      {renderArr && pointLogs?.[renderLogData] ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  {tableHeader[renderLogData].map((col) => (
                    <TableCell key={col} align="center">
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {renderArr.map((row) => {
                  if (renderLogData === "deposit")
                    return (
                      <TableRow key={row.createdAt}>
                        <TableCell align="center">
                          {dateSet2(row.createdAt)}
                        </TableCell>

                        <TableCell align="center">
                          <span
                            className={`${row.status === "취소" ? "cancel" : ""}
                        ${row.status === "완료" ? "done" : ""}`}
                          >
                            {row.status}
                          </span>
                        </TableCell>

                        <TableCell align="center">
                          {row.amount.toLocaleString()}
                        </TableCell>

                        <TableCell align="center">
                          {row.oleaForExchange
                            ? row.oleaForExchange.toLocaleString()
                            : ""}
                        </TableCell>

                        <TableCell align="center">{row.hashValue}</TableCell>

                        <TableCell align="center">
                          {row.balance ? row.balance.toLocaleString() : ""}
                        </TableCell>

                        <TableCell align="center" width={"160px"}>
                          {row.memoForNotice ? (
                            <span>
                              {isMemoOpen && (
                                <Modal
                                  data={{
                                    header: "관리자 메세지",
                                    size: "sm",
                                    ico: false,
                                    sort: "center",
                                    content: (
                                      <>
                                        <div className="memo_div">
                                          {memoContent}
                                        </div>
                                      </>
                                    ),
                                    btnType: "point",
                                    leftBtn: false,
                                    btnRightText: "닫기",
                                    rdcBtnHandler: ({ modalPayload }) => {
                                      modalPayload.setIsMemoOpen(false);
                                      setMemoContent("");
                                    },
                                  }}
                                  modalPayload={{ setIsMemoOpen }}
                                />
                              )}
                              <button
                                onClick={() => {
                                  setIsMemoOpen(true);
                                  setMemoContent(row.memoForNotice);
                                }}
                                className="btn sm point"
                              >
                                관리자 메세지
                              </button>
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </TableCell>
                      </TableRow>
                    );

                  if (renderLogData === "withdrawal")
                    return (
                      <TableRow key={row.createdAt}>
                        <TableCell align="center">
                          {dateSet2(row.createdAt)}
                        </TableCell>

                        <TableCell align="center">{row.goods_name}</TableCell>

                        <TableCell align="center">
                          {row.amount.toLocaleString()}
                        </TableCell>

                        <TableCell align="center">
                          {row.balance ? row.balance.toLocaleString() : ""}
                        </TableCell>
                      </TableRow>
                    );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="pagination">
            <MuiPagination
              count={Math.ceil(pointLogs[renderLogData].length / postsPerPage)}
              cur={postsCurPage}
              setCur={setPostsCurPage}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
