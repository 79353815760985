import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

import "./TimerInformation.scss";

const RouletteInfo = () => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        opacity: 0.95,
      }}
    >
      <div className="timer_info_box">
        <div>
          <span className="timer_info_sub_text">
            마켓에서 룰렛을 이용하실 수 있는 게임머니를 구매할 수 있습니다.
          </span>
        </div>
      </div>
    </TableContainer>
  );
};

export default RouletteInfo;
