import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

import "./TimerInformation.scss";

const PurchaseChance = () => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        opacity: 0.95,
      }}
    >
      <div className="timer_info_box">
        <div>
          <span className="timer_info_sub_text">
            구매 시점으로부터 24시간 이후 잔여 구매 기회가 초기화됩니다.
          </span>
        </div>
      </div>
    </TableContainer>
  );
};

export default PurchaseChance;
