import METAMASK from "../assets/icons/MetaMask_Fox 1.png";
import KLIP from "../assets/icons/klip_logo_blue.png";

export const logoConvert = (walletType) => {
  switch (walletType) {
    case 1:
      return METAMASK;
    case 2:
      return KLIP;
    default:
      return;
  }
};
