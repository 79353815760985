import axios from "axios";
import AuthService from "./authService";
import TokenService from "./token";

let isTokenRefreshing = false;
let refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
  refreshSubscribers.map((callback, idx) => {
    return callback(accessToken);
  });
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

axios.interceptors.response.use(
  (config) => {
    // 오류 없을 때
    return config;
  },

  async (error) => {
    const { config, response } = error;
    const { code, message } = response.data.error;
    const originalReq = config; // 에러가 난 요청정보들
    // if (code === 500) {
    //   alert("알 수 없는 오류가 발생했습니다.");
    //   window.location.href = "/";
    //   return;
    // }
    if (message === "Bad Request") {
      alert("잘못된 요청입니다.");
      window.location.href = "/";
      return;
    }
    // if (message === "Not authentication") {
    //   TokenService.clear();
    //   alert("간편 로그인 토큰이 만료되었습니다. 다시 로그인해 주세요.");
    //   window.location.href = "/";
    //   return;
    // }
    else if (message === "Token expired") {
      // 토큰이 만료되고,
      if (!isTokenRefreshing) {
        // 토큰 생성중이면, if 실행되지 않도록
        isTokenRefreshing = true; // false 일때는 true로 바꿔주고.

        const res = await AuthService.issuanceNewToken();

        const { accessToken } = res.data;
        TokenService.save(accessToken); // 쿠키에 다시 저장

        isTokenRefreshing = false; // 토큰 생성중 상태를 fasle로 바꿔주고

        // 헤더를 새로운 액세스토큰으로 헤더 재설정
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        originalReq.headers.Authorization = `Bearer ${accessToken}`;

        onTokenRefreshed(accessToken); // 첫 요청이 아닌 다른 쌓여있던 요청 다시 요청보내기
        refreshSubscribers = []; // 요청 배열 초기화

        return axios(originalReq); // 첫 요청 다시 요청
      }
      // 토큰을 생성중일때  (if문을 passing 한 요청들은 )
      const retryOriginalRequest = new Promise((resolve) => {
        addRefreshSubscriber((accessToken) => {
          originalReq.headers.Authorization = "Bearer " + accessToken;
          resolve(axios(originalReq));
        });
      });
      return retryOriginalRequest; // 모아둔 요청 재실행
    }
    return Promise.reject(error);
  }
);
