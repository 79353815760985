import { takeEvery, put, call, select } from "redux-saga/effects";

import MarketService from "../../service/marketService";
import { loadingOff, loadingOn } from "./loding";
import dateSet, { getTime } from "../../utils/dateSet";

const prefix = "olea/market";

// action type
const actionType = {};

const actionTypeArray = ["START", "SUCCESS", "FAIL"];

actionTypeArray.forEach((data) => {
  actionType[data] = `${prefix}/${data}`;
});

const { START, SUCCESS, FAIL } = actionType;

export const actionCreator = (type, data) => ({
  type,
  data,
});

// action creator
const start = () => actionCreator(START);
export const success = (data) => actionCreator(SUCCESS, data);
const fail = (error) => actionCreator(FAIL, error);

// initial state
const initialState = {
  error: null,
  info: null,
  // showMarket: {
  //   marketData: [],
  // },
  // showClubMarket: {
  //   marketData: [],
  // },
  // showTicketMarket: {
  //   marketData: [],
  // },
  // showOleaMarket: {
  //   marketData: [],
  // },
  tokenMarket: {
    marketData: [],
    purchaseStatus: 0,
  },
  OLEA_USDT: 0,
  USDT_KRW: 0,
  paymentLogsId: 0,
  userRestAmount: 0,
  paymenetLogArr: [],
};

// reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START:
      return {
        ...state,
        error: null,
      };
    case SUCCESS:
      return {
        ...state,
        ...action.data,
        error: null,
      };
    case FAIL:
      return {
        ...state,
        error: action.data.error,
      };
    default:
      return state;
  }
}

// saga action
const sagaActionType = {};

const sagaActionTypeArr = [
  "MARKET_SET_STATE",
  "MARKET_GET_SHOW",
  "MARKET_GET_SHOW_CLUB",
  "MARKET_GET_SHOW_TICKET",
  "MARKET_POST_ORDER",
  "MARKET_POST_CLUB_ORDER",
  "MARKET_POST_TICKET_ORDER",
  "MARKET_GET_BRAND",
  "MARKET_GET_OLEA_TICKER",
  "MARKET_GET_KRW_TICKER",
  "MARKET_POST_OLEA_PAYMENT_LOG",
  "MARKET_POST_VERIFY_OLEA_PAYMENT_LOG",
  "MARKET_GET_USER_REST_AMOUNT",
  "MARKET_GET_USER_PAYMENT_LOG",
  "MARKET_GET_PAYMENT_LOG_LIST",
  "MARKET_GET_OLEA_POINT",
  "MARKET_GET_OLEA_POINT_INFO",
  "MARKET_POST_EXCHANGE",
  "MARKET_PATCH_EXCHANGE",
  "MARKET_PATCH_EXCHANGE_CANCEL",
  "MARKET_GET_SHOW_MARKET",
  "GET_TOKEN_MARKET_GOODS_LIST",
  "POST_TOKEN_MARKET_GOODS_PAYMENT",
];

sagaActionTypeArr.forEach((data) => {
  sagaActionType[data] = `${prefix}/${data}`;
});

const {
  MARKET_SET_STATE,
  MARKET_GET_SHOW,
  MARKET_GET_SHOW_CLUB,
  MARKET_GET_SHOW_TICKET,
  MARKET_POST_ORDER,
  MARKET_POST_CLUB_ORDER,
  MARKET_POST_TICKET_ORDER,
  MARKET_GET_BRAND,
  MARKET_GET_OLEA_TICKER,
  MARKET_GET_KRW_TICKER,
  MARKET_POST_OLEA_PAYMENT_LOG,
  MARKET_POST_VERIFY_OLEA_PAYMENT_LOG,
  MARKET_GET_USER_REST_AMOUNT,
  MARKET_GET_USER_PAYMENT_LOG,
  MARKET_GET_PAYMENT_LOG_LIST,
  MARKET_GET_OLEA_POINT,
  MARKET_GET_OLEA_POINT_INFO,
  MARKET_POST_EXCHANGE,
  MARKET_PATCH_EXCHANGE,
  MARKET_PATCH_EXCHANGE_CANCEL,
  MARKET_GET_SHOW_MARKET,
  GET_TOKEN_MARKET_GOODS_LIST,
  POST_TOKEN_MARKET_GOODS_PAYMENT,
} = sagaActionType;

// saga action creator
export const marketSetStateSagaActionCreator = (data) =>
  actionCreator(MARKET_SET_STATE, data);

export const marketGetShowSagaActionCreator = () =>
  actionCreator(MARKET_GET_SHOW);

export const marketGetShowClubSagaActionCreator = () =>
  actionCreator(MARKET_GET_SHOW_CLUB);

export const marketGetShowTicketSagaActionCreator = () =>
  actionCreator(MARKET_GET_SHOW_TICKET);

export const marketGetShowMarketSagaActionCreator = () =>
  actionCreator(MARKET_GET_SHOW_MARKET);

export const marketPostOrderSagaActionCreator = (data) =>
  actionCreator(MARKET_POST_ORDER, data);

export const marketPostClubOrderSagaActionCreator = (data) =>
  actionCreator(MARKET_POST_CLUB_ORDER, data);

export const marketPostTicketOrderSagaActionCreator = (data) =>
  actionCreator(MARKET_POST_TICKET_ORDER, data);

export const marketGetBrandSagaActionCreator = () =>
  actionCreator(MARKET_GET_BRAND);

export const marketGetOLEATickerSagaActionCreator = () =>
  actionCreator(MARKET_GET_OLEA_TICKER);

export const marketGetKRWTickerSagaActionCreator = () =>
  actionCreator(MARKET_GET_KRW_TICKER);

export const marketPostOLEAPaymentLogSagaActionCreator = (data) =>
  actionCreator(MARKET_POST_OLEA_PAYMENT_LOG, data);

export const marketPostVerifyOLEAPaymentLogSagaActionCreator = (data) =>
  actionCreator(MARKET_POST_VERIFY_OLEA_PAYMENT_LOG, data);

export const marketGetUserRestAmountSagaActionCreator = (data) =>
  actionCreator(MARKET_GET_USER_REST_AMOUNT, data);

export const marketGetUserPaymentLogSagaActionCreator = () =>
  actionCreator(MARKET_GET_USER_PAYMENT_LOG);

export const marketGetPaymentLogListSagaActionCreator = (data) =>
  actionCreator(MARKET_GET_PAYMENT_LOG_LIST, data);

export const marketGetOLEAPointSagaActionCreator = (data) =>
  actionCreator(MARKET_GET_OLEA_POINT, data);

export const marketGetOLEAPointInfoSagaActionCreator = (data) =>
  actionCreator(MARKET_GET_OLEA_POINT_INFO, data);

export const marketPostExchangeSagaActionCreator = (data) =>
  actionCreator(MARKET_POST_EXCHANGE, data);

export const marketPatchExchangeSagaActionCreator = (data) =>
  actionCreator(MARKET_PATCH_EXCHANGE, data);

export const marketPatchExchangeCancelSagaActionCreator = (data) =>
  actionCreator(MARKET_PATCH_EXCHANGE_CANCEL, data);

export const getTokenMarketGoodsList = (data) =>
  actionCreator(GET_TOKEN_MARKET_GOODS_LIST, data);

export const postTokenMarketGoodsPayment = (data) =>
  actionCreator(POST_TOKEN_MARKET_GOODS_PAYMENT, data);

// saga reducer
function* sagaReducer(action) {
  const type = action.type;
  switch (type) {
    case MARKET_SET_STATE:
      try {
        yield put(loadingOn());
        yield put(success(action.data));
        yield put(loadingOff());
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case MARKET_GET_SHOW:
      try {
        yield put(loadingOn());
        const res = yield call(MarketService.getShowHolderMarket);
        if (res.message === "Ok") {
          const data = res.data;
          yield put(
            success({
              showMarket: {
                CAP: data.CAP,
                brandsData: data.brandsData,
                date: data.date,
                isEvent: data.isEvent,
                marketCost: data.marketCost,
                marketData: data.marketData.map((data) => ({
                  ...data,
                  goodsName: data.goodsName.split("] ").at(-1),
                })),
                oleaPrice: data.oleaPrice,
                purchaseStatus: data.purchaseStatus,
                resTime: data.resTime,
                time: data.time,
              },
            })
          );
          yield put(loadingOff());
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case MARKET_GET_SHOW_CLUB:
      try {
        yield put(loadingOn());
        const res = yield call(MarketService.getShowClubMarket);
        if (res.message === "Ok") {
          const data = res.data;
          yield put(
            success({
              showClubMarket: {
                brandsData: data.brandsData,
                marketData: data.marketData.map((data) => ({
                  ...data,
                  goodsName: data.goodsName.split("] ").at(-1),
                })),
                oleaPrice: data.oleaPrice,
                purchaseStatus: data.purchaseStatus,
                resTime: data.resTime,
              },
            })
          );
          yield put(loadingOff());
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case MARKET_GET_SHOW_TICKET:
      try {
        yield put(loadingOn());
        const res = yield call(MarketService.getShowTicketMarket);
        if (res.message === "Ok") {
          const data = res.data;
          yield put(
            success({
              showTicketMarket: data,
            })
          );
          yield put(loadingOff());
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case MARKET_GET_SHOW_MARKET:
      try {
        yield put(loadingOn());
        const res = yield call(MarketService.getShowMarket, action.data);
        if (res.message === "Ok") {
          yield put(loadingOff());
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case MARKET_POST_ORDER:
      try {
        yield put(loadingOn());
        const res = yield call(
          MarketService.postMarketOrder,
          action.data.payload
        );
        if (res.message === "구매 성공") {
          yield put(loadingOff());
          action.data.setModalOpen(8);
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        if (
          err.message === "Bad Request" ||
          err.message ===
            "커뮤니티 레벨이 부족합니다.\n디스코드 채팅방을 더 이용해주시기 바랍니다." ||
          err.message ===
            "적립금이 모두 소진되어 더 이상 구매할 수 없습니다." ||
          err.message === "기프티콘이 발급되지 않았습니다."
        ) {
          action.data.setModalOpen(9);
        } else if (
          err.message ===
          "커뮤니티 점수가 부족합니다.\n디스코드 채팅방을 더 이용해주시기 바랍니다."
        ) {
          action.data.setModalOpen(10);
        }
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case MARKET_POST_CLUB_ORDER:
      try {
        yield put(loadingOn());
        if (action.data.payload.nftId) {
          const nftRes = yield call(
            MarketService.postClubMarketOrder,
            action.data.payload
          );
          yield put(loadingOff());
          if (nftRes.message === "구매 성공") {
            action.data.setModalOpen(8);
          }
        } else {
          const voucherRes = yield call(
            MarketService.postClubMarketVoucherOrder,
            action.data.payload
          );
          yield put(loadingOff());
          if (voucherRes.message === "구매 성공") {
            action.data.setModalOpen(8);
          }
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        if (
          err.message === "Bad Request" ||
          err.message ===
            "커뮤니티 레벨이 부족합니다.\n디스코드 채팅방을 더 이용해주시기 바랍니다." ||
          err.message ===
            "적립금이 모두 소진되어 더 이상 구매할 수 없습니다." ||
          err.message === "기프티콘이 발급되지 않았습니다."
        ) {
          action.data.setModalOpen(10);
        } else if (
          err.message ===
          "커뮤니티 점수가 부족합니다.\n디스코드 채팅방을 더 이용해주시기 바랍니다."
        ) {
          action.data.setModalOpen(11);
        }
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case MARKET_POST_TICKET_ORDER:
      try {
        yield put(loadingOn());
        const res = yield call(
          MarketService.postTicketMarketOrder,
          action.data.payload
        );
        if (res.message === "구매 성공") {
          yield put(loadingOff());
          action.data.setModalOpen(4);
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        if (
          err.message === "Bad Request" ||
          err.message ===
            "커뮤니티 레벨이 부족합니다.\n디스코드 채팅방을 더 이용해주시기 바랍니다." ||
          err.message ===
            "적립금이 모두 소진되어 더 이상 구매할 수 없습니다." ||
          err.message === "기프티콘이 발급되지 않았습니다."
        ) {
          action.data.setModalOpen(5);
        } else if (
          err.message ===
          "커뮤니티 점수가 부족합니다.\n디스코드 채팅방을 더 이용해주시기 바랍니다."
        ) {
          action.data.setModalOpen(6);
        }
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case MARKET_GET_BRAND:
      try {
        yield put(loadingOn());
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case MARKET_GET_OLEA_TICKER:
      try {
        const OLEAres = yield call(MarketService.getOLEATicker);
        yield put(
          success({
            OLEA_USDT: Number(OLEAres.price),
          })
        );
      } catch (e) {
        console.log(e);
      }
      break;

    case MARKET_GET_KRW_TICKER:
      try {
        const res = yield call(MarketService.getKRW);
        yield put(
          success({
            USDT_KRW: Number(res.data),
          })
        );
      } catch (error) {
        const err = { ...error.response.data.error };
      }
      break;

    case MARKET_POST_OLEA_PAYMENT_LOG:
      try {
        const res = yield call(MarketService.postOLEAPaymentLog, action.data);
        yield put(
          success({
            paymentLogsId: res.data.paymentLogsId,
          })
        );
      } catch (error) {
        const err = { ...error.response.data.error };

        if (err.message === "rest price is not enough") {
          action.data.setModalOpen("ERROR_EXCEED_USABLE_OLEA");
        }
      }
      break;

    case MARKET_POST_VERIFY_OLEA_PAYMENT_LOG:
      try {
        yield call(MarketService.postVerifyOLEAPaymentLog, action.data.payload);
        action.data.setModalOpen("SUCCESS_BUY_PRODUCT");
      } catch (error) {
        const err = { ...error.response.data.error };
      }
      break;

    case MARKET_GET_USER_REST_AMOUNT:
      try {
        const res = yield call(MarketService.getUserRestAmount, action.data);
        yield put(
          success({
            userRestAmount: res.data.restPrice,
          })
        );
      } catch (error) {
        const err = { ...error.response.data.error };
      }
      break;

    case MARKET_GET_USER_PAYMENT_LOG:
      try {
        const res = yield call(MarketService.getUserPaymentLog);
        yield put(
          success({
            paymenetLogArr: res.data,
          })
        );
      } catch (error) {
        const err = { ...error.response.data.error };
      }
      break;

    case MARKET_GET_PAYMENT_LOG_LIST:
      try {
        yield call(MarketService.getPaymentLogVerify, action.data);
      } catch (error) {
        const err = { ...error.response.data.error };
      }
      break;

    case MARKET_GET_OLEA_POINT:
      try {
        const res = yield call(MarketService.getOLEAPoint, action.data);
        if (res.message === "Ok") {
          yield put(
            success({
              OLEA_Point: res.data,
            })
          );
        }
      } catch (error) {
        const err = { ...error.response.data.error };
      }
      break;

    case MARKET_GET_OLEA_POINT_INFO:
      try {
        const res = yield call(MarketService.getOLEAPointInfo);

        yield put(
          success({
            OLEA_Point_Info: res.data,
          })
        );
      } catch (error) {
        const err = { ...error.response.data.error };
      }
      break;

    case MARKET_POST_EXCHANGE:
      try {
        const res = yield call(MarketService.postExchange, action.data);
        if (res.message === "Ok") {
          yield put(success({ exchangeSuccessId: res.data.id }));
          yield put(marketGetOLEAPointInfoSagaActionCreator());
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        // alert(err.message);
      }
      break;

    case MARKET_PATCH_EXCHANGE:
      try {
        const res = yield call(
          MarketService.patchExchange,
          action.data.payload
        );

        alert(
          "트랜잭션 해시 전송이 완료되었습니다.\n영업시간 내에 관리자 확인 후 POINT가 지급될 예정입니다.\n감사합니다."
        );
        yield put(marketGetOLEAPointInfoSagaActionCreator());
        action.data.setModalOpen(0);
      } catch (error) {
        const err = { ...error.response.data.error };
        alert(err.message);
      }
      break;

    case MARKET_PATCH_EXCHANGE_CANCEL:
      try {
        const res = yield call(
          MarketService.patchExchangeCancel,
          action.data.payload
        );
        if (res.message === "Ok") {
          alert("POINT 충전 요청 취소 완료");
          yield put(marketGetOLEAPointInfoSagaActionCreator());
          action.data.setModalOpen(0);
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        alert(err.message);
      }
      break;
    case GET_TOKEN_MARKET_GOODS_LIST:
      try {
        const tokenMarketState = yield select(
          (state) => state.market.tokenMarket
        );

        const res = yield call(
          MarketService.getTokenMarketGoodsList,
          action.data.type
        );

        yield put(
          success({
            tokenMarket: {
              ...tokenMarketState,
              marketData: res.data.marketData,
              brandsData: res.data.brandsData,
              purchaseStatus: res.data.purchaseStatus,
            },
          })
        );
      } catch (error) {
        const err = { ...error.response.data.error };
      }
      break;

    case POST_TOKEN_MARKET_GOODS_PAYMENT:
      try {
        yield put(loadingOn());
        window.scrollTo({ top: 0, behavior: "smooth" });

        const tokenMarketState = yield select(
          (state) => state.market.tokenMarket
        );
        const OLEAPointInfoState = yield select(
          (state) => state.market.OLEA_Point_Info
        );

        const res = yield call(
          MarketService.postTokenMarketGoodsPayment,
          action.data
        );

        if (res.code === 201) {
          yield put(loadingOff());

          yield put(
            success({
              tokenMarket: {
                ...tokenMarketState,
                purchaseStatus: 0,
              },

              OLEA_Point_Info: {
                ...OLEAPointInfoState,
                point: OLEAPointInfoState.point - action.data.totalPoint,
              },
            })
          );

          alert("결제가 완료되었습니다.\n이메일을 확인해 주세요.");
        }
      } catch (error) {
        yield put(loadingOff());

        const err = { ...error.response.data.error };
        if (err.code === 400)
          return alert(`마지막 구매 시점으로부터 24시간 이후 구매가 가능합니다.\n마지막 구매 시점: ${dateSet(
            err.message.createdAt
          )} (${getTime(err.message.createdAt)})
        `);
      }
      break;

    default:
      break;
  }
}

// export saga
export function* marketSaga() {
  yield takeEvery(MARKET_SET_STATE, sagaReducer);
  yield takeEvery(MARKET_GET_SHOW, sagaReducer);
  yield takeEvery(MARKET_GET_SHOW_CLUB, sagaReducer);
  yield takeEvery(MARKET_GET_SHOW_TICKET, sagaReducer);
  yield takeEvery(MARKET_GET_SHOW_MARKET, sagaReducer);
  yield takeEvery(MARKET_POST_ORDER, sagaReducer);
  yield takeEvery(MARKET_POST_CLUB_ORDER, sagaReducer);
  yield takeEvery(MARKET_POST_TICKET_ORDER, sagaReducer);
  yield takeEvery(MARKET_GET_BRAND, sagaReducer);
  yield takeEvery(MARKET_GET_OLEA_TICKER, sagaReducer);
  yield takeEvery(MARKET_GET_KRW_TICKER, sagaReducer);
  yield takeEvery(MARKET_POST_OLEA_PAYMENT_LOG, sagaReducer);
  yield takeEvery(MARKET_POST_VERIFY_OLEA_PAYMENT_LOG, sagaReducer);
  yield takeEvery(MARKET_GET_USER_REST_AMOUNT, sagaReducer);
  yield takeEvery(MARKET_GET_USER_PAYMENT_LOG, sagaReducer);
  yield takeEvery(MARKET_GET_PAYMENT_LOG_LIST, sagaReducer);
  yield takeEvery(MARKET_GET_OLEA_POINT, sagaReducer);
  yield takeEvery(MARKET_GET_OLEA_POINT_INFO, sagaReducer);
  yield takeEvery(MARKET_POST_EXCHANGE, sagaReducer);
  yield takeEvery(MARKET_PATCH_EXCHANGE, sagaReducer);
  yield takeEvery(MARKET_PATCH_EXCHANGE_CANCEL, sagaReducer);
  yield takeEvery(GET_TOKEN_MARKET_GOODS_LIST, sagaReducer);
  yield takeEvery(POST_TOKEN_MARKET_GOODS_PAYMENT, sagaReducer);
}
