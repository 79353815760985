import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPointLogs,
  userDeleteInfoSagaActionCreator,
} from "../../../../redux/modules/user";
import { getAccessToken } from "../../../../service/token";

import { logoConvert } from "../../../../utils/imageConvert";
import Modal from "../../../modal/Modal";

import "./MyPageTemplate.scss";
import { marketGetOLEAPointInfoSagaActionCreator } from "../../../../redux/modules/market";
import OleaPointLog from "./OleaPointLog";
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import QUESTIONMARK from "../../../../assets/icons/questionMark.svg";

const MyPageTemplate = () => {
  const dispatch = useDispatch();

  const { userId, userEmail, address } = useSelector(
    (state) => state.user.userInfo
  );

  const walletTypeState = useSelector(
    (state) => state.user.userInfo.walletType
  );
  const OLEA_Point_Info = useSelector((state) => state.market.OLEA_Point_Info);
  const pointLogs = useSelector((state) => state.user.pointLogs);

  const [modalOpen, setModalOpen] = useState(0);
  const [renderLogData, setRenderLogData] = useState("deposit");

  useEffect(() => {
    if (!userId) return;
    const decoded = jwtDecode(getAccessToken());
    dispatch(marketGetOLEAPointInfoSagaActionCreator());

    dispatch(getPointLogs());

    // dispatch(getClaimLogsByUserIdSagaActionCreator());
    // dispatch(getClaimToPointLogsByUserIdSagaActionCreator());
    // dispatch(postClaimToPointLogsSagaActionCreator({ userId, nftId, amount, type}));
    // dispatch(postClaimLogsSagaActionCreator({ userId, nftId, amount, type}));
    // dispatch(patchClaimCancelSagaActionCreator({ id }));
  }, [userId, dispatch]);

  const modalData = [
    {
      header: "회원탈퇴 안내",
      size: "sm",
      sort: "center",
      ico: true,
      content: (
        <p>
          회원 탈퇴 시 고객님께서 보유하고 있는 POINT는 사용이 불가합니다.
          <br /> 그래도 회원 탈퇴를 진행하시겠습니까?
          {/* 회원 탈퇴 시 고객님께서 보유하고 계신 Voucher 및 Ticket은 사용이
          불가합니다.
          <br /> 그래도 회원 탈퇴를 진행하시겠습니까? */}
        </p>
      ),
      btnType: "point",
      leftBtn: true,
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
      rightBtnHandler: () => {
        dispatch(userDeleteInfoSagaActionCreator());
        setModalOpen(0);
      },
    },
    {
      header: "POINT 차감 안내",
      size: "sm",
      sort: "center",
      content: (
        <div>
          OLEA COIN 입금 확인 절차에서 문제가 발생하여
          <br />
          다음 POINT 충천 시
          <strong style={{ color: "#e94786" }}>
            {" "}
            {-OLEA_Point_Info?.point}POINT 만큼 차감 후{" "}
          </strong>
          충전됩니다.
          <br />
          <br />
          자세한 내역은
          <br />
          <strong>{"POINT 충전 내역 > 관리자 메세지"}</strong>
          에서 확인하실 수 있습니다.
        </div>
      ),
      btnType: "point",
      ico: false,
      leftBtn: false,
      btnRightText: "확인",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
  ];

  const asset = [
    {
      title: "보유 POINT",
      value:
        OLEA_Point_Info?.point < 0
          ? 0
          : OLEA_Point_Info?.point?.toLocaleString() || 0,
      unit: "POINT",
      type: "POINT",
    },

    {
      title: "구매 시 사용 가능 POINT",
      value:
        OLEA_Point_Info?.point < 0
          ? 0
          : parseInt(OLEA_Point_Info?.point / 10)?.toLocaleString() || 0,
      unit: "POINT",
      type: "useableOLEA POINT",
      tooltip: true,
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopover = (e) => {
    if (e.target.id === "questionmark") return;
    if (open) setOpen(false);
  };

  useEffect(() => {
    if (OLEA_Point_Info?.point < 0) {
      setModalOpen(2);
    }
  }, [OLEA_Point_Info?.point]);
  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <ClickAwayListener onClickAway={handlePopover}>
        <Box>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement={"right-start"}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ marginTop: "10px" }}>
                  <Typography sx={{ p: 0.5 }}>
                    기프티콘 상품 구매 시, 보유한 POINT의 최대 10%까지 사용
                    가능합니다.
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
      <div className="my_page_template_wrapper">
        <div className="my_page_template_content">
          <div className="my_page_template_box">
            <p>
              <span>{userEmail}</span>
            </p>
            {/* <p>
              {userTypeConvert(userType)}
              {userTypeConvertWithText(userType)}
            </p> */}

            <p>
              <span>
                {walletTypeState ? (
                  <img
                    className="smart_wallet_logo_img"
                    src={logoConvert(walletTypeState)}
                    alt="smart wallet logo"
                  />
                ) : null}
                {address}
              </span>
            </p>
          </div>

          <div className="my_page_template_box">
            <div style={{ display: "flex", gap: 10 }}>
              {/* 배포 후 추가 예정
              <button
                className="my_page_template_withdrawal_btn"
                onClick={() => {
                  setModalOpen(1);
                }}
              >
                비밀번호 변경
              </button> */}

              <button
                className="my_page_template_withdrawal_btn"
                onClick={() => {
                  setModalOpen(1);
                }}
              >
                회원탈퇴
              </button>
            </div>
          </div>
        </div>

        <div className="my_page_template_content">
          <div className="my_page_template_box">
            {asset.map((data) => (
              <div key={data.type}>
                <span>
                  {data.title}

                  {data.tooltip ? (
                    <img
                      id="questionmark"
                      src={QUESTIONMARK}
                      alt="QUESTIONMARK"
                      onClick={handlePopoverOpen}
                      style={{ cursor: "pointer", marginLeft: 8 }}
                    />
                  ) : null}
                </span>

                <span>
                  {data.value}
                  <span> {data.unit}</span>
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="my_page_template_content">
          <ul className="olea_point_log_tab_area">
            <li
              className={renderLogData === "deposit" ? "isActive" : ""}
              onClick={() => setRenderLogData("deposit")}
            >
              POINT 충전 내역
            </li>

            <li
              className={renderLogData === "withdrawal" ? "isActive" : ""}
              onClick={() => setRenderLogData("withdrawal")}
            >
              POINT 사용 내역
            </li>
          </ul>

          <OleaPointLog renderLogData={renderLogData} />
        </div>
      </div>
    </>
  );
};

export default MyPageTemplate;
