import "./HowToUse.scss";
import React, { useState } from "react";
import LOGO from "../../../assets/icons/LOGO.svg";
import { Collapse } from "@kunukn/react-collapse";
import backgroundImg from "../../../assets/mainImage/olea_market_bg.png";
import OleaToPoint1 from "../../../assets/howToUse/olea-to-point-1.png";
import OleaToPoint2 from "../../../assets/howToUse/olea-to-point-2.png";
import OleaToPoint3 from "../../../assets/howToUse/olea-to-point-3.png";
import OleaToPoint4 from "../../../assets/howToUse/olea-to-point-4.png";
import OleaToPoint5 from "../../../assets/howToUse/olea-to-point-5.png";
import OleaToPoint6 from "../../../assets/howToUse/olea-to-point-6.png";
import OleaToPoint7 from "../../../assets/howToUse/olea-to-point-7.png";
import OleaToPoint8 from "../../../assets/howToUse/olea-to-point-8.png";
import OleaToPoint9 from "../../../assets/howToUse/olea-to-point-9.png";
import OleaToPoint10 from "../../../assets/howToUse/olea-to-point-10.png";
import OleaToPoint11 from "../../../assets/howToUse/olea-to-point-11.png";

const HowToUse = () => {
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  return (
    <div
      className="HowToUse"
      style={{
        backgroundImage: `url(${backgroundImg})`,
      }}
    >
      <div className="how_to_use_wrapper">
        <h1>
          <img src={LOGO} alt="OLEA MARKET LOGO" /> 이용안내
        </h1>

        <section>
          <h3 onClick={() => setIsOpen1(!isOpen1)}>
            1. OLEA MARKET 서비스
            <div className={`arrowBtn ${isOpen1 ? "on" : ""}`} />
          </h3>

          <Collapse isOpen={isOpen1}>
            <div className="collapse_area">
              <strong>OLEA MARKET</strong>에서는 보유 중인 OLEA COIN을 POINT로
              변환하여 사용하실 수 있으며,
              <br />
              변환된 POINT를 이용하여 기프티콘을 구매할 수 있습니다.
              <br />
              <br />
              {/* <strong>OLEA POINT</strong> 란, OLEA MARKET 내부에서 사용하는
              포인트로
              <br />
              OLEA 혹은 NFT Claim을 통해서 충전하실 수 있습니다.
              <br />
              <br /> */}
              <div className="sub">
                <div>
                  ※ 일 구매 횟수는 1회로 제한되며, 구매한 시점으로부터 24시간이
                  지난 후 구매 기회가 새롭게 주어집니다.
                </div>
                <img src={OleaToPoint10} alt="OleaToPoint10" width={600} />

                <br />
                <br />

                <div>
                  ※ 기프티콘 상품 구매 시, 보유한 POINT의 최대 10%까지 사용
                  가능합니다.
                </div>
                <img src={OleaToPoint11} alt="OleaToPoint11" width={600} />
              </div>
            </div>
          </Collapse>
        </section>

        <div className="gray-line" />

        <section>
          <h3 onClick={() => setIsOpen2(!isOpen2)}>
            2. POINT 충전 방법
            <div className={`arrowBtn ${isOpen2 ? "on" : ""}`} />
          </h3>

          <Collapse isOpen={isOpen2}>
            <div className="collapse_area">
              <ul>
                <li>
                  <h4>
                    ※ <strong>OLEA COIN</strong>을 통한 POINT 충전 방법
                  </h4>

                  <ul className="list-num">
                    <li>
                      <div>
                        로그인 후 상단의 <strong>기프티콘 탭</strong>을 통해
                        Gifticon Market으로 이동합니다.
                        <br />
                        이동 시 아래와 같은 화면에서 충전하고자 하는 만큼의{" "}
                        <strong>POINT</strong>를 선택합니다.
                      </div>
                      <img src={OleaToPoint1} alt="OleaToPoint1" width={400} />
                    </li>

                    <li>
                      <div>
                        충전할 POINT 선택 후 OLEA MARKET의 지갑 주소로 신청
                        당시의 시세에 맞는 OLEA를 전송합니다.
                        <br />
                        전송 후 생성된 트랜잭션 해시를 입력합니다.
                        <br />
                      </div>

                      <br />

                      <div className="sub">
                        ※ 트랜잭션 해시는 트랜잭션이 수행될 때마다 생성되는 고유
                        식별자로 트랜잭션 상태를 추적할 수 있습니다. (
                        <a
                          style={{ borderBottom: "1px solid #000" }}
                          href="https://info.etherscan.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Etherscan에서 자세히 확인하기
                        </a>
                        )
                        <br />
                        ※ 트랜잭션 해시 입력 후에는 신청을 취소할 수 없습니다.
                        <br />※ OLEA MARKET 지갑 주소와 전송할 OLEA COIN을
                        정확히 확인해 주세요.
                      </div>
                      <img src={OleaToPoint2} alt="OleaToPoint1" width={400} />
                    </li>

                    <li>
                      <div>
                        [충전 진행 상태 확인] 버튼을 통해서 충전 진행 상태를
                        확인하실 수 있으며,
                        <br />
                        트랜잭션 해시 입력창을 다시 확인하실 수 있습니다.
                      </div>
                      <img src={OleaToPoint3} alt="OleaToPoint1" width={800} />
                    </li>

                    <li>
                      <div>
                        입력하신 트랜잭션 해시는 [충전 진행 상태 확인] 버튼을
                        통해 다시 확인하실 수 있습니다.
                        <br />
                        이제 영업 시간 내에 관리자 확인 후 POINT가 지급됩니다.
                      </div>
                      <img src={OleaToPoint4} alt="OleaToPoint1" width={800} />
                    </li>

                    <li>
                      <div>
                        마이 페이지에서 POINT 충전 내역을 확인하실 수 있습니다.
                        <br />
                        충전 중 문제가 생긴 경우, 관리자 메세지를 통해 해당
                        내용을 확인하실 수 있습니다.
                      </div>
                      <img src={OleaToPoint5} alt="OleaToPoint1" width={800} />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </Collapse>
        </section>

        <div className="gray-line" />

        <section>
          <h3 onClick={() => setIsOpen3(!isOpen3)}>
            3. POINT 사용 방법
            <div className={`arrowBtn ${isOpen3 ? "on" : ""}`} />
          </h3>

          <Collapse isOpen={isOpen3}>
            <div className="collapse_area">
              <ul className="list-num">
                <li>
                  <div>
                    상단의 <strong>기프티콘 탭</strong>을 통해 Gifticon
                    Market으로 이동합니다.
                    <br />
                    이동 시 아래와 같은 화면을 확인하실 수 있습니다.
                    <br />
                    <br />
                    <div>
                      * 잔여 구매 기회 : 사용자는 OLEA MARKET에서 24시간에 1회의
                      구매 기회를 가질 수 있습니다.
                      <br />
                      * 타이머 : OLEA COIN의 시세 변동에 따라 충전 시 필요한
                      OLEA의 개수가 변할 수 있습니다.
                      <br />* 지갑 : 충전된 POINT와 구매 시 사용 가능한 POINT를
                      확인하실 수 있습니다.
                      <div className="sub" style={{ paddingLeft: "40px" }}>
                        ※ 상품 구매 시 보유한 POINT의 최대 10%까지 사용
                        가능합니다.
                      </div>
                    </div>
                  </div>
                  <img src={OleaToPoint6} alt="OleaToPoint6" width={800} />
                </li>

                <li>
                  <div>
                    하단의 물품 리스트에서 각 상품의 [담기] 버튼을 통해 구매를
                    희망하는 상품들을 구매 상품 리스트에 담아 줍니다.
                    <br />
                    잔여 구매 기회는 1회이지만, 한 번 구매 시 여러 상품을
                    구매하실 수 있습니다.
                  </div>

                  <img src={OleaToPoint7} alt="OleaToPoint7" width={800} />
                </li>

                <li>
                  <div>
                    구매 상품 리스트에 구매를 희망하는 상품들을 모두 담으신 후,
                    [주문하기] 버튼을 통해 해당 상품들을 주문하실 수 있습니다.
                  </div>

                  <img src={OleaToPoint8} alt="OleaToPoint8" width={600} />
                </li>

                <li>
                  <div>
                    주문 완료 시 가입하신 이메일로 해당 상품들의 기프티콘이
                    발송됩니다.
                  </div>

                  <img src={OleaToPoint9} alt="OleaToPoint9" width={300} />
                </li>
              </ul>
            </div>
          </Collapse>
        </section>

        <div className="gray-line" />
      </div>
    </div>
  );
};

export default HowToUse;
