import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";

import "./HomeCarousel.scss";
import {
  HomeBrandCafeArr,
  HomeBrandFoodArr,
  HomeBrandCouponArr,
} from "../../../../shared/HomeDataArr";

const HomeCarousel = () => {
  const swiperArr = [HomeBrandCafeArr, HomeBrandFoodArr, HomeBrandCouponArr];
  return (
    <>
      {swiperArr.map((data, idx) => (
        <div className="main_carousel_wrapper mySwiper" key={`swiper${idx}`}>
          <Swiper
            slidesPerView={6}
            spaceBetween={30}
            centeredSlides={true}
            scrollbar={{ draggable: true }}
            loop={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
              reverseDirection: idx === 1 && true,
            }}
            speed={idx === 0 ? 3000 : idx === 1 ? 4400 : idx === 2 && 3700}
            modules={[Autoplay, Navigation]}
          >
            {data.map((cur, idx) => {
              return (
                <SwiperSlide className="main_carousel_slider" key={idx}>
                  <img src={cur.src} alt={`${idx}번째 브랜드 이미지`} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ))}
    </>
  );
};

export default HomeCarousel;
