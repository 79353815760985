import React, { useEffect } from "react";
import NoticeIcon from "../../assets/icons/icon_blur1.png";
import { useDispatch } from "react-redux";
import Portal from "../../shared/Portal";

import "./Modal.scss";

export default function Modal({ data, modalPayload }) {
  useEffect(() => {
    const $activePop = document.querySelector(".pop_layer.main.active");
    if ($activePop) {
      document.body.style.overflow = "hidden";
    }
    return () => (document.body.style.overflow = "inherit");
  }, [data]);
  const {
    header = "Title",
    content = <p>content</p>,
    ico = false,
    size = "sm",
    leftBtn = false,
    rightBtn = true,
    closeBtn = true,
    btnLeftText = "취소",
    btnRightText = "확인",
    btnType = "default",
    sort = "",
    leftBtnHandler = () => {},
    rightBtnHandler = () => {},
    closeBtnHandler = () => {},
    dimBtnHandler = () => {},
    ldcBtnHandler = () => {},
    rdcBtnHandler = () => {},
  } = data;
  const dispatch = useDispatch();

  return (
    <Portal>
      <div className="old-wrap">
        <div className="pop_layer main active" id="pop1">
          <div
            className="dim"
            onClick={(e) => {
              if (!(e.target === e.currentTarget)) return;
              dimBtnHandler({ modalPayload });
              rdcBtnHandler({ modalPayload });
              ldcBtnHandler({ modalPayload });
            }}
          ></div>
          <div className={`in_pop main ${size} active`}>
            <h3 className="tit">{header}</h3>

            <div className={`in ${sort}`}>
              {ico && (
                <div className="ico">
                  <img src={NoticeIcon} alt="" />
                </div>
              )}
              {typeof content === "function"
                ? content({ modalPayload })
                : content}
              <div className="btn_area main center">
                {leftBtn && (
                  <a
                    href="#;"
                    className={`btn lg default close`}
                    onClick={(e) => {
                      e.preventDefault();
                      leftBtnHandler({ modalPayload });
                      ldcBtnHandler({ modalPayload });
                    }}
                  >
                    {typeof btnLeftText === "function"
                      ? btnRightText({ modalPayload })
                      : btnLeftText}
                  </a>
                )}
                {rightBtn && (
                  <a
                    href="#;"
                    className="btn lg point"
                    onClick={(e) => {
                      e.preventDefault();
                      rightBtnHandler({ modalPayload });
                      rdcBtnHandler({ modalPayload });
                    }}
                  >
                    {typeof btnRightText === "function"
                      ? btnRightText({ modalPayload })
                      : btnRightText}
                  </a>
                )}
              </div>
            </div>
            {closeBtn && (
              <button
                className="close"
                onClick={() => {
                  closeBtnHandler({ modalPayload });
                  rdcBtnHandler({ modalPayload });
                  ldcBtnHandler({ modalPayload });
                }}
              >
                닫기
              </button>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
}
