import { useState } from "react";

import Modal from "../../modal/Modal";

import ARROW_RIGHT from "../../../assets/icons/rightArrow.svg";
import "./MarketBuyTicket.scss";
import ROULETTE_BTN from "../../../assets/images/roulette_button.png";
import HolderBuyGameMoneyComponent from "./Holder/template/HolderBuyGameMoneyComponent";
import ClubBuyGameMoneyComponent from "./Club/template/ClubBuyGameMoneyComponent";
import JoyBuyGameMoneyComponent from "../joy/template/JoyBuyGameMoneyComponent";

const MarketBuyGameMoney = ({ states }) => {
  const { pageType } = states;
  const [modalOpen, setModalOpen] = useState(0);
  const gameMoneyPrice = 100;

  const modalData = [
    {
      header: "Game Money 구매",
      size: "sm",
      sort: "center",
      content: (
        <>
          <p>JOY 탭에서 룰렛게임을 진행할 수 있는 게임머니입니다.</p>
          <br />
          <p>1 게임머니는 1 OLEA 입니다.</p>
          <br />
          <p>100게임머니 단위로 구매할 수 있습니다.</p>
          <br />
          {pageType === "HOLDER" ? (
            <HolderBuyGameMoneyComponent
              states={{ modalOpen, setModalOpen, gameMoneyPrice }}
            />
          ) : pageType === "CLUB" ? (
            <ClubBuyGameMoneyComponent
              states={{ modalOpen, setModalOpen, gameMoneyPrice }}
            />
          ) : pageType === "JOY" ? (
            <JoyBuyGameMoneyComponent
              states={{ modalOpen, setModalOpen, gameMoneyPrice }}
            />
          ) : null}
        </>
      ),
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    {
      header: "게임머니 구매 완료",
      size: "sm",
      sort: "center",
      content: (
        <>
          <p>게임머니 구매가 정상적으로 처리되었습니다.</p>
        </>
      ),
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    {
      header: "게임머니 구매 실패",
      size: "sm",
      sort: "center",
      content: (
        <>
          <p>게임머니 구매가 비정상적으로 처리되었습니다.</p>
          <br />
          <p>잔여 OLEA를 확인 후 다시 시도해주세요.</p>
        </>
      ),
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
  ];

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <div
        // className="market_buy_ticket_wrapper violet_bg"
        className={
          pageType === "HOLDER" || pageType === "CLUB"
            ? "market_buy_ticket_wrapper violet_bg"
            : "joy_buy_game_money_wrapper brown_bg"
        }
        onClick={() => {
          setModalOpen(1);
        }}
      >
        <span>
          게임머니 구매하기
          {pageType === "JOY" ? null : (
            <img
              src={ARROW_RIGHT}
              alt="right arrow"
              style={{ filter: "brightness(0) invert(1)" }}
            />
          )}
        </span>
        <img src={ROULETTE_BTN} alt="GAME MONEY" style={{ width: "50px" }} />
      </div>
    </>
  );
};

export default MarketBuyGameMoney;
