import { useDispatch } from "react-redux";
import "./SearchInput.scss";
import React, { useState } from "react";
import {
  getClaimRequestList,
  getPointExchangeRequestList,
  getPurchaseList,
} from "../../../../../redux/modules/admin";

const SearchInput = ({ typeList, origin, setOrigin }) => {
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState(typeList[0].key || "");
  const [enteredWord, setEnteredWord] = useState("");

  const searchHandler = () => {
    const newOrigin = origin.filter((req) =>
      String(req[selectedType]).includes(enteredWord.trim())
    );

    if (!newOrigin.length) {
      alert("일치하는 정보가 없습니다.");
      return;
    }

    setOrigin(newOrigin);
  };

  return (
    <div className="SearchInput">
      {typeList && (
        <select
          name="typeList"
          id="typeList"
          onChange={(e) =>
            setSelectedType(typeList[e.target.selectedIndex].key)
          }
        >
          {typeList.map((type) => (
            <option key={type.key} value={type.value}>
              {type.value}
            </option>
          ))}
        </select>
      )}

      <input
        type="text"
        placeholder="Search..."
        onChange={(e) => setEnteredWord(e.target.value)}
        onKeyDown={(e) => {
          if (e.nativeEvent.isComposing || e.keyCode === 229) return;
          e.key === "Enter" && searchHandler();
        }}
      />

      <div className="btn_area">
        <button className="point" onClick={searchHandler}>
          검색
        </button>

        <button
          className="default"
          onClick={() => {
            dispatch(getPointExchangeRequestList());
            dispatch(getClaimRequestList("OLEA"));
            dispatch(getClaimRequestList("POINT"));
            dispatch(getPurchaseList());
          }}
        >
          초기화
        </button>
      </div>
    </div>
  );
};

export default SearchInput;
