import { useDispatch, useSelector } from "react-redux";
import "./Point.scss";
import React, { useEffect, useState } from "react";
import numberWithCommas from "../../../../../utils/numbersWithComas";
import {
  getPointExchangeRequestList,
  patchCancelStatus,
  patchExchangePoint,
  setAdminState,
} from "../../../../../redux/modules/admin";
import MuiPagination from "../../../../muiPagination/MuiPagination";
import { dateSet2 } from "../../../../../utils/dateSet";
import Modal from "../../../../modal/Modal";
import { modalData } from "../../modlaData";
import SearchInput from "../SearchInput/SearchInput";
import { currentPosts } from "../../../../../utils/paginationCurrentPosts";
import { CiMemoPad } from "react-icons/ci";

const Point = () => {
  const dispatch = useDispatch();
  const pointRequestList = useSelector((state) => state.admin.pointRequestList);

  const [renderArr, setRenderArr] = useState(pointRequestList || []);
  const [postsPerPage] = useState(10);
  const [postsCurPage, setPostsCurPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(0);
  const [deleteTarget, setDeleteTarget] = useState();

  const [clickedReqOrigin, setClickedReqOrigin] = useState({});
  const [clickedReq, setClickedReq] = useState({});
  const [isMemoEdit, setIsMemoEdit] = useState(false);
  const [memoType, setMemoType] = useState("notice");

  const editedMemoHandler = (target) => {
    dispatch(
      setAdminState({
        pointRequestList: pointRequestList.map((req) => {
          if (req.id === target.id) {
            return target;
          }
          return req;
        }),
      })
    );
  };

  const modalPayload = {
    dispatch,
    setModalOpen,
    pointRequestList,

    clickedReqOrigin,

    clickedReq,
    setClickedReq,

    isMemoEdit,
    setIsMemoEdit,
    memoType,
    setMemoType,

    editedMemoHandler,

    deleteTarget,
    pageType: "Point",
  };

  const theadArr = [
    { key: "email", value: "이메일" },
    { key: "point", value: "신청한 POINT" },
    { key: "oleaForExchange", value: "전송받을 OLEA COIN" },
    { key: "marketPrice", value: "OLEA COIN 시세" },
    { key: "hashValue", value: "트랜잭션 해시" },
    { key: "status", value: "상태" },
    { key: "createdAt", value: "신청일" },
  ];

  useEffect(() => {
    dispatch(getPointExchangeRequestList());
  }, [dispatch]);

  const paginationConvert = (arr) => {
    dispatch(setAdminState({ pointRequestList: arr }));
  };

  useEffect(() => {
    setRenderArr(currentPosts(pointRequestList, postsCurPage, postsPerPage));
  }, [pointRequestList, postsCurPage, postsPerPage]);

  useEffect(() => {
    setPostsCurPage(1);
  }, [pointRequestList.length]);

  return (
    <>
      {modalOpen !== 0 && (
        <Modal data={modalData[modalOpen]} modalPayload={modalPayload} />
      )}

      <div className="Point">
        <div className="title_area">
          <h2>POINT 관리</h2>

          <SearchInput
            typeList={theadArr}
            origin={pointRequestList}
            setOrigin={paginationConvert}
          />
        </div>

        <div className="list_area">
          <table>
            <thead>
              <tr>
                {theadArr.map((th) => (
                  <th key={th.key}>{th.value}</th>
                ))}
                <th></th>
              </tr>
            </thead>

            <tbody>
              {renderArr.map((req, idx) => (
                <tr key={req.hashValue + idx}>
                  <td>{req.email}</td>

                  <td>{numberWithCommas(req.point, "POINT")}</td>

                  <td>{numberWithCommas(req.oleaForExchange, "OLEA")}</td>

                  <td>{numberWithCommas(req.marketPrice, "KRW")}</td>

                  <td>
                    <a
                      href={`https://goerli.etherscan.io/tx/${req.hashValue}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {req.hashValue}
                    </a>
                  </td>

                  <td>
                    <span
                      className={`${req.status === "취소" ? "cancel" : ""}
                        ${req.status === "완료" ? "done" : ""}`}
                    >
                      {req.status}
                    </span>
                  </td>

                  <td>{dateSet2(req.createdAt)}</td>

                  <td>
                    <div className="btn_area">
                      {req.status !== "취소" && (
                        <button
                          className="default"
                          onClick={() => dispatch(patchCancelStatus(req))}
                        >
                          취소
                        </button>
                      )}
                      {req.status === "전송" && (
                        <button
                          className="point"
                          onClick={() =>
                            dispatch(patchExchangePoint({ id: req.id }))
                          }
                        >
                          지급
                        </button>
                      )}
                      <CiMemoPad
                        onClick={() => {
                          setClickedReq(req);
                          setClickedReqOrigin(req);
                          setModalOpen("POINT_MEMO");
                        }}
                      />

                      <div
                        className="deleteBtn"
                        onClick={() => {
                          setModalOpen("DELETE_CHECK");
                          setDeleteTarget(req.id);
                        }}
                      >
                        &times;
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {renderArr && (
            <div className="pagination">
              <MuiPagination
                count={Math.ceil(pointRequestList.length / postsPerPage)}
                cur={postsCurPage}
                setCur={setPostsCurPage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Point;
