import "./Header.scss";
import React, { useEffect } from "react";
import logo from "../../../../../assets/icons/olea_symbol.png";
import { userSignOutSagaActionCreator } from "../../../../../redux/modules/user";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { getOLEAPrice } from "../../../../../redux/modules/admin";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const oleaPrice = useSelector((state) => state.admin.olea.price);

  useEffect(() => {
    dispatch(getOLEAPrice());
  }, [location.pathname]);

  const navArr = [
    {
      name: "POINT 관리",
      path: "/point",
    },
    {
      name: "출금 신청 관리",
      path: "/claim",
    },
    {
      name: "사용자 구매 내역",
      path: "/purchase",
    },
  ];

  return (
    <div className="Header">
      <div className="gnb">
        <div
          className="logo"
          onClick={() => {
            dispatch(push("/"));
          }}
        >
          <img src={logo} width={35} height={35} alt="OLEA MARKET ADMIN" />
          OLEA MARKET ADMIN
        </div>

        {navArr.map((nav) => (
          <div
            className="gnb_item"
            key={nav.name}
            onClick={() => {
              dispatch(push(nav.path));
            }}
          >
            {nav.name}
          </div>
        ))}

        <strong>
          <a
            href="https://coinmarketcap.com/ko/currencies/olea-token/"
            target="_blank"
            rel="noopener noreferrer"
          >
            현재 OLEA COIN 시세: {oleaPrice}KRW
          </a>
        </strong>
      </div>

      <div
        className="logout"
        onClick={() => dispatch(userSignOutSagaActionCreator())}
      >
        로그아웃
      </div>
    </div>
  );
};

export default Header;
