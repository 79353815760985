import SubHeader from "../../header/template/SubHeader";
import MyWalletSearchSection from "./template/MyWalletSearchSection";

import MyWalletCardSection from "./template/MyWalletCardSection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  set,
  userGetMetamaskInfoSagaActionCreator,
} from "../../../redux/modules/user";

const MyWallet = () => {
  const dispatch = useDispatch();
  const addressState = useSelector((state) => state.user.userInfo?.address);

  useEffect(() => {
    dispatch(userGetMetamaskInfoSagaActionCreator());

    return () => {
      dispatch(set({ userMetaMaskInfo: [] }));
    };
  }, [addressState]);

  return (
    <>
      <SubHeader
        states={{
          title: "MY WALLET",
          type: "MY_WALLET",
        }}
      />
      <MyWalletSearchSection />
      <MyWalletCardSection />
    </>
  );
};

export default MyWallet;
