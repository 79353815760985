import Minus from "../../../../../assets/icons/minus.svg";
import MARKETCART from "../../../../../assets/icons/marketCart.svg";

import "../../MarketLeftContainer.scss";
import { useEffect, useRef } from "react";
import numberWithCommas from "../../../../../utils/numbersWithComas";
import { useDispatch, useSelector } from "react-redux";
import { postTokenMarketGoodsPayment } from "../../../../../redux/modules/market";

const MarketLeftContainer = ({ states }) => {
  const dispatch = useDispatch();
  const $container = useRef(null);
  const OLEA_Point_Info = useSelector((state) => state.market.OLEA_Point_Info);
  const {
    myShoppingCart,
    setMyShoppingCart,
    handlePopoverOpen,
    handlePopoverClose,
    totalCheck,
    totalPrice,
  } = states;
  //1554300
  const paymentHandler = () => {
    if (myShoppingCart.length === 0)
      return alert("구매를 희망하는 상품을 추가해 주세요.");

    if (totalPrice > OLEA_Point_Info.point / 10)
      return alert(
        "구매 시 사용 가능 POINT가 부족합니다.\n기프티콘 상품 구매 시, 보유한 POINT의 최대 10%까지 사용 가능합니다."
      );

    const order = Object.values(
      myShoppingCart.reduce((acc, item) => {
        if (!acc[item.goodsId]) {
          acc[item.goodsId] = { goods_id: item.goodsId, quantity: 0 };
        }
        acc[item.goodsId].quantity++;
        return acc;
      }, {})
    );

    setMyShoppingCart([]);
    dispatch(
      postTokenMarketGoodsPayment({
        totalPoint: totalPrice,
        order,
      })
    );
  };

  return (
    <div className="olea_market_left_container_wrapper" ref={$container}>
      <img src={MARKETCART} alt="MARKETCART" />
      <div className="market_left_container_header">
        <span>상품명</span>
        <span>가격</span>
        <span>빼기</span>
      </div>
      <ul className="market_left_container_shopping_cart_container">
        {myShoppingCart.map((data) => (
          <li key={data.uniId}>
            <span
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              {data.goodsName}
            </span>
            <span>{numberWithCommas(data.goodsPointPrice)} POINT</span>
            <span>
              <button
                className="market_left_container_minus_btn"
                onClick={() => {
                  const filter = myShoppingCart.filter(
                    (item) => item.uniId !== data.uniId
                  );
                  setMyShoppingCart(filter);
                }}
              >
                <img src={Minus} alt="Minus" />
              </button>
            </span>
          </li>
        ))}
      </ul>
      <div className="market_left_container_shopping_cart_result_container olea_market_left_container_btn_box">
        <span>TOTAL</span>
        <span className={totalCheck ? "none" : null}>
          {numberWithCommas(totalPrice)}
        </span>
        <span style={{ fontWeight: 500, fontSize: "20px", color: "white" }}>
          &nbsp; POINT
        </span>
        <button onClick={() => paymentHandler()}>주문하기</button>
      </div>
    </div>
  );
};

export default MarketLeftContainer;
