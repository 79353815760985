import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { tierTypeConvert } from "../../../../shared/NftData";
import MuiPagination from "../../../muiPagination/MuiPagination";
import { currentPosts } from "../../../muiPagination/currentPost";
import randomString from "../../../../utils/randomString";
import "./NftInfo.scss";

const NftInfo = ({ states }) => {
  const { imageUrl, tierType, edition } = states;
  const tradeLogArr = useSelector((state) => state.user.tradeLog);
  const [curPage, setCurPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);
  const [renderData, setRenderData] = useState([]);

  useEffect(() => {
    setRenderData(currentPosts(tradeLogArr, curPage, postsPerPage));
  }, [curPage, postsPerPage, tradeLogArr]);

  const bigSizeImageUrl = imageUrl.split(
    "https://d2als48iiabdbp.cloudfront.net/"
  );

  const clickNftImageHandler = () => {
    window.open(
      `https://storage.googleapis.com/athenareturns/${bigSizeImageUrl[1]}`,
      "_blank"
    );
  };

  return (
    <div className="nft_info_wrapper">
      <div className="nft_info_nft_box">
        <img
          src={imageUrl}
          alt={`${imageUrl}`}
          onClick={clickNftImageHandler}
        />
        <div className="nft_info_nft_info_box">
          <span className="nft_info_box_text">
            {tierTypeConvert(tierType)}
            <span className="nft_info_box_text_edition">#{edition}</span>
          </span>
        </div>
      </div>
      <div className="nft_info_log_box">
        <div className="nft_info_tradelog_wrapper">
          <div className="nft_info_tradelog_header">
            {[
              "구매 날짜",
              "내용",
              "사용 OLEA COIN",
              "사용 이벤트 OLEA COIN",
            ].map((data) => (
              <span key={data}>{data}</span>
            ))}
          </div>

          <div className="nft_info_tradelog_body">
            {renderData.map((data) => (
              <div key={randomString()}>
                <span>{data.createdAt}</span>
                <span>{data.goodsName}</span>
                <span>{data.tradedCoin}</span>
                <span className="nft_info_content">{data.tradedEventCoin}</span>
              </div>
            ))}
          </div>
        </div>

        <MuiPagination
          count={Math.ceil(tradeLogArr?.length / postsPerPage)}
          cur={curPage}
          setCur={setCurPage}
        />
      </div>
    </div>
  );
};

export default NftInfo;
