import { takeEvery, put, call, select } from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import { loadingOff, loadingOn } from "./loding";
import AuthService from "../../service/authService";
import TokenService from "../../service/token";
import { userSetStateActionCreator } from "./user";
import SignUpComplete from "../../components/pages/signUp/SignUpComplete";

const prefix = "olea/auth";

// action type
const actionType = {};

const actionTypeArray = ["START", "SUCCESS", "FAIL", "SET"];

actionTypeArray.forEach((data) => {
  actionType[data] = `${prefix}/${data}`;
});

const { START, SUCCESS, FAIL, SET } = actionType;

export const actionCreator = (type, data) => ({
  type,
  data,
});

// action creator
const start = () => actionCreator(START);
const success = (data) => actionCreator(SUCCESS, data);
const fail = (error) => actionCreator(FAIL, error);
export const setAuthState = (data) => actionCreator(SET, data);

// initial state
const initialState = {
  error: null,
  userType: "",
};

// reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START:
      return {
        ...state,
        error: null,
      };
    case SUCCESS:
      return {
        ...state,
        ...action.data,
        error: null,
      };
    case FAIL:
      return {
        ...state,
        error: action.data.error,
      };

    case SET:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
}

// saga action
const sagaActionType = {};

const sagaActionTypeArr = [
  "POST_SIGNUP_USER",
  "POST_LOGIN_USER",
  "PATCH_EMAIL_AUTH_CHECK",
  "GET_RESEND_AUTH_MAIL",
  "GET_RESET_PASSWORD",
];

sagaActionTypeArr.forEach((data) => {
  sagaActionType[data] = `${prefix}/${data}`;
});

const {
  POST_SIGNUP_USER,
  POST_LOGIN_USER,
  PATCH_EMAIL_AUTH_CHECK,
  GET_RESEND_AUTH_MAIL,
  GET_RESET_PASSWORD,
} = sagaActionType;

// saga action creator

export const postSignUpUser = (data) => actionCreator(POST_SIGNUP_USER, data);
export const postLoginUser = (data) => actionCreator(POST_LOGIN_USER, data);
export const patchEmailAuthCheck = (data) =>
  actionCreator(PATCH_EMAIL_AUTH_CHECK, data);

export const getResendAuthMail = (data) =>
  actionCreator(GET_RESEND_AUTH_MAIL, data);

export const getResetPassword = (data) =>
  actionCreator(GET_RESET_PASSWORD, data);

// saga reducer
function* sagaReducer(action) {
  const type = action.type;
  switch (type) {
    // 일반 유저 회원가입
    case POST_SIGNUP_USER:
      try {
        const res = yield call(AuthService.postSignUpUser, action.data);

        if (res.code === 201) {
          // const state = yield select();

          // yield put(
          //   userSetStateActionCreator({
          //     routeMap: [
          //       ...state.user.routeMap,
          //       { path: "/auth/email", Component: SignUpComplete },
          //     ],

          //     userId: action.data.email,
          //   })
          // );

          // yield put(push("/auth/email"));

          alert("회원가입이 완료되었습니다.\n가입된 계정으로 로그인해 주세요.");
          yield put(push("/sign-in"));
        }
      } catch (e) {
        const err = { ...e.response.data.error };

        if (err.code === 409) {
          alert("이미 사용 중인 이메일입니다.");
        }

        if (err.code === 422) {
          alert("디스코드로 가입된 이메일입니다.\n디스코드로 로그인해 주세요.");
          yield put(push("/sign-in"));
        }
      }
      break;

    // 일반 유저 로그인
    case POST_LOGIN_USER:
      try {
        const res = yield call(AuthService.postLoginUser, {
          email: action.data.email,
          password: action.data.password,
        });

        if (res.code === 200) {
          TokenService.save(res.data.accessToken, res.data.refreshToken);
          yield put(success({ token: res.data }));
          yield put(push("/"));
        }
      } catch (e) {
        const err = { ...e.response.data.error };
        if (err.code === 403) {
          action.data.setAuthModalOpen(true);
        }

        if (err.code === 404 || err.code === 401) {
          alert("이메일 혹은 비밀번호를 확인해 주세요.");
        }

        if (err.code === 409) {
          alert("디스코드로 가입된 이메일입니다.\n디스코드로 로그인해 주세요.");
          yield put(push("/sign-in"));
        }
      }
      break;

    // 이메일 인증
    case PATCH_EMAIL_AUTH_CHECK:
      try {
        const res = yield call(AuthService.patchEmailAuthCheck, action.data);

        yield put(
          success({
            emailAuthCheckCode: res.code,
          })
        );
      } catch (e) {
        const err = { ...e.response.data.error };

        if (err.code === 409) {
          alert("이미 인증이 완료된 회원입니다.");
          yield put(push("/"));
          return;
        }

        if (err.code === 410) {
          alert(
            "인증 메일의 유효기간이 만료되었습니다. \n 로그인 후 인증 메일을 재전송해 주세요."
          );
          yield put(push("/"));
          return;
        }

        alert("올바르지 않은 접근입니다.");
        yield put(push("/"));
      }
      break;

    // 이메일 인증 메일 재전송
    case GET_RESEND_AUTH_MAIL:
      try {
        const res = yield call(
          AuthService.getResendAuthMail,
          action.data.email
        );

        alert(
          "인증 메일이 재전송되었습니다.\n회원가입 당시 사용한 메일을 확인해 주세요."
        );
      } catch (e) {
        const err = { ...e.response.data.error };

        if (err.code === 409) {
          alert("디스코드로 가입된 이메일입니다.\n디스코드로 로그인해 주세요.");
          yield put(push("/sign-in"));
        }
      }
      break;

    // 이메일 인증 메일 재전송
    case GET_RESET_PASSWORD:
      try {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(action.data.emailCheck)) {
          return alert("이메일 형식을 입력해 주세요.");
        }

        const res = yield call(
          AuthService.getResetPassword,
          action.data.emailCheck
        );

        alert(
          "해당 이메일로 재설정된 비밀번호가 발송되었습니다.\n재설정된 비밀번호로 로그인해 주세요."
        );
        action.data.setPasswordModalOpen(false);
      } catch (e) {
        const err = { ...e.response.data.error };

        if (err.code === 404) {
          alert("이메일을 확인해 주세요.");
        }
      }
      break;

    default:
      break;
  }
}

// export saga
export function* authSaga() {
  yield takeEvery(POST_SIGNUP_USER, sagaReducer);
  yield takeEvery(POST_LOGIN_USER, sagaReducer);
  yield takeEvery(PATCH_EMAIL_AUTH_CHECK, sagaReducer);
  yield takeEvery(GET_RESEND_AUTH_MAIL, sagaReducer);
  yield takeEvery(GET_RESET_PASSWORD, sagaReducer);
}
