export const modalData = {
  POP_UP_CALLIOPE_INFO: {
    header: "Calliope 민팅",
    size: "sm",
    sort: "center",
    content: <div className="calliope_popup"></div>,
    btnType: "point",
    btnRightText: "이동하기",
    rightBtnHandler: ({ modalPayload }) => {
      modalPayload.dispatch(modalPayload.push("/public_mint"));
    },
    closeBtnHandler: ({ modalPayload }) => {
      modalPayload.setModalOpen(0);
    },
    dimBtnHandler: ({ modalPayload }) => {
      modalPayload.setModalOpen(0);
    },
  },
  ALERT_MAIN: {
    header: "안내",
    size: "sm",
    sort: "center",
    content: (
      <p>
        <br />
        기프티콘 업체 변경으로 인하여 일시적으로 마켓 이용이 제한됩니다.
        <br />
        <br />
        감사합니다
        <br />
        <br />
      </p>
    ),
    btnType: "point",
    btnRightText: "닫기",
    rdcBtnHandler: ({ modalPayload }) => {
      modalPayload.setModalOpen(0);
    },
  },
};
