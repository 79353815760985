// import icons
import { useState } from "react";
import Modal from "../../../modal/Modal";
import Holders from "../../../../assets/icons/holders_icon.svg";
import ShipIcon from "../../../../assets/icons/opensea-logo-white.png";
import PalaIcon from "../../../../assets/icons/pala_logo_tp.png";
import LookupNFT from "./LookupNft";

import "./HomeHolder.scss";

const HomeHolder = () => {
  const [modalOpen, setModalOpen] = useState(0);
  const modalData = [
    {
      header: "NFT OLEA 잔여량 조회",
      size: "sm",
      sort: "center",
      content: <LookupNFT />,
      btnType: "point",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
  ];

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <div className="holders_wrapper">
        <img src={Holders} alt="Holders이미지" />

        <p className="holders_text">
          아테나리턴즈 NFT 홀더가 되어 올레아 마켓을 이용해보세요
        </p>

        <div className="holders_btn_box">
          <button
            className="holders_btn"
            onClick={() => {
              setModalOpen(1);
            }}
          >
            <span className="holders_btn_text">NFT OLEA 잔여량 조회하기</span>
          </button>

          <button
            className="holders_btn"
            onClick={() => {
              window.open(
                "https://opensea.io/collection/athena-returns-a",
                "_blank"
              );
            }}
          >
            <img src={ShipIcon} alt="배모양아이콘" />
            <div className="holder_textbox">
              <span className="holders_btn_text">
                오픈씨에서 A티어 홀더되기
              </span>
            </div>
          </button>

          <button
            className="holders_btn"
            onClick={() => {
              window.open(
                "https://opensea.io/collection/athena-returns-b",
                "_blank"
              );
            }}
          >
            <img src={ShipIcon} alt="배모양아이콘" />
            <div className="holder_textbox">
              <span className="holders_btn_text">
                오픈씨에서 B티어 홀더되기
              </span>
            </div>
          </button>

          {/* <button
            className="holders_btn"
            onClick={() => {
              window.open(
                "https://pala.io/ethereum/collection/a67d0c68ddac0a7a61f919f8fc1dcb6d091a1ccb",
                "_blank"
              );
            }}
          >
            <img src={PalaIcon} alt="Pala" />
            <div className="holder_textbox">
              <span className="holders_btn_text">Pala에서 홀더되기</span>
            </div>
          </button> */}
        </div>
      </div>
    </>
  );
};

export default HomeHolder;
