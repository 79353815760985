import { DISCORD_AUTH_URL } from "../../../auth/OAuth2";
import "./SignInV2.scss";
import React, { useState } from "react";
import DISCORD_SYMBOL from "../../../assets/icons/discordSymbol.svg";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import {
  getResendAuthMail,
  getResetPassword,
  postLoginUser,
} from "../../../redux/modules/auth";
import Modal from "../../modal/Modal";

const SignInV2 = () => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [emailCheck, setEmailCheck] = useState("");

  const loginHandler = (e) => {
    e.preventDefault();
    dispatch(postLoginUser({ email: userId, password, setAuthModalOpen }));
  };

  return (
    <>
      {authModalOpen && (
        <Modal
          data={{
            header: "이메일 인증 안내",
            size: "sm",
            sort: "center",
            content: (
              <p>
                이메일 인증 이후 로그인이 가능합니다.
                <br />
                <br />
                메일을 확인 후 다시 시도해 주세요.
                <br />
                <br />
                인증 메일을 받지 못하신 경우 재발송 버튼을 클릭해 주세요.
              </p>
            ),
            btnType: "point",
            ico: false,
            leftBtn: true,
            btnLeftText: "닫기",
            btnRightText: "재발송",
            ldcBtnHandler: () => {
              setAuthModalOpen(false);
            },
            rightBtnHandler: () => {
              dispatch(getResendAuthMail({ email: userId }));
            },
          }}
        />
      )}

      {passwordModalOpen && (
        <Modal
          data={{
            header: "비밀번호 재설정",
            size: "sm",
            sort: "center",
            content: (
              <div>
                비밀번호를 재설정 할 이메일을 입력해 주세요.
                <br />
                <br />
                <div className="divCenter">
                  <label htmlFor="username">이메일</label>

                  <input
                    type="text"
                    value={emailCheck}
                    placeholder="이메일 입력"
                    onChange={(e) => setEmailCheck(e.target.value)}
                  />
                </div>
              </div>
            ),
            btnType: "point",
            ico: false,
            leftBtn: true,
            btnLeftText: "닫기",
            btnRightText: "재설정",
            ldcBtnHandler: () => {
              setPasswordModalOpen(false);
            },
            rightBtnHandler: () => {
              dispatch(getResetPassword({ emailCheck, setPasswordModalOpen }));
            },
          }}
        />
      )}

      <div className="SignInV2">
        <h1>로그인</h1>

        <div className="signIn_area">
          <h2>OLEA MARKET 이용을 위해 로그인해 주세요</h2>

          <div className="gray-line" />

          <form className="form_area" onSubmit={loginHandler}>
            <div className="input_area">
              <label htmlFor="username">이메일</label>
              <input
                type="text"
                id="username"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                required
                placeholder="이메일 입력"
              />
            </div>

            <div className="input_area">
              <label htmlFor="password">비밀번호</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="비밀번호 입력"
              />
            </div>

            <div className="input_area">
              <button type="submit">로그인</button>
            </div>
          </form>

          <ul className="ul_area">
            {/* 
              배포 후 추가 예정
            <li onClick={() => setPasswordModalOpen(true)}>비밀번호 찾기</li> */}
            <li onClick={() => dispatch(push("/sign-up"))}>회원가입</li>
          </ul>

          <div className="btn_area">
            <button
              className="discord"
              onClick={() => {
                window.location.href = DISCORD_AUTH_URL;
              }}
            >
              <img
                src={DISCORD_SYMBOL}
                alt="DISCORD SYMBOL"
                width={30}
                height={21}
              />
              <span>LOGIN FOR DISCORD</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInV2;
