import { push } from "connected-react-router";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FOXICON from "../../../assets/icons/MetaMask_Fox 1.png";
import KLIPLOGO from "../../../assets/icons/klip_logo.png";
import {
  userGetKlaytnAddressSagaActionCreator,
  userGetMetamaskAddressSagaActionCreator,
  userPutRefreshNftSagaActionCreator,
  userPutRegisterSmartWalletAddressSagaActionCreator,
} from "../../../redux/modules/user";
import Modal from "../../modal/Modal";
import { QRCodeSVG } from "qrcode.react";

import "./ConnectWallet.scss";
import { logoConvert } from "../../../utils/imageConvert";

const ConnectWallet = () => {
  const dispatch = useDispatch();
  const addressState = useSelector((state) => state.user.userInfo?.address);
  const klipQRLinkState = useSelector((state) => state.user.klipQRLink);
  const account = useSelector((state) => state.user.registerPayload?.address);
  const walletTypeState = useSelector(
    (state) => state.user.userInfo.walletType
  );

  const [isCheck, setisCheck] = useState(false);
  const [modalOpen, setModalOpen] = useState(0);

  const modalData = [
    {
      //! setModalOpen : 1
      header: "스마트 월렛 계정 연결 완료",
      size: "sm",
      sort: "center",
      content: (
        <p>
          스마트 월렛 계정 연결이 완료되었습니다.
          <br />
          <br />
          연결된 스마트 월렛 계정 : {addressState}
          <br />
          확인 버튼을 클릭하시면 My Wallet 페이지로 이동합니다.
        </p>
      ),
      btnType: "point",
      btnRightText: "확인",
      leftBtn: true,
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
      rightBtnHandler: () => {
        dispatch(push("/my-wallet"));
      },
    },
    {
      //! setModalOpen : 2
      header: "메타마스크 설치 안내",
      size: "sm",
      sort: "center",
      content: (
        <p>
          메타 마스크가 설치되어 있지 않습니다.
          <br />
          설치 페이지로 이동하시려면 확인을 클릭해 주세요.
          <br />
          설치 완료 후 새로고침을 해주세요.
        </p>
      ),
      btnType: "point",
      leftBtn: true,
      btnRightText: "확인",
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
      rightBtnHandler: () => {
        window.open("https://metamask.io/download.html");
        window.location.replace("/connect-wallet");
      },
    },
    {
      //! setModalOpen : 3
      header: "메타마스크 오류",
      size: "sm",
      sort: "center",
      content: (
        <p>
          메타마스크 연동 과정 중 크롬 익스텐션을 종료하셨습니다.
          <br />
          메타마스크 크롬 익스텐션을 다시 켠 후 연동을 진행해 주세요.
        </p>
      ),
      btnType: "point",
      btnRightText: "확인",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    {
      //! setModalOpen : 4
      header: "메타마스크 오류",
      size: "sm",
      sort: "center",
      content: (
        <p>
          메타마스크 연동 과정이 비정상적으로 처리되었습니다.
          <br />
          메타마스크 크롬 익스텐션을 다시 켠 후 연동을 진행해 주세요.
        </p>
      ),
      btnType: "point",
      btnRightText: "확인",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    {
      //! setModalOpen : 5
      header: "알 수 없는 오류",
      size: "sm",
      sort: "center",
      content: <p>알 수 없는 에러가 발생하였습니다.</p>,
      btnType: "point",
      btnRightText: "확인",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    {
      //! setModalOpen : 6
      header: "Klip QR code 로그인",
      size: "sm",
      sort: "center",
      content: <QRCodeSVG value={klipQRLinkState} />,
      btnType: "point",
      btnRightText: "확인",
      rdcBtnHandler: () => {
        setModalOpen(0);
        dispatch(
          userGetKlaytnAddressSagaActionCreator({
            type: "REJECT",
            setModalOpen,
          })
        );
      },
    },
    {
      //! setModalOpen : 7
      header: "스마트 월렛 정보 제공 동의",
      size: "sm",
      sort: "center",
      content: <p>스마트 월렛 정보 제공 동의 이후 사용하실 수 있습니다.</p>,
      btnType: "point",
      btnRightText: "확인",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    {
      //! setModalOpen : 8
      header: "연동 오류",
      size: "sm",
      sort: "center",
      content: <p>이미 연동 된 계정입니다.</p>,
      btnType: "point",
      btnRightText: "확인",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
  ];

  // 메타마스크 계정 첫 등록 이후 변경 불가
  useEffect(() => {
    if (!account || account === "") return;
    dispatch(
      userPutRegisterSmartWalletAddressSagaActionCreator({ setModalOpen })
    );
  }, [account]);

  const connectWallet = useCallback(
    async (wallet) => {
      if (isCheck === false) setModalOpen(7);
      else {
        switch (wallet) {
          case "METAMASK":
            dispatch(userGetMetamaskAddressSagaActionCreator({ setModalOpen }));
            break;

          case "KLIP":
            setModalOpen(6);
            dispatch(
              userGetKlaytnAddressSagaActionCreator({
                type: "REQUEST",
                setModalOpen,
              })
            );
            break;

          default:
            return;
        }
      }
    },
    [isCheck]
  );

  const walletBtnArr = [
    { type: "METAMASK", name: "메타마스크", img: FOXICON },
    { type: "KLIP", name: "클립 With Kakao", img: KLIPLOGO },
  ];

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen - 1]} />}
      <div className="connect_wallet_wrapper">
        <span>지갑 연결하기</span>
        {addressState && (
          <span>
            현재 연결된 계정 :{" "}
            {walletTypeState && (
              <img
                className="smart_wallet_logo_img"
                src={logoConvert(walletTypeState)}
                alt="smart wallet logo"
              />
            )}
            {addressState}
          </span>
        )}
        <div className="connect_wallet_contents">
          {walletBtnArr.map((data) => (
            <div
              key={data.name}
              className="connect_wallet_card"
              onClick={() => {
                connectWallet(data.type);
              }}
            >
              <img src={data.img} alt={`${data.img}`} />
              <div className="connect_wallet_btn">
                <span className="connect_wallet_btn_text">{data.name}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="connect_wallet_input_box">
          <input
            type="checkbox"
            name="agree"
            id="agree"
            onChange={() => {
              setisCheck(!isCheck);
            }}
          />
          <label htmlFor="agree">
            스마트 월렛 정보 제공과 마켓 이용약관에 동의합니다.
          </label>
        </div>
      </div>
    </>
  );
};

export default ConnectWallet;
