import { useDispatch, useSelector } from "react-redux";
import "./Admin.scss";
import React, { useEffect } from "react";
import { push } from "connected-react-router";
import {
  getClaimRequestList,
  getPointExchangeRequestList,
  setAdminState,
} from "../../../redux/modules/admin";

const Admin = () => {
  const dispatch = useDispatch();
  const pointRequestList = useSelector((state) => state.admin.pointRequestList);
  const claimToOLEARequestList = useSelector(
    (state) => state.admin.claimToOLEARequestList
  );
  const claimToPointRequestList = useSelector(
    (state) => state.admin.claimToPointRequestList
  );

  useEffect(() => {
    dispatch(getPointExchangeRequestList());
    dispatch(getClaimRequestList("OLEA"));
    dispatch(getClaimRequestList("OLEA POINT"));

    return () => {
      dispatch(
        setAdminState({
          olea: { price: 0, points: [] },
          pointRequestList: [],
          claimToOLEARequestList: [],
          claimToPointRequestList: [],
        })
      );
    };
  }, []);

  return (
    <div className="Admin">
      <div className="card" onClick={() => dispatch(push("/point"))}>
        POINT 관리 {pointRequestList.length}건
      </div>

      <div className="card" onClick={() => dispatch(push("/claim"))}>
        출금 신청 관리{" "}
        {claimToOLEARequestList.length + claimToPointRequestList.length}건
      </div>

      <div className="card" onClick={() => dispatch(push("/purchase"))}>
        사용자 구매 내역
      </div>
    </div>
  );
};

export default Admin;
