import ConnectWallet from "../../components/pages/connectWallet/ConnectWallet";
import AddressInquire from "../../components/pages/emailInquire/AddressInquire";
import EmailInquire from "../../components/pages/emailInquire/EmailInquire";
import Home from "../../components/pages/home/Home";
// import Club from "../../components/pages/markets/Club/Club";
// import Holder from "../../components/pages/markets/Holder/Holder";
// import Ticket from "../../components/pages/markets/Ticket/Ticket.jsx";
import MyWallet from "../../components/pages/myWallet/MyWallet";
import MyPage from "../../components/pages/myPage/MyPage";
import OAuth2RedirectHandler from "../../components/pages/OAuth2Redirect/OAuth2Redirect";
import SignIn from "../../components/pages/signIn/SignIn";
// import TokenPage from "../../components/pages/token/TokenPage";
// import Joy from "../../components/pages/joy/Joy";
import Olea from "../../components/pages/markets/Olea-1/Olea";
import Admin from "../../components/pages/admin/Admin";
import Point from "../../components/pages/admin/components/Point/Point";
import Claim from "../../components/pages/admin/components/Claim/Claim";
import SignInV2 from "../../components/pages/signInV2/SignInV2";
import SignUp from "../../components/pages/signUp/SignUp";
import Purchase from "../../components/pages/admin/components/Purchase/Purchase";
import HowToUse from "../../components/pages/howToUse/HowToUse";

// import OLEAMintOG from "../../components/pages/mintNFT/OLEAMintOG";
// import OLEAMintWL from "../../components/pages/mintNFT/OLEAMintWL";
// import OLEAMint from "../../components/pages/mintNFT/OLEAMint";
// import Mint from "../../components/pages/mintNFT/Mintpublic";

export function routeSetMap(userId, address, userType) {
  let routeMap = [];

  if (process.env.REACT_APP_ADMIN_USER_ID.includes(userId)) {
    routeMap = [
      { path: "/", Component: Admin },
      { path: "/point", Component: Point },
      { path: "/claim", Component: Claim },
      { path: "/purchase", Component: Purchase },
    ];
    return routeMap;
  }

  if (userId && userType === "email") {
    return [
      { path: "/", Component: Home },
      { path: "/my-page", Component: MyPage },
      { path: "/gifticon", Component: Olea },
      { path: "/how-to-use", Component: HowToUse },
    ];
  }

  // 월렛 연동 시
  if (userId && address) {
    return [
      { path: "/", Component: Home },
      { path: "/connect-wallet", Component: ConnectWallet },
      // { path: "/my-wallet", Component: MyWallet },
      { path: "/my-page", Component: MyPage },
      { path: "/email-inquire", Component: EmailInquire },
      { path: "/gifticon", Component: Olea },
      { path: "/nft", Component: MyWallet },
      // { path: "/holder", Component: Holder },
      // { path: "/club", Component: Club },
      // { path: "/ticket", Component: Ticket },
      // { path: "/joy", Component: Joy },
      // { path: "/olea", Component: Olea },
      { path: "/address-inquire", Component: AddressInquire },
      // { path: "/olea_mint_og", Component: OLEAMintOG },
      // { path: "/olea_mint_wl", Component: OLEAMintWL },
      // { path: "/olea_mint_public", Component: OLEAMint },
      { path: "/how-to-use", Component: HowToUse },
    ];
  }

  if (userId && userType === "discord") {
    return [
      { path: "/", Component: Home },
      { path: "/my-page", Component: MyPage },
      { path: "/gifticon", Component: Olea },
      { path: "/connect-wallet", Component: ConnectWallet },
      { path: "/email-inquire", Component: EmailInquire },
      { path: "/address-inquire", Component: AddressInquire },
      { path: "/how-to-use", Component: HowToUse },
    ];
  }

  return [
    { path: "/", Component: Home },
    { path: "/sign-in", Component: SignInV2 },
    { path: "/sign-up", Component: SignUp },
    { path: "/auth/discord/callback", Component: OAuth2RedirectHandler },
    { path: "/how-to-use", Component: HowToUse },
  ];
}
