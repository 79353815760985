import axios from "axios";

import { getAccessToken, getRefreshToken } from "./token";

const ADMIN_URL = `${process.env.REACT_APP_API_URL}/api/admin`;
const POINT_URL = `${process.env.REACT_APP_API_URL}/api/point`;
const CLAIM_URL = `${process.env.REACT_APP_API_URL}/api/claimLogs`;

export default class AdminService {
  // OLEA 시세 조회
  static async getOLEAPrice() {
    const { data } = await axios({
      method: "GET",
      url: `${POINT_URL}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  // 포인트 교환 요청 리스트 조회
  static async getPointExchangeRequestList() {
    const { data } = await axios({
      method: "GET",
      url: `${POINT_URL}/exchange`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  // 포인트 지급 및 완료 상태로 변경
  static async patchExchangePoint(payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${POINT_URL}/deposit`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  // 포인트 교환 요청 메모 기록
  static async patchPointExchangeRequestMemo(type, payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${POINT_URL}/memo?type=${type}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  // 포인트 차감
  static async patchSubtractPoint(payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${POINT_URL}/withdrawal`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  // 포인트 교환 요청 취소 상태로 변경
  static async patchCancelStatus(payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${POINT_URL}/admin/cancel`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  // 클레임 요청 리스트 조회
  static async getClaimRequestList(type) {
    const { data } = await axios({
      method: "GET",
      url: `${CLAIM_URL}${type === "OLEA" ? "" : "/point"}/admin`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  // 올레아 클레임 요청 메모 기록
  static async patchClaimToOLEARequestMemo(type, payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${CLAIM_URL}/memo?type=${type}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  // 올레아 클레임 요청 완료 상태로 변경
  static async patchClaimToOLEAComplete(payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${CLAIM_URL}/deposit`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  // 올레아 클레임 요청 취소 상태로 변경
  static async patchClaimToOLEACancel(payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${CLAIM_URL}/admin/cancel`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  // 포인트 클레임 요청 메모 기록
  static async patchClaimToPointRequestMemo(type, payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${CLAIM_URL}/point/memo?type=${type}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  // 포인트 클레임 요청 완료 상태로 변경
  static async patchClaimToPointComplete(payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${CLAIM_URL}/point/deposit`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  // 포인트 클레임 요청 취소 상태로 변경
  static async patchClaimToPointCancel(payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${CLAIM_URL}/point/admin/cancel`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  // 사용자 물품 구매 내역
  static async getPurchaseList() {
    const { data } = await axios({
      method: "GET",
      url: `${POINT_URL}/admin/purchase`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  // 리스트 row 데이터 삭제
  static async deleteRowData(url) {
    const { data } = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/${url}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }
}
