import axios from "axios";

import { getAccessToken, getRefreshToken } from "./token";

const Market_URI = `${process.env.REACT_APP_API_URL}/api/trades`;

export default class MarketService {
  static async getShowHolderMarket() {
    const { data } = await axios({
      method: "GET",
      url: `${Market_URI}/holder`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getShowClubMarket() {
    const { data } = await axios({
      method: "GET",
      url: `${Market_URI}/club`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getShowTicketMarket() {
    const { data } = await axios({
      method: "GET",
      url: `${Market_URI}/ticket`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getShowMarket() {
    const { data } = await axios({
      method: "GET",
      url: `${Market_URI}/market`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async postMarketOrder(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${Market_URI}/holder`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  static async postClubMarketOrder(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${Market_URI}/club`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  static async postClubMarketVoucherOrder(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${Market_URI}/club/voucher`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  static async postTicketMarketOrder(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${Market_URI}/ticket`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  static async getOLEATicker() {
    const { data } = await axios({
      method: "GET",
      url: "https://api.mexc.com/api/v3/ticker/price?symbol=OLEAUSDT",
      headers: { Accept: "application/json" },
    });
    return data;
  }

  static async getKRW() {
    const { data } = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/exchange`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async postOLEAPaymentLog(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/trades/payment-log`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  static async postVerifyOLEAPaymentLog(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/trades/payment-log/verify`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }

  static async getUserRestAmount(userId) {
    const { data } = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/trades/payment-log/${userId}/rest`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getUserPaymentLog() {
    const { data } = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/trades/payment-log`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getPaymentLogVerify(paymentLogsId) {
    const { data } = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/trades/payment-log/log-verify/${paymentLogsId}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getOLEAPoint() {
    const { data } = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/point`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async getOLEAPointInfo() {
    const { data } = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/point/info`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async postExchange(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/point/exchange`,
      data: payload,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async patchExchange(payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/point/exchange`,
      data: payload,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  static async patchExchangeCancel(payload) {
    const { data } = await axios({
      method: "PATCH",
      url: `${process.env.REACT_APP_API_URL}/api/point/cancel`,
      data: payload,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  // 토큰 마켓 믈픔 리스트 조회
  static async getTokenMarketGoodsList(type) {
    const { data } = await axios({
      method: "GET",
      url: `${Market_URI}/market?type=${type}`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return data;
  }

  // 토큰 마켓 물품 구매
  static async postTokenMarketGoodsPayment(payload) {
    const { data } = await axios({
      method: "POST",
      url: `${Market_URI}/market`,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      data: payload,
    });
    return data;
  }
}
