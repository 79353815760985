import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configStore from "./redux/configStore";
import { createBrowserHistory } from "history";
import "./styles/common.scss";

import App from "./App";
import "./service/config.js";
import { SnackbarProvider } from "notistack";

export const history = createBrowserHistory();

const store = configStore(history);
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={10}
        classes={{
          base: { fontSize: 26 },
        }}
      >
        <App />
      </SnackbarProvider>
    </Provider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={10}
        classes={{
          base: { fontSize: 26 },
        }}
      >
        <App />
      </SnackbarProvider>
    </Provider>,
    rootElement
  );
}
