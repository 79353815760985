import "./policy.scss";
import React from "react";

const Marketing = () => {
  return (
    <div className="policy">
      <table>
        <thead>
          <tr>
            <th>수집 시점</th>
            <th>수집 및 이용목적</th>
            <th>필수 / 선택</th>
            <th>수집항목</th>
            <th>이용 및 보유기간</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>회원가입 시</td>
            <td rowSpan={3}>마케팅 활용 및 이벤트 안내</td>
            <td rowSpan={4}>선택</td>
            <td>이메일 주소</td>
            <td rowSpan={3}>회원 탈퇴 시까지</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Marketing;
