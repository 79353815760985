import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../../../../modal/Modal";

import {
  marketGetOLEAPointInfoSagaActionCreator,
  marketGetOLEAPointSagaActionCreator,
  marketPatchExchangeCancelSagaActionCreator,
  marketPatchExchangeSagaActionCreator,
  marketPostExchangeSagaActionCreator,
  success,
} from "../../../../../redux/modules/market";

import "../../MarketRightContainer.scss";
import OleaPointProgress from "./OleaPointProgress";
import OleaPointLog from "../../../myPage/template/OleaPointLog";
import { MdContentCopy } from "react-icons/md";
import {
  Box,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import QUESTIONMARK from "../../../../../assets/icons/questionMark.svg";

const MarketRightContainer = ({ states }) => {
  const dispatch = useDispatch();
  // totalCheck: boolean > 장바구니 내부 상품이 totalPrice보다 높은지 낮은지 비교
  // totalPrice: number > 장바구니 상품 총합
  // myShoppingCart: [] > 장바구니 [{goodsId: number}]
  // count: number > SubHeader에 있는 3000ms 시간 제한. 300s 단위
  const [selectPoint, setSelectPoint] = useState(0);
  const { count } = states;
  const userId = useSelector((state) => state.user.userInfo?.userId);
  const [txHashValue, setTxHashValue] = useState("");

  const userEmail = useSelector((state) => state.user.userInfo?.userEmail);
  const OLEA_Point = useSelector((state) => state.market.OLEA_Point);
  const OLEA_Point_Info = useSelector((state) => state.market.OLEA_Point_Info);
  const exchangeSuccessId = useSelector(
    (state) => state.market.exchangeSuccessId
  );

  const [modalOpen, setModalOpen] = useState(0);

  useEffect(() => {
    if (count === 0) {
      // 시간 만료 (5분 뒤 API 호출하여 갱신)
      // OLEA Point 충전용 단위/시세 데이터 조회
      dispatch(marketGetOLEAPointInfoSagaActionCreator());
      dispatch(marketGetOLEAPointSagaActionCreator());
      // OLEA to USDT 시세 조회 (외부)
      // dispatch(marketGetOLEATickerSagaActionCreator());
      // USDT to KRW 시세 조회 (외부)
      // dispatch(marketGetKRWTickerSagaActionCreator());
      // 사용 가능 잔여 OLEA 조회
      // dispatch(marketGetUserRestAmountSagaActionCreator(userId));
    }
  }, [count]);

  useEffect(() => {
    if (exchangeSuccessId) {
      setModalOpen("TXHASH_SEND");
    }
  }, [exchangeSuccessId]);

  useEffect(() => {
    return () => {
      dispatch(success({ exchangeSuccessId: null }));
    };
  }, []);

  const clickOrderBtnHandler = () => {
    if (!selectPoint) return;

    if (OLEA_Point_Info?.exchangeInProgress?.status === "신청") {
      alert(
        "충전이 진행 중입니다.\n새로운 충전 신청은 기존 신청 취소 후 다시 신청해 주세요."
      );

      return;
    }

    if (OLEA_Point_Info?.exchangeInProgress?.status === "전송") {
      alert(
        "충전이 진행 중입니다.\n트랜잭션 해시를 입력한 후에는 취소하실 수 없습니다.\n영업시간 내에 관리자 확인 후 POINT가 지급될 예정입니다."
      );

      return;
    }

    dispatch(
      marketPostExchangeSagaActionCreator({
        point: selectPoint,
      })
    );
  };

  const modalData = {
    ERROR_EXCEED_USABLE_OLEA: {
      header: "오류",
      size: "sm",
      sort: "center",
      content: <p>사용 가능한 OLEA COIN 한도를 초과합니다.</p>,
      ico: true,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    ERROR_NOT_ENOUGH_OLEA: {
      header: "오류",
      size: "sm",
      sort: "center",
      content: <p>OLEA가 부족합니다.</p>,
      ico: true,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    ERROR_NOT_EXIST_PRODUCT: {
      header: "오류",
      size: "sm",
      sort: "center",
      content: <p>장바구니에 상품이 없습니다.</p>,
      ico: true,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    ERROR_NO_MORE_CHANCE_TO_BUY: {
      header: "오류",
      size: "sm",
      sort: "center",
      content: <p>구매 기회가 모두 소진되었습니다.</p>,
      ico: true,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    SUCCESS_BUY_PRODUCT: {
      header: "안내",
      size: "sm",
      sort: "center",
      content: (
        <p>
          티켓 마켓 상품 구매가 정상적으로 처리되었습니다.
          <br />
          {userEmail && userEmail}
          <br />
          해당 이메일 주소로 상품이 발송되었습니다.
        </p>
      ),
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        window.location.href = "/olea";
        setModalOpen(0);
      },
    },
    ERROR_BUY_PRODUCT: {
      header: "오류",
      size: "sm",
      sort: "center",
      content: (
        <p>
          상품 구매가 비정상적으로 처리되었습니다.
          <br />
          {userEmail && userEmail}
          <br />
          해당 이메일 주소로 상품이 발송되었는지 확인 후,
          <br />
          이메일 혹은 디스코드 커뮤니티 티켓으로 문의 바랍니다.
        </p>
      ),
      ico: true,
      btnType: "point",
      btnRightText: "닫기",
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    TXHASH_SEND: {
      header: "트랜잭션 해시 입력",
      size: "sm",
      sort: "center",
      content: (
        <div>
          <h3>
            <span style={{ color: "#595771" }}>OLEA MARKET</span> 지갑 주소
          </h3>

          <div className="wallet_address" style={{ marginTop: 3 }}>
            <span>0xf1fA60EfFE62c8f9Bf8165b86C08000257AC6668</span>
            <span>
              <MdContentCopy
                className="copyBtn"
                style={{ fontSize: 15, marginLeft: 10, cursor: "pointer" }}
                onClick={(e) => {
                  const $mail =
                    document.querySelector(".wallet_address").textContent;
                  const $container = document.createElement("textarea");
                  document.body.appendChild($container);
                  $container.value = $mail;
                  $container.select();
                  document.execCommand("copy");
                  document.body.removeChild($container);
                  alert("OLEA MARKET 지갑주소가 복사되었습니다.");
                }}
              />
            </span>
          </div>

          <br />

          <h3>
            <span style={{ color: "#595771" }}>OLEA MARKET</span> 지갑에 전송할
            OLEA COIN
          </h3>

          <div style={{ marginTop: 3 }}>
            <span className="transfer_olea">
              {OLEA_Point_Info?.exchangeInProgress?.oleaForExchange?.toLocaleString()}{" "}
            </span>
            OLEA COIN
            <span>
              <MdContentCopy
                className="copyBtn"
                style={{ fontSize: 15, marginLeft: 10, cursor: "pointer" }}
                onClick={(e) => {
                  const $mail =
                    document.querySelector(".transfer_olea").textContent;
                  const $container = document.createElement("textarea");
                  document.body.appendChild($container);
                  $container.value = $mail;
                  $container.select();
                  document.execCommand("copy");
                  document.body.removeChild($container);
                  alert(
                    "OLEA MARKET 지갑에 전송할 OLEA COIN이 복사되었습니다."
                  );
                }}
              />
            </span>
          </div>

          <br />

          <div style={{ marginTop: 8 }}>
            <span style={{ color: "#595771" }}>OLEA MARKET</span> 지갑 주소로
            OLEA COIN을 전송하신 후
            <br />
            발급된 <strong>트랜잭션 해시</strong> 값을 아래에 입력해 주세요.
            <div style={{ color: "#507df1", fontSize: 14 }}>
              트랜잭션 해시 입력 후에는 충전 신청을 취소하실 수 없습니다.
            </div>
            <input
              type="text"
              placeholder="트랜잭션 해시 입력"
              className="inquire_style_input"
              onChange={(e) => setTxHashValue(e.target.value)}
              style={{ marginTop: 8, maxWidth: 400 }}
            />
          </div>

          <br />

          <div style={{ color: "#e94786", fontSize: 14 }}>
            OLEA MARKET 지갑 주소와 전송할 OLEA COIN을 정확히 확인해 주세요.
            <br />
            이 과정에서 발생하는 문제는 도움을 드릴 수 없습니다.
            <br />
          </div>
        </div>
      ),

      btnRightText: "전송",
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
      rightBtnHandler: () => {
        if (txHashValue.trim() === "") {
          alert("트랜잭션 해시를 입력해 주세요.");
          return;
        }
        dispatch(
          marketPatchExchangeSagaActionCreator({
            payload: {
              hashValue: txHashValue,
              id: exchangeSuccessId || OLEA_Point_Info?.exchangeInProgress.id,
            },
            setModalOpen,
          })
        );
      },
    },
    POINT_PROGRESS: {
      header: "POINT 충전 진행 상태",
      size: "md",
      sort: "center",
      rightBtn: false,
      content: <OleaPointProgress setModalOpen={setModalOpen} />,
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
    POINT_CANCEL: {
      header: "POINT 충전 취소",
      size: "sm",
      sort: "center",
      btnRightText: "충전 취소",
      leftBtn: true,
      btnLeftText: "닫기",
      content: <p>POINT 충전을 취소하시겠습니까?</p>,
      ldcBtnHandler: () => {
        setModalOpen(0);
      },
      rightBtnHandler: () => {
        dispatch(
          marketPatchExchangeCancelSagaActionCreator({
            payload: { id: OLEA_Point_Info?.exchangeInProgress?.id },
            setModalOpen,
          })
        );
      },
    },
    POINT_LOG: {
      header: "POINT 충전 내역",
      size: "md",
      sort: "center",
      btnRightText: "닫기",
      content: <OleaPointLog />,
      rdcBtnHandler: () => {
        setModalOpen(0);
      },
    },
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopover = (e) => {
    if (e.target.id === "questionmark") return;
    if (open) setOpen(false);
  };

  return (
    <>
      {modalOpen !== 0 && <Modal data={modalData[modalOpen]} />}

      <ClickAwayListener onClickAway={handlePopover}>
        <Box>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement={"right-start"}
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ marginTop: "20px" }}>
                  <Typography sx={{ p: 0.5 }}>
                    기프티콘 상품 구매 시, 보유한 POINT의 최대 10%까지 사용
                    가능합니다.
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>

      <div className="olea_market_right_container_wrapper">
        <div className="market_right_container_point_container">
          {OLEA_Point_Info?.exchangeInProgress && (
            <>
              <button onClick={() => setModalOpen("POINT_PROGRESS")}>
                충전 진행 상태 확인
              </button>
              {/* 신청 상태일 때 렌더 */}
              {/* {OLEA_Point_Info.exchangeInProgress?.status === "신청" && (
                <button onClick={() => setModalOpen("TXHASH_SEND")}>
                  트랜잭션 해시 입력
                </button>
              )} */}
            </>
          )}
        </div>

        <div className="market_right_container_header">
          <div>지갑</div>

          <div>
            <div>
              <span>
                보유 POINT :{" "}
                <strong style={{ fontSize: "16px", color: "#2f8fff" }}>
                  {OLEA_Point_Info?.point < 0
                    ? 0
                    : OLEA_Point_Info?.point?.toLocaleString()}{" "}
                </strong>
              </span>
            </div>

            <div>
              <span>
                구매 시 사용 가능 POINT
                <img
                  id="questionmark"
                  src={QUESTIONMARK}
                  alt="QUESTIONMARK"
                  onClick={handlePopoverOpen}
                  width={15}
                  style={{ cursor: "pointer", marginLeft: 5 }}
                />{" "}
                :{" "}
                <strong style={{ fontSize: "16px", color: "#2f8fff" }}>
                  {OLEA_Point_Info?.point < 0
                    ? 0
                    : parseInt(
                        OLEA_Point_Info?.point / 10
                      )?.toLocaleString()}{" "}
                </strong>
              </span>
            </div>
          </div>
        </div>

        {/* <Web3Profile props={{ OLEA_USDT, USDT_KRW }} /> */}
        <div className="flex_start" style={{ color: "#fff" }}>
          {OLEA_Point?.points?.map(({ point, price }) => (
            <label key={point} htmlFor={`point_${point}`} className="point_box">
              <div>
                <input
                  type="radio"
                  id={`point_${point}`}
                  name={`point`}
                  onChange={() => setSelectPoint(point)}
                />
              </div>
              <span>{point.toLocaleString()} POINT</span>
              <span>({price.toLocaleString()} OLEA COIN 전송)</span>
            </label>
          ))}
        </div>
        <div className="olea_market_right_container_btn_box">
          {/* {address && <button onClick={clickCheckPaymentLog}>구매 내역</button>}
          {address && (
            <button
              disabled={!write || contractWriteLoading}
              onClick={() => {
                if (contractWriteLoading) return;
                clickOrderBtnHandler();
                return;
              }}
            >
              주문하기
            </button>
          )} */}
          <button
            disabled={!selectPoint}
            onClick={() => {
              clickOrderBtnHandler();
              return;
            }}
          >
            충전하기
          </button>
        </div>
      </div>
    </>
  );
};

export default MarketRightContainer;
