import { takeEvery, put, call, select, takeLatest } from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import { loadingOff, loadingOn } from "./loding";
import GameService from "../../service/gameService";
import TokenService from "../../service/token";

const prefix = "olea/game";

// action type
const actionType = {};

const actionTypeArray = ["START", "SUCCESS", "FAIL"];

actionTypeArray.forEach((data) => {
  actionType[data] = `${prefix}/${data}`;
});

const { START, SUCCESS, FAIL } = actionType;

export const actionCreator = (type, data) => ({
  type,
  data,
});

// action creator
const start = () => actionCreator(START);
const success = (data) => actionCreator(SUCCESS, data);
const fail = (error) => actionCreator(FAIL, error);

// initial state
const initialState = {
  error: null,
  info: {
    game: [{}],
    gameMoney: null,
    winningMoney: null,
    rankingBoard: [],
  },
  prizeNumber: 0,
};

// reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START:
      return {
        ...state,
        error: null,
      };
    case SUCCESS:
      return {
        ...state,
        ...action.data,
        error: null,
      };
    case FAIL:
      return {
        ...state,
        error: action.data.error,
      };
    default:
      return state;
  }
}

// saga action
const sagaActionType = {};

const sagaActionTypeArr = [
  "GAME_SET_STATE",
  "POST_RUN_GAME",
  "GET_GAME_INFO",
  "POST_CREATE_NICKNAME",
];

sagaActionTypeArr.forEach((data) => {
  sagaActionType[data] = `${prefix}/${data}`;
});

const { GAME_SET_STATE, POST_RUN_GAME, GET_GAME_INFO, POST_CREATE_NICKNAME } =
  sagaActionType;

// saga action creator
export const gameSetSagaActionCreator = (data) =>
  actionCreator(GAME_SET_STATE, data);

export const postRunGameSagaActionCreator = (data) =>
  actionCreator(POST_RUN_GAME, data);

export const getGameInfoSagaActionCreator = (data) =>
  actionCreator(GET_GAME_INFO, data);

export const postCreateNicknameSagaActionCreator = (data) =>
  actionCreator(POST_CREATE_NICKNAME, data);

// saga reducer
function* sagaReducer(action) {
  const type = action.type;
  switch (type) {
    case GAME_SET_STATE:
      try {
        yield put(loadingOn());
        yield put(success(action.data));
        yield put(loadingOff());
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case POST_RUN_GAME:
      try {
        const game = yield select((state) => state.game.info.game);
        const res = yield call(GameService.runGame, action.data.currentBatCoin);

        yield put(
          success({
            prizeNumber: game.find(
              (data) => data.option === res.data.gameResult
            ).id,
            info: {
              game: game,
              gameMoney: res.data.gameMoney,
              legacyWinningMoney: res.data.legacyWinningMoney,
              winningMoney: res.data.winningMoney,
              rankingBoard: yield select(
                (state) => state.game.info.rankingBoard
              ),
            },
          })
        );
        // yield put(getGameInfoSagaActionCreator(action.data.currentBatCoin));
        action.data.setMustSpin(true);
      } catch (e) {
        console.log(e.response);
      }
      break;

    case GET_GAME_INFO:
      try {
        const res = yield call(GameService.getGame, action.data);
        yield put(
          success({
            info: {
              game: res.data.game.reduce((acc, value, index) => {
                const id = index;
                const option = value;

                return [...acc, { id, option }];
              }, []),
              gameMoney: res.data.gameMoney,
              winningMoney: res.data.winningMoney,
              rankingBoard: res.data.rankingBoard,
            },
          })
        );
      } catch (e) {
        const err = { ...e.response.data.error };
        console.log(e);
        console.log(err);
      }
      break;

    case POST_CREATE_NICKNAME:
      try {
        const res = yield call(GameService.postNickname, action.data.nickname);
        if (res.message === "Ok") {
          action.data.setModalOpen(3);
          TokenService.save(res.data.accessToken);
        }
      } catch (e) {
        const err = { ...e.response.data.error };
        if (err.message === "Already exist") {
          action.data.setAlreadyExist(true);
        }
        console.log(e);
        console.log(err);
      }
      break;

    default:
      break;
  }
}

// export saga
export function* gameSaga() {
  yield takeEvery(GAME_SET_STATE, sagaReducer);
  yield takeEvery(POST_RUN_GAME, sagaReducer);
  yield takeEvery(GET_GAME_INFO, sagaReducer);
  yield takeEvery(POST_CREATE_NICKNAME, sagaReducer);
}
