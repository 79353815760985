import {
  takeEvery,
  put,
  call,
  select,
  delay,
  fork,
  cancel,
} from "redux-saga/effects";
import { push, replace } from "connected-react-router";
import jwt_decode from "jwt-decode";

import TokenService, {
  getAccessToken,
  getRefreshToken,
} from "../../service/token";
import AuthService from "../../service/authService";
import UserService from "../../service/userService";
import NftService from "../../service/nftService";

import { balanceTypeConvert } from "../../shared/NftData";

import { routeSetMap } from "../../shared/Router/routeMap";

import Home from "../../components/pages/home/Home";
import SignIn from "../../components/pages/signIn/SignIn";
import OAuth2RedirectHandler from "../../components/pages/OAuth2Redirect/OAuth2Redirect";
import InquireService from "../../service/inquireService";
import { loadingOff, loadingOn } from "./loding";
import { ethers } from "ethers";
import { getGameInfoSagaActionCreator } from "./game";
import SignInV2 from "../../components/pages/signInV2/SignInV2";
import SignUp from "../../components/pages/signUp/SignUp";
import SignUpComplete from "../../components/pages/signUp/SignUpComplete";
import EmailAuthenticationSuccess from "../../components/pages/signUp/EmailAuthenticationSuccess";
import HowToUse from "../../components/pages/howToUse/HowToUse";
// import Mint from "../../components/pages/mintNFT/Mintpublic";

const prefix = "olea/user";

// action type
const actionType = {};

const actionTypeArray = ["SUCCESS", "FAIL", "SET"];

actionTypeArray.forEach((data) => {
  actionType[data] = `${prefix}/${data}`;
});

const { SUCCESS, FAIL, SET } = actionType;

export const actionCreator = (type, data) => ({
  type,
  data,
});

// action creator
const success = (data) => actionCreator(SUCCESS, data);
const fail = (error) => actionCreator(FAIL, error);
export const set = (data) => actionCreator(SET, data);

// initial state
const initialState = {
  error: null,
  userMetaMaskInfo: [],
  tradeLog: [],
  nftSearch: { tier: "", edition: "", balance: "" },
  routeMap: [
    { path: "/", Component: Home },
    // { path: "/sign-in", Component: SignIn },
    { path: "/sign-in", Component: SignInV2 },
    { path: "/sign-up", Component: SignUp },
    { path: "/auth/discord/callback", Component: OAuth2RedirectHandler },

    { path: "/auth/email", Component: SignUpComplete },
    { path: "/email/*", Component: EmailAuthenticationSuccess },
    { path: "/how-to-use", Component: HowToUse },
  ],
  klipQRLink: "DEFAULT",
  oleaMintLogs: [],
  oleaClaimLog: [],
};

// reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        routeMap: action.data,

        error: null,
      };
    case FAIL:
      return {
        ...state,
        // error: action.data.error,
      };
    case SET:
      return {
        ...state,
        ...action.data,
        error: null,
      };
    default:
      return state;
  }
}

// saga action
const sagaActionType = {};
const sagaActionTypeArr = [
  "USER_INIT_SAGA",
  "USER_SET_STATE",
  "AUTH_SIGNIN_CHECK",
  "USER_GET_DISCORD_ACCOUNT",
  "USER_GET_SCORES",
  "USER_GET_TICKET",
  "USER_GET_BALANCE",
  "USER_SIGNOUT",
  "USER_PUT_REFRESH_NFT",
  "USER_GET_METAMASK_INFO",
  "USER_GET_TRADE_LOG",
  "USER_POST_EMAIL_INQUIRE",
  "USER_GET_VOUCHER",
  "USER_GET_METAMASK_ADDRESS",
  "USER_GET_KLAYTN_ADDRESS",
  "USER_PUT_REGISTER_SMART_WALLET_ADDRESS",
  "USER_GET_MY_PAGE_INFO",
  "USER_DELETE_INFO",
  "USER_POST_BUY_TICKET",
  "USER_POST_BUY_GAME_MONEY",
  "USER_POST_REQUEST_CLAIM",
  "USER_SEND_CALLIOPE_MINT_INFO",
  "USER_POST_BUY_TICKET_ON_OLEA_MINT",
  "USER_GET_MINT_LOG",
  "USER_GET_EACH_MINT_LOG",
  "USER_GET_OLEA_CLAIM_LOG",
  "USER_POST_OLEA_CLAIM",
  "GET_POINT_LOGS",
];

sagaActionTypeArr.forEach((data) => {
  sagaActionType[data] = `${prefix}/${data}`;
});

const {
  USER_INIT_SAGA,
  USER_SET_STATE,
  USER_GET_DISCORD_ACCOUNT,
  USER_GET_SCORES,
  USER_GET_TICKET,
  USER_GET_BALANCE,
  USER_SIGNOUT,
  USER_PUT_REFRESH_NFT,
  USER_GET_METAMASK_INFO,
  USER_GET_TRADE_LOG,
  USER_POST_EMAIL_INQUIRE,
  USER_GET_VOUCHER,
  USER_GET_METAMASK_ADDRESS,
  USER_GET_KLAYTN_ADDRESS,
  USER_PUT_REGISTER_SMART_WALLET_ADDRESS,
  USER_GET_MY_PAGE_INFO,
  USER_DELETE_INFO,
  USER_POST_BUY_TICKET,
  USER_POST_BUY_GAME_MONEY,
  USER_POST_REQUEST_CLAIM,
  USER_SEND_CALLIOPE_MINT_INFO,
  USER_POST_BUY_TICKET_ON_OLEA_MINT,
  USER_GET_MINT_LOG,
  USER_GET_EACH_MINT_LOG,
  USER_GET_OLEA_CLAIM_LOG,
  USER_POST_OLEA_CLAIM,
  GET_POINT_LOGS,
} = sagaActionType;

// saga action creator
export const userInitSagaActionCreator = (data) =>
  actionCreator(USER_INIT_SAGA, data);

export const userSetStateActionCreator = (data) =>
  actionCreator(USER_SET_STATE, data);

export const userGetDiscordAccountSagaActionCreator = (data) =>
  actionCreator(USER_GET_DISCORD_ACCOUNT, data);

export const userGetInfoSagaActionCreator = (data) =>
  actionCreator(USER_GET_SCORES, data);

export const userGetTicketSagaActionCreator = (data) =>
  actionCreator(USER_GET_TICKET, data);

export const userGetBalanceSagaActionCreator = (data) =>
  actionCreator(USER_GET_BALANCE, data);

export const userSignOutSagaActionCreator = (data) =>
  actionCreator(USER_SIGNOUT, data);

export const userPutRefreshNftSagaActionCreator = (data) =>
  actionCreator(USER_PUT_REFRESH_NFT, data);

export const userGetMetamaskInfoSagaActionCreator = (data) =>
  actionCreator(USER_GET_METAMASK_INFO, data);

export const userGetTradeLogSagaActionCreator = (data) =>
  actionCreator(USER_GET_TRADE_LOG, data);

export const userPostEmailInquireSagaActionCreator = (data) =>
  actionCreator(USER_POST_EMAIL_INQUIRE, data);

export const userGetVoucherSagaActionCreator = () =>
  actionCreator(USER_GET_VOUCHER);

export const userGetMetamaskAddressSagaActionCreator = (data) =>
  actionCreator(USER_GET_METAMASK_ADDRESS, data);

export const userGetKlaytnAddressSagaActionCreator = (data) =>
  actionCreator(USER_GET_KLAYTN_ADDRESS, data);

export const userPutRegisterSmartWalletAddressSagaActionCreator = (data) =>
  actionCreator(USER_PUT_REGISTER_SMART_WALLET_ADDRESS, data);

export const userGetMyPageInfoSagaActionCreator = (data) =>
  actionCreator(USER_GET_MY_PAGE_INFO, data);

export const userDeleteInfoSagaActionCreator = (data) =>
  actionCreator(USER_DELETE_INFO, data);

export const userPostBuyTicketSagaActionCreator = (data) =>
  actionCreator(USER_POST_BUY_TICKET, data);

export const userPostBuyGameMoneySagaActionCreator = (data) =>
  actionCreator(USER_POST_BUY_GAME_MONEY, data);

export const userPostRequestClaimSagaActionCreator = (data) =>
  actionCreator(USER_POST_REQUEST_CLAIM, data);

export const userSendCalliopeMintInfoSagaActionCreator = (data) =>
  actionCreator(USER_SEND_CALLIOPE_MINT_INFO, data);

export const userPostBuyTicketOnOleaMintSagaActionCreator = (data) =>
  actionCreator(USER_POST_BUY_TICKET_ON_OLEA_MINT, data);

export const userGetMintLogSagaActionCreator = () =>
  actionCreator(USER_GET_MINT_LOG);

export const userGetEachMintLogSagaActionCreator = (data) =>
  actionCreator(USER_GET_EACH_MINT_LOG, data);

export const userGetOleaClaimLogSagaActionCreator = (data) =>
  actionCreator(USER_GET_OLEA_CLAIM_LOG, data);

export const userPostOleaClaimSagaActionCreator = (data) =>
  actionCreator(USER_POST_OLEA_CLAIM, data);

export const getPointLogs = (data) => actionCreator(GET_POINT_LOGS, data);

// saga reducer
function* sagaReducer(action) {
  const type = action.type;
  switch (type) {
    case USER_INIT_SAGA:
      try {
        yield put(loadingOn());
        if (getAccessToken()) {
          const decode = jwt_decode(getAccessToken());
          yield put(
            set({
              userInfo: {
                userId: decode.userId,
                userEmail: decode.email,
                address: decode.address,
                accessToken: getAccessToken(),
                refreshToken: getRefreshToken(),
                walletType: decode.walletType,
                userType: decode.loginType,
              },
            })
          );
          let routeMap = [];
          routeMap = routeSetMap(
            decode.userId,
            decode.address,
            decode.loginType
            // yield select((state) => state.auth.userType)
          );
          yield put(success(routeMap));
          yield put(loadingOff());
        } else {
          let routeMap = [];
          routeMap = initialState.routeMap;
          yield put(success(routeMap));
          yield put(loadingOff());
          TokenService.clear();
        }
      } catch (error) {
        // const err = { ...error.response.data.error };
        // yield put(fail());
        yield put(loadingOff());
      }
      break;

    case USER_SET_STATE:
      yield put(set(action.data));
      yield put(loadingOff());
      break;

    case USER_GET_DISCORD_ACCOUNT:
      try {
        yield put(loadingOn());
        const res = yield call(AuthService.getDiscordLogin, action.data.code);
        TokenService.save(res.data.accessToken, res.data.refreshToken);
        const decoded = jwt_decode(res.data.accessToken);
        yield put(loadingOff());
        yield put(userInitSagaActionCreator());
        yield put(replace("/"));
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
        if (
          err.message ===
          "Athena Returns NFT 디스코드 채널에 가입하지 않은 사용자입니다."
        ) {
          action.data.setModalOpen(1);
        } else {
          yield put(fail({ error: err }));
          yield put(replace("/sign-in"));
        }
      }
      break;

    case USER_GET_SCORES:
      try {
        yield put(loadingOn());
        const state = yield select();
        const userInfoState = state.user.userInfo;
        const res = yield call(UserService.getUserScores);
        if (res.message === "Ok") {
          yield put(set({ userInfo: { ...userInfoState, PP: res.data.PP } }));
          yield put(loadingOff());
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
        if (
          err.message ===
          "Please chat in Athena Returns NFT Discord channel first."
        ) {
          action.data.setModalOpen(1);
        } else if (err.message === "lack of level") {
          action.data.setModalOpen(2);
        }
      }
      break;

    case USER_GET_TICKET:
      try {
        yield put(loadingOn());
        const state = yield select();
        const userInfoState = state.user.userInfo;
        const res = yield call(UserService.getUserTicket);
        if (res.message === "Ok") {
          yield put(
            set({ userInfo: { ...userInfoState, ticket: res.data.ticket } })
          );
          yield put(loadingOff());
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case USER_GET_BALANCE:
      try {
        const resultData = balanceTypeConvert(
          action.data.tier,
          action.data.edition
        );
        if (resultData.value !== null && resultData.value !== "") {
          const res = yield call(NftService.getBalance, resultData.value);
          yield put(set({ balance: res.data }));
        } else if (!resultData.value || resultData.value === "") {
          yield put(set({ balance: null, error_balance: resultData.reason }));
        } else if (resultData.reason === null) {
          yield put(set({ error_balance: null }));
        } else {
          yield put(set({ error_balance: resultData.reason }));
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        // console.log("USER_GET_BALANCE : ", err);
        if (
          err.message === "(거래할 수 없는 nft)거래 조건을 만족하지 못했습니다."
        ) {
          yield put(
            set({
              balance: null,
              error_balance: "아직 민팅되지 않은 NFT 입니다.",
            })
          );
        }
        if (err.message === "사용할 수 없는 NFT 입니다.") {
          yield put(
            set({
              balance: null,
              error_balance: err.message,
            })
          );
        }
      }
      break;

    case USER_SIGNOUT:
      try {
        yield TokenService.clear();
        window.location.href = "/sign-in";
      } catch (error) {
        const err = { ...error.response.data.error };
      }
      break;

    case USER_PUT_REFRESH_NFT:
      try {
        yield put(loadingOn());
        const res = yield call(UserService.refreshNftList);
        TokenService.save(res.data.accessToken);
        yield put(loadingOff());
        window.location.href = "/nft";
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
        if (err.message === "스마트 월렛 계정이 연동되어있지 않습니다.") {
          action.data.setModalOpen(1);
        } else {
          action.data.setModalOpen(2);
        }
      }
      break;

    case USER_GET_TRADE_LOG:
      try {
        const res = yield call(NftService.getTradeLog, action.data);
        yield put(
          set({
            tradeLog: res.data,
          })
        );
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
      }
      break;

    case USER_POST_EMAIL_INQUIRE:
      try {
        yield put(loadingOn());
        const res = yield call(
          InquireService.postSendEmailInquire,
          action.data.payload
        );
        if (res.result === "SUCCESS") {
          yield put(loadingOff());
          action.data.setModalOpen(2);
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
        action.data.setModalOpen(3);
      }
      break;

    case USER_GET_VOUCHER:
      try {
        yield put(loadingOn());
        const res = yield call(UserService.getUserVoucher);
        if (res.message === "Ok") {
          yield put(set({ voucher: res.data.voucher }));
        }
        yield put(loadingOff());
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case USER_GET_METAMASK_INFO:
      try {
        yield put(loadingOn());
        const res = yield call(UserService.getUserMetaMask, action.data);
        if (res.message === "Ok") {
          yield put(loadingOff());
        }
        if (res.data.length !== 0) {
          const resUserMetamaskInfo = res.data.nfts;
          const curDate = res.data.date;
          const mappingUserMetamaskInfo = resUserMetamaskInfo.map((data) => {
            const imageUrl = data.imageUrl.split(
              "https://storage.googleapis.com/athenareturns/"
            );
            return {
              ...data,
              imageUrl: `https://d2als48iiabdbp.cloudfront.net/${imageUrl[1]}`,
            };
          });
          yield put(
            set({
              userMetaMaskInfo: mappingUserMetamaskInfo,
              date: curDate,
            })
          );
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case USER_GET_METAMASK_ADDRESS:
      try {
        if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          yield provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          yield put(
            set({
              registerPayload: {
                address: yield signer.getAddress(),
                walletType: 1,
              },
            })
          );
        } else {
          action.data.setModalOpen(2);
        }
      } catch (error) {
        if (error.code === 4001) {
          action.data.setModalOpen(3);
        } else if (error.code === -32002) {
          action.data.setModalOpen(4);
        } else {
          action.data.setModalOpen(5);
        }
      }
      break;

    case USER_GET_KLAYTN_ADDRESS:
      try {
        let pollForAddress; // 1초마다 response.data.result 응답받아오는 API 변수 선언
        if (action.data.type === "REQUEST") {
          // 요청을 보냈을 때,
          const res = yield call(UserService.connectKlip); // request_key를 받아와
          const { request_key } = res; // 구조분해할당으로 변수 선언 후,

          yield put(
            // QR code Link를 받아와 State에 저장한다.
            set({
              klipQRLink: `https://klipwallet.com/?target=/a2a?request_key=${request_key}`,
            })
          );

          pollForAddress = yield fork(function* () {
            while (true) {
              // loop 시작
              // 위에서 받아온 request_key를 쿼리스트링으로 보내 result를 받아오는 Klip API
              const res = yield call(UserService.getKlipAccessUrl, request_key);
              if (res.data.result) {
                // 사용자가 QR 코드 스캔 이후 결과가 있다면,
                // 사용자의 Klip Address와 walletType을 state에 저장하고,
                yield put(
                  set({
                    registerPayload: {
                      address: res.data.result.klaytn_address,
                      walletType: 2,
                    },
                  })
                );
                yield put(
                  // QR code Link를 초기화 한다.
                  set({
                    klipQRLink: "DEFAULT",
                  })
                );
                action.data.setModalOpen(0); // QR code 모달창을 닫고
                break; // loop 탈출
              }
              yield delay(1000); // 1초 이후 재요청
            }
          });
          yield put(set({ pollForAddress })); // pollForAddress 함수를 State에 저장
        } else if (action.data.type === "REJECT") {
          // 모달창을 닫아 요청을 거부했을때,
          // state에서 pollForAddress를 꺼내와,
          pollForAddress = yield select((state) => state.user.pollForAddress);
          if (pollForAddress) {
            // 존재한다면,
            yield cancel(pollForAddress); // 취소시킨다.
          }
          return; // 함수 탈출
        }
      } catch (error) {
        const err = { ...error.response.data.error };
      }
      break;

    case USER_PUT_REGISTER_SMART_WALLET_ADDRESS:
      try {
        const state = yield select();
        const registerPayload = state.user.registerPayload;
        yield put(loadingOn());
        const res = yield call(UserService.registerMetamask, registerPayload);
        if (res.message === "Ok") {
          TokenService.save(res.data.accessToken);
          const decode = jwt_decode(res.data.accessToken);
          yield put(
            set({
              userInfo: {
                userId: decode.userId,
                userEmail: decode.email,
                address: decode.address,
                accessToken: res.data.accessToken,
                refreshToken: getRefreshToken(),
                walletType: decode.walletType,
              },
            })
          );
          yield put(
            set({
              registerPayload: {
                address: null,
                walletType: null,
              },
            })
          );
          yield put(userInitSagaActionCreator());
          action.data.setModalOpen(1);
          yield put(loadingOff());
          return;
        } else if (res.message === "No Content") {
          action.data.setModalOpen(8);
          yield put(
            set({
              registerPayload: {
                address: null,
                walletType: null,
              },
            })
          );
          yield put(loadingOff());
          return;
        }
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(
          set({
            registerPayload: {
              address: null,
              walletType: null,
            },
          })
        );
        if (err.message === "Already exist") {
          action.data.setModalOpen(8);
        }
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case USER_GET_MY_PAGE_INFO:
      try {
        yield put(loadingOn());
        const voucherRes = yield call(UserService.getUserVoucher);
        const ticketRes = yield call(UserService.getUserTicket);
        const ppRes = yield call(UserService.getUserScores);
        const decode = jwt_decode(getAccessToken());
        yield put(
          set({
            userInfo: {
              userId: decode.userId,
              userEmail: decode.email,
              address: decode.address,
              accessToken: getAccessToken(),
              refreshToken: getRefreshToken(),
              ticket: ticketRes.data.ticket,
              PP: ppRes.data.PP,
              walletType: decode.walletType,
            },
            voucher: voucherRes.data.voucher,
          })
        );

        yield put(loadingOff());
      } catch (error) {
        const err = { ...error.response.data.error };
        if (err.message === "lack of level") {
          action.data.setModalOpen(1);
        }
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case USER_DELETE_INFO:
      try {
        yield put(loadingOn());
        const res = yield call(UserService.deleteUserInfo);
        if (res.message === "No Content") {
          alert(
            "회원탈퇴가 완료되었습니다.\nOLEA MARKET을 이용해 주셔서 감사합니다."
          );
          yield put(userSignOutSagaActionCreator());
        }
        yield put(loadingOff());
      } catch (error) {
        const err = { ...error.response.data.error };
        yield put(fail({ error: err }));
        yield put(loadingOff());
      }
      break;

    case USER_POST_BUY_TICKET:
      try {
        yield put(loadingOn());
        const res = yield call(UserService.postBuyTicket, action.data.payload);
        if (res.message === "구매 성공") {
          if (action.data.pageType === "holder") {
            yield put(userGetMetamaskInfoSagaActionCreator("olea"));
          } else if (action.data.pageType === "club") {
            yield put(userGetMetamaskInfoSagaActionCreator("club"));
            yield put(userGetVoucherSagaActionCreator());
          } else if (action.data.pageType === "ticket") {
            yield put(userGetTicketSagaActionCreator());
            yield put(userGetMyPageInfoSagaActionCreator());
            yield put(userGetMetamaskInfoSagaActionCreator());
            yield put(userGetVoucherSagaActionCreator());
          }
          action.data.setModalOpen(2);

          yield put(loadingOff());
        }
      } catch (error) {
        yield put(loadingOff());
        action.data.setModalOpen(3);
      }
      break;

    case USER_POST_BUY_GAME_MONEY:
      try {
        yield put(loadingOn());
        const res = yield call(
          UserService.postBuyGameMoney,
          action.data.payload
        );
        if (res.message === "구매 성공") {
          if (action.data.pageType === "holder") {
            yield put(userGetMetamaskInfoSagaActionCreator("olea"));
          } else if (action.data.pageType === "club") {
            yield put(userGetMetamaskInfoSagaActionCreator("club"));
            yield put(userGetVoucherSagaActionCreator());
          } else if (action.data.pageType === "joy") {
            yield put(userGetMyPageInfoSagaActionCreator());
            yield put(userGetMetamaskInfoSagaActionCreator());
            yield put(userGetVoucherSagaActionCreator());
            yield put(getGameInfoSagaActionCreator(20));
          }
          action.data.setModalOpen(2);

          yield put(loadingOff());
        }
      } catch (error) {
        yield put(loadingOff());
        action.data.setModalOpen(3);
      }
      break;

    case USER_POST_REQUEST_CLAIM:
      try {
        const res = yield call(
          UserService.postRequestClaim,
          action.data.claimCoin
        );
        if (res.message === "Created") {
          action.data.setModalOpen(6);
        }
      } catch (e) {
        console.log(e);
      }
      break;

    case USER_SEND_CALLIOPE_MINT_INFO:
      try {
        yield call(NftService.sendCalliopeMintInfo, action.data);
        const res = yield call(NftService.getMintLog);
        yield put(
          set({
            oleaMintLogs: res.data,
          })
        );
      } catch (e) {
        console.log(e);
      }
      break;

    case USER_POST_BUY_TICKET_ON_OLEA_MINT:
      try {
        yield put(loadingOn());
        const res = yield call(UserService.postBuyTicket, action.data.payload);
        if (res.message === "구매 성공") {
          yield put(userGetTicketSagaActionCreator());
          yield put(userGetMyPageInfoSagaActionCreator());
          yield put(userGetMetamaskInfoSagaActionCreator());
          yield put(userGetVoucherSagaActionCreator());
          action.data.setModalOpen("SUCCESS_BUY_TICKET");
          yield put(loadingOff());
        }
      } catch (error) {
        yield put(loadingOff());
        action.data.setModalOpen("ERROR_BUY_TICKET");
      }
      break;

    case USER_GET_MINT_LOG:
      try {
        const res = yield call(NftService.getMintLog);
        yield put(
          set({
            oleaMintLogs: res.data,
          })
        );
      } catch (e) {
        console.log(e);
      }
      break;

    case USER_GET_EACH_MINT_LOG:
      try {
        yield call(NftService.getEachMintLog, action.data);
        const res = yield call(NftService.getMintLog);
        yield put(
          set({
            oleaMintLogs: res.data,
          })
        );
      } catch (e) {
        console.log(e);
      }
      break;

    case USER_GET_OLEA_CLAIM_LOG:
      try {
        // const decode = jwt_decode(getAccessToken());
        // const res = yield call(UserService.getClaimLogs, decode.userId);
        const oleaRes = yield call(UserService.getClaimLogs, action.data.nftId);
        const pointRes = yield call(
          UserService.getClaimLogsPoint,
          action.data.nftId
        );

        const oleaLogs = oleaRes.data.map((data) => {
          return {
            ...data,
            claimType: "OLEA",
          };
        });

        const pointLogs = pointRes.data.map((data) => {
          return {
            ...data,
            claimType: "OLEA POINT",
          };
        });

        yield put(
          set({
            oleaClaimLog: [...oleaLogs, ...pointLogs],
          })
        );
      } catch (e) {
        console.log(e);
      }
      break;

    case USER_POST_OLEA_CLAIM:
      try {
        if (action.data.claimType === "olea") {
          const res = yield call(
            UserService.postClaimToOleaRequest,
            action.data.payload
          );
          if (res.message === "Success to claim") {
            action.data.setModalOpen(4);
            yield put(userGetMetamaskInfoSagaActionCreator());
          }
        } else if (action.data.claimType === "point") {
          const res = yield call(
            UserService.postClaimToPointRequest,
            action.data.payload
          );
          if (res.message === "Success to claim point") {
            action.data.setModalOpen(4);
            yield put(userGetMetamaskInfoSagaActionCreator());
          }
        }
      } catch (e) {
        console.log(e);
        action.data.setModalOpen(0);
      }
      break;

    case GET_POINT_LOGS:
      try {
        const res = yield call(UserService.getPointLogs);

        yield put(
          set({
            pointLogs: res.data,
          })
        );
      } catch (e) {
        console.log(e);
      }
      break;

    default:
      break;
  }
}

// export saga
export function* userSaga() {
  yield takeEvery(USER_INIT_SAGA, sagaReducer);
  yield takeEvery(USER_SET_STATE, sagaReducer);
  yield takeEvery(USER_GET_DISCORD_ACCOUNT, sagaReducer);
  yield takeEvery(USER_GET_SCORES, sagaReducer);
  yield takeEvery(USER_GET_TICKET, sagaReducer);
  yield takeEvery(USER_GET_BALANCE, sagaReducer);
  yield takeEvery(USER_SIGNOUT, sagaReducer);
  yield takeEvery(USER_PUT_REFRESH_NFT, sagaReducer);
  yield takeEvery(USER_GET_METAMASK_INFO, sagaReducer);
  yield takeEvery(USER_GET_TRADE_LOG, sagaReducer);
  yield takeEvery(USER_POST_EMAIL_INQUIRE, sagaReducer);
  yield takeEvery(USER_GET_VOUCHER, sagaReducer);
  yield takeEvery(USER_GET_METAMASK_ADDRESS, sagaReducer);
  yield takeEvery(USER_GET_KLAYTN_ADDRESS, sagaReducer);
  yield takeEvery(USER_PUT_REGISTER_SMART_WALLET_ADDRESS, sagaReducer);
  yield takeEvery(USER_GET_MY_PAGE_INFO, sagaReducer);
  yield takeEvery(USER_DELETE_INFO, sagaReducer);
  yield takeEvery(USER_POST_BUY_TICKET, sagaReducer);
  yield takeEvery(USER_POST_BUY_GAME_MONEY, sagaReducer);
  yield takeEvery(USER_POST_REQUEST_CLAIM, sagaReducer);
  yield takeEvery(USER_SEND_CALLIOPE_MINT_INFO, sagaReducer);
  yield takeEvery(USER_POST_BUY_TICKET_ON_OLEA_MINT, sagaReducer);
  yield takeEvery(USER_GET_MINT_LOG, sagaReducer);
  yield takeEvery(USER_GET_EACH_MINT_LOG, sagaReducer);
  yield takeEvery(USER_GET_OLEA_CLAIM_LOG, sagaReducer);
  yield takeEvery(USER_POST_OLEA_CLAIM, sagaReducer);
  yield takeEvery(GET_POINT_LOGS, sagaReducer);
}
