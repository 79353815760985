import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { tierSearch } from "../../../../shared/NftData";

import NORESULTFOUND from "../../../../assets/icons/notFoundIcon.png";

import "./MyWalletCardSection.scss";
import MyWalletGlareCards from "./MyWalletGlareCards";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const MyWalletCardSection = () => {
  const [toggleInfo, setToggleInfo] = useState(false);
  const userAccountsArray = useSelector((state) => state.user.userMetaMaskInfo);
  const { tier, edition, balance } = useSelector(
    (state) => state.user.nftSearch
  );

  const [renderArr, setRenderArr] = useState(userAccountsArray);

  const filterFunc = (tier, balance, edition) => {
    const tierSearchReturn = tierSearch(tier, userAccountsArray);
    const searchResult = tierSearchReturn.filter(
      (cur) => String(cur.edition).indexOf(edition) !== -1
    );
    const result = sortArr(balance, searchResult);
    setRenderArr(result);
  };

  const sortArr = (balance, searchResult) => {
    switch (balance) {
      case "htl":
        return searchResult.sort((a, b) => b.balance - a.balance);
      case "lth":
        return searchResult.sort((a, b) => a.balance - b.balance);
      default:
        return searchResult;
    }
  };

  useEffect(() => {
    filterFunc(tier, balance, edition);
  }, [tier, balance, edition]);

  useEffect(() => {
    setRenderArr(userAccountsArray);
  }, [userAccountsArray]);

  return (
    <div className="my_wallet_card_section_wrapper">
      <div className="my_wallet_card_section_container">
        <div className="my_wallet_card_section_toggle_box">
          <FormControlLabel
            sx={{
              display: "block",
            }}
            control={
              <Switch
                checked={toggleInfo}
                onChange={() => setToggleInfo(!toggleInfo)}
                name="NFT 정보 확인"
                color="primary"
              />
            }
            label="NFT 정보 확인"
          />
        </div>
        {renderArr.length ? (
          <div className="my_wallet_glare_card_section">
            {renderArr.map((data) => {
              return (
                <MyWalletGlareCards
                  key={data.nftId}
                  states={{ data, toggleInfo }}
                  toggleInfo={toggleInfo}
                />
              );
            })}
          </div>
        ) : (
          <div className="no_results_found_wapper">
            <img src={NORESULTFOUND} alt="검샐결과없음 이미지" />
            <p className="no_result_text">검색 결과가 없습니다.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyWalletCardSection;
