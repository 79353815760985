import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NFTTypeConvert } from "../../../../shared/NftData";

import ROULETTE_BTN from "../../../../assets/images/roulette_button.png";
import { userPostBuyGameMoneySagaActionCreator } from "../../../../redux/modules/user";

const TicketBuyTicketComponent = ({ states }) => {
  const { setModalOpen, gameMoneyPrice } = states;
  const dispatch = useDispatch();

  const userNftArr = useSelector((state) => state.user.userMetaMaskInfo);
  const voucherState = useSelector((state) => state.user.voucher);

  const [count, setCount] = useState(1);
  const [selectedValue, setSelectedValue] = useState("voucher");

  const handleChage = (e) => {
    setSelectedValue(e.target.value);
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const increment = () => {
    let comparison;
    if (selectedValue === "voucher") {
      comparison = Math.floor(voucherState / gameMoneyPrice);
    } else {
      comparison = Math.floor(
        userNftArr.filter((data) => data.nftId === Number(selectedValue))[0]
          .balance / gameMoneyPrice
      );
    }

    if (count < comparison) {
      setCount(count + 1);
    }
  };

  const clickBuyBtnHandler = useCallback(() => {
    let payload;
    if (selectedValue === "voucher") {
      payload = {
        pageType: "voucher",
        gameMoneyCount: count,
      };

      if (voucherState < gameMoneyPrice) {
        setModalOpen(3);
      } else {
        dispatch(
          userPostBuyGameMoneySagaActionCreator({
            payload,
            setModalOpen,
            pageType: "joy",
          })
        );
        setModalOpen(0);
      }
    } else {
      payload = {
        pageType: "nft",
        nftId: Number(selectedValue),
        gameMoneyCount: count,
      };

      if (
        userNftArr.filter((data) => data.nftId === Number(selectedValue))[0]
          .balance < gameMoneyPrice
      ) {
        setModalOpen(3);
      } else {
        dispatch(
          userPostBuyGameMoneySagaActionCreator({
            payload,
            setModalOpen,
            pageType: "joy",
          })
        );
        setModalOpen(0);
      }
    }
  }, [selectedValue, count]);

  return (
    <>
      <div className="buy_ticket_component">
        <div>
          <img src={ROULETTE_BTN} alt="ticket" style={{ width: "70px" }} />
          <span>
            <button onClick={decrement}>-</button>
            {count * 100}게임머니<button onClick={increment}>+</button>
          </span>
        </div>
        <div>
          <span>구매수단 선택</span>
          <select value={selectedValue} onChange={handleChage}>
            <option value={"voucher"}>
              {`Voucher / ${voucherState} OLEA`}
            </option>

            {userNftArr.map((data) => (
              <option key={data.nftId} value={data.nftId}>
                {`ATENA ${NFTTypeConvert(data.tierType)} ${data.edition} / ${
                  data.balance
                } OLEA`}
              </option>
            ))}
          </select>
        </div>

        <button onClick={clickBuyBtnHandler}>구매</button>
      </div>
    </>
  );
};

export default TicketBuyTicketComponent;
